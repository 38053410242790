import { eventEmitter, moduleRouter, weappSDK } from "@weapp/utils";
import { utils, constants, AnyObj, HrmCard } from "../../lib";
import { AssociativeSelectedClickProps, MAssociativeSelectedClickProps } from "./auto-complete/types";
import { SHAREBROWSERKEYS, TYPESBROWSERKEYS } from "./PropsType";

const { pauseEvent } = utils;
const { uiAppName } = constants;

let printMode = false;

// 开启打印模式
export function enablePrintMode() {
  printMode = true;
}

// 关闭打印模式
export function disabledPrintMode() {
  printMode = false;
}

// 判断当前是否开启打印模式
export function isPrintMode() {
  return printMode;
}

/** 匹配独立部署后 ebuilder module */
function extractEbuilderAndNumber(module: string) {
  const ebRegex = /ebuilder\d+/;
  const match = module.match(ebRegex);
  if(match) {
    const regex = /\d+/;
    const appId = module.match(regex)?.[0];
    let newModule = appId ? module.replace(appId, '') : module;
    return { module: newModule, appId };
  } else {
    return { module, appId: '' };
  }
}

const printUtils = {
  isPrintMode,
  enablePrintMode,
  disabledPrintMode,
}

/**
 * 查找浏览按钮对应的事项模块标识
 * @param module 浏览按钮模块标识
 * @param type 浏览按钮类型
 * @returns 对应事项卡片的模块标识
 */
function getModule(m: string, type: string) {
  const modules = moduleRouter.getModules?.() || {};
  const { module } = extractEbuilderAndNumber(m);
  return Object.entries(modules).find(([key, item]) => {
    if (item.module !== module) return false;
    if (Array.isArray(item.type) && item.type.length) {
      return item.type.indexOf(type) !== -1;
    } else if (item.type === type) {
      return true;
    }
    return false;
  });
}

// 获取数据的唯一标示key值
const getDataKey = (props: {
  data: AnyObj,
  dataKey?: string,
  groupKey?: string,
  isMultType?: boolean,
}) => {
  let { data, dataKey, groupKey, isMultType } = props;
  dataKey = dataKey || 'id';
  groupKey = groupKey || 'type';
  return isMultType && groupKey in data ? `${data[groupKey]}|${data[dataKey]}` : data[dataKey];
}

const getDataInfo = (props: {
  data: AnyObj,
  dataKey?: string,
  groupKey?: string,
  isMultType?: boolean,
}) => {
  let { data, dataKey, groupKey, isMultType } = props;
  dataKey = dataKey || 'id';
  groupKey = groupKey || 'type';
  return {
    id: data[dataKey],
    type: data[groupKey],
    key: getDataKey({ data, dataKey, groupKey, isMultType }),
  };
}

const onAssociativeSelectedClick = (props: AssociativeSelectedClickProps) => {
  const {
    data, event, openSP, type: bType, cardDetailDialogParams, module, isShowGroup, linkUrl, viewDialogPath, dataKey,
    groupKey, isMultType, history, location, match, isTypesBrowser,
    // options, browsers,
    onSelectedItemClick, customSelectedItemClick, onSelectedItemLink, onSelectedItemLinkCallBack,
  } = props;
  if (event) pauseEvent(event);
  // 触发其他 Trigger 弹框关闭
  eventEmitter.emit(uiAppName, 'emit_global_trigger_close', event);

  let { id, type: dType } = getDataInfo?.({ data, dataKey, groupKey, isMultType }) || {};
  let type = dType || bType;

  // shareBrowser/typesBrowser 更新 type 和 id
  let typeKey = "";
  if (SHAREBROWSERKEYS.OBJTYPE in data) {
    if (data.targetId) id = data.targetId;
    typeKey = SHAREBROWSERKEYS.OBJTYPE;
  } else if (TYPESBROWSERKEYS.ENTITYTYPE in data) {
    typeKey = TYPESBROWSERKEYS.ENTITYTYPE;
  }
  if (typeKey && data[typeKey]) {
    type = data[typeKey];
  }

  const selectedItemLinkFn = () => {
    onSelectedItemLinkCallBack?.();
    const moduleConfig = getModule(module || '', bType || type || '');
    if (moduleConfig) {
      const option = {
        params: cardDetailDialogParams || {}
      }
      const { appId } = extractEbuilderAndNumber(module || ''); 
      if(appId) option.params.appId = appId;
      const generateId = moduleRouter.generateId(id, option);
      moduleRouter.open(props as any, moduleConfig[0] as string, generateId as string)
    }
  }

  const customItemClickFn = () => {
    if (data?.linkUrl) {
      weappSDK.openLink({ url: `${data.linkUrl}${id}` });
    } else if (data?.custom?.linkUrl) {
      weappSDK.openLink({ url: `${data?.custom?.linkUrl}${id}` });
    } else if (linkUrl) {
      weappSDK.openLink({ url: `${linkUrl}${id}` });
    }
  }

  const selectItemClickFn = () => {
    if (id !== undefined && type !== undefined && isShowGroup && data?.childrenData?.length) {
      let { id, type: dType } = getDataInfo?.({ data, dataKey, groupKey, isMultType }) || {};
      let type = dType || bType;
      let cardType = type === 'channel' ? 'group' : type;
      const members: any[] = [];
      data.childrenData.forEach((data: any) => {
        const {
          richContent, content, name, title, avatar, departmentId, department
        } = data;
        const { id } = getDataInfo?.({ data, dataKey, groupKey, isMultType }) || {};
        if (id) {
          const member: {
            id: string,
            name: string,
            avatar?: AnyObj,
            position?: {
              id: string,
              name: string
            }
          } = {
            id,
            name: richContent || content || name || title || '',
            avatar,
          }
          if (departmentId && department) {
            member.position = {
              id: departmentId,
              name: department
            }
          }
          members.push(member);
        }
      })
      const listCustomData: any = {
        type: cardType,
        titleName: data.originName || data.name || data.content || data.title,
        topIconName: 'Icon-File-expansion',
        members,
      }
      HrmCard.openCustomCard(event, id, cardType, 'bs/hrm', { customData: listCustomData })
    } else if (type !== undefined && id !== undefined) {
      // 只用 _entitype 判断不准确，有的模块的普通浏览按钮数据也会带上 _entitype
      if (type === 'organization') {
        // HrmCard.openHrmSP(event, id, 'department', 'bs/hrm', typeKey ? { path: 'card' } : undefined);
        HrmCard.openHrmSP(event, id, 'department', 'bs/hrm');
      } else if (type === 'resource' || type === 'user') {
        (openSP ? HrmCard.openHrmSP : HrmCard.openHrmCard)(event, id, '', 'bs/hrm', isTypesBrowser && typeKey ? { path: 'card' } : undefined);
      } else if (type === 'channel' || type === 'group') {
        (openSP ? HrmCard.openHrmSP : HrmCard.openHrmCard)(event, id, 'group', 'bs/hrm', isTypesBrowser && typeKey ? { path: 'card' } : undefined);
      } else if (type === 'department' || type === 'dept') {
        (openSP ? HrmCard.openHrmSP : HrmCard.openHrmCard)(event, id, 'department', 'bs/hrm', isTypesBrowser && typeKey ? { path: 'card' } : undefined);
      } else if (['external', 'subcompany', 'position', 'role'].indexOf(type) !== -1) {
        (openSP ? HrmCard.openHrmSP : HrmCard.openHrmCard)(event, id, type, 'bs/hrm', isTypesBrowser && typeKey ? { path: 'card' } : undefined);
      } else {
        let _viewDialogPath = viewDialogPath || '';
        if (!_viewDialogPath) {
          const moduleConfig = getModule(module || '', bType || type || '');
          const browserRouter = moduleConfig?.[1];
          if (browserRouter && browserRouter.paths && history && location && match) {
            _viewDialogPath = moduleRouter?.generateSubPath?.(props as any, browserRouter.paths[0]);
          }
        }
        if (_viewDialogPath) {
          if (onSelectedItemLink) {
            onSelectedItemLink(data, _viewDialogPath, selectedItemLinkFn);
          } else {
            selectedItemLinkFn();
          }
        } else {
          if (customSelectedItemClick) {
            customSelectedItemClick?.(data, event, customItemClickFn);
          } else {
            customItemClickFn();
          }
        }
      }
    }
  }

  if (onSelectedItemClick) {
    onSelectedItemClick(data, event, selectItemClickFn);
  } else {
    selectItemClickFn();
  }
}

const onMAssociativeSelectedClick = (props: MAssociativeSelectedClickProps) => {
  const {
    data, event, type: bType, module, dataKey, groupKey, viewDialogPath, cardDetailDialogParams, appLinkUrl, useHrmCard = true,
    onSelectedItemClick, customSelectedItemClick, onSelectedItemLink 
  } = props;

  let { id, type: dType } = getDataInfo?.({ data, dataKey, groupKey }) || {};
  let type = dType || bType;

    // shareBrowser/typesBrowser 更新 type 和 id
    let typeKey = '';
    if (SHAREBROWSERKEYS.OBJTYPE in data) {
      if(data.targetId) id = data.targetId;
      typeKey = SHAREBROWSERKEYS.OBJTYPE;
    } else if (TYPESBROWSERKEYS.ENTITYTYPE in data) {
      typeKey = TYPESBROWSERKEYS.ENTITYTYPE;
    }
    if (typeKey && data[typeKey]) {
      type = data[typeKey];
    }

  const selectedItemLinkFn = () => {
    let _viewDialogPath = viewDialogPath;
    const browserRouter = getModule(module || '', bType || type || '')?.[1];
    if (!_viewDialogPath && browserRouter && browserRouter.mDetailPath) {
      _viewDialogPath = browserRouter.mDetailPath;
    }
    const option = { params: cardDetailDialogParams || {} };
    const { appId } = extractEbuilderAndNumber(module || ''); 
    if(appId) option.params.appId = appId;
    const generateId = moduleRouter.generateId(id, option);
    const url = _viewDialogPath!.replace('{id}', generateId);
    weappSDK.openLink({ url });
  }

  const customItemClickFn = () => {
    if (data?.appLinkUrl) {
      if(event) pauseEvent(event);
      weappSDK.openLink({ url: `${data.appLinkUrl}${id}` });
    } else if (data?.custom?.appLinkUrl) {
      if(event) pauseEvent(event);
      weappSDK.openLink({ url: `${data?.custom?.appLinkUrl}${id}` });
    } else if (appLinkUrl) {
      if(event) pauseEvent(event);
      weappSDK.openLink({ url: `${appLinkUrl}${id}` });
    }
  }

  const selectItemClickFn = () => {
    if (useHrmCard && id !== undefined && (type === 'resource' || type === 'user')) {
      HrmCard.openMobileHrmCard(id, '', 'bs/hrm');
      if(event) pauseEvent(event);
    } else {
      let _viewDialogPath = viewDialogPath;
      const browserRouter = getModule(module || '', bType || type || '')?.[1];
      if (!_viewDialogPath && browserRouter && browserRouter.mDetailPath) {
        _viewDialogPath = browserRouter.mDetailPath;
      }
      if (_viewDialogPath) {
        if (onSelectedItemLink) {
          if(event) pauseEvent(event);
          onSelectedItemLink(data, _viewDialogPath, selectedItemLinkFn);
          return;
        } else {
          if(event) pauseEvent(event);
          selectedItemLinkFn();
        }
      } else {
        if (customSelectedItemClick) {
          customSelectedItemClick?.(data, event, customItemClickFn);
        } else {
          customItemClickFn();
        }
      }
    }
  }

  if (onSelectedItemClick) {
    if(event) pauseEvent(event);
    onSelectedItemClick(data, event, selectItemClickFn);
  } else {
    selectItemClickFn();
  }
}

export type PrintUtils = typeof printUtils;

export {
  printUtils,
  onAssociativeSelectedClick,
  onMAssociativeSelectedClick,
  getDataKey,
  getDataInfo,
  getModule,
  extractEbuilderAndNumber,
};
