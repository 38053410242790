(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("weappUtils"), require("mobx"), require("React"), require("ReactDOM"), require("ReactRouterDOM"), require("mobxReact"), require("axios"), require("regeneratorRuntime"), require("loadjs"), require("weappEcodesdk"));
	else if(typeof define === 'function' && define.amd)
		define(["weappUtils", "mobx", "React", "ReactDOM", "ReactRouterDOM", "mobxReact", "axios", "regeneratorRuntime", "loadjs", "weappEcodesdk"], factory);
	else if(typeof exports === 'object')
		exports["weappUi"] = factory(require("weappUtils"), require("mobx"), require("React"), require("ReactDOM"), require("ReactRouterDOM"), require("mobxReact"), require("axios"), require("regeneratorRuntime"), require("loadjs"), require("weappEcodesdk"));
	else
		root["weappUi"] = factory(root["weappUtils"], root["mobx"], root["React"], root["ReactDOM"], root["ReactRouterDOM"], root["mobxReact"], root["axios"], root["regeneratorRuntime"], root["loadjs"], root["weappEcodesdk"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__19__, __WEBPACK_EXTERNAL_MODULE__23__, __WEBPACK_EXTERNAL_MODULE__73__, __WEBPACK_EXTERNAL_MODULE__97__, __WEBPACK_EXTERNAL_MODULE__261__, __WEBPACK_EXTERNAL_MODULE__282__, __WEBPACK_EXTERNAL_MODULE__319__) {
return 