import { Method } from 'axios';
import { AnyObj } from '../../../types/common';

const getAttachmentLocalSecurityInfo = (options?: {
  key?: string,
  url?: string,
  method?: Method,
  params?: AnyObj,
  headers?: AnyObj,
}) => import(
  /* webpackChunkName: "upload" */
  './getLocalSecurityInfoAsync'
).then((defaultModule) => defaultModule?.getAttachmentLocalSecurityInfo?.(options));

export default getAttachmentLocalSecurityInfo;