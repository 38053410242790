import { Loadable } from '../../lib';
import { AssociativeSearchType, AssociativeSelectedType, SelectedDetailCardType } from "./auto-complete/types";
import { BrowserType, BrowserPanelType, BrowserWindowType } from "./types";
import { TypesBrowserDetailListType, TypesBrowserType } from "./types-browser/types";
import { ShareBrowserDetailListType, ShareBrowserType } from "./share-browser/types";
import { defaultProps, defaultTypesProps, defaultShareProps, defaultSelectedDetailCardProps, defaultTypesBrowserDetailTableProps, defaultShareBrowserDetailTableProps } from "./defaultProps";
import { autoCompleteMiddleware, browserMiddleware, associativeBrowserMiddleware, typeBrowserMiddleware, shareBrowserMiddleware } from "./util/browserMiddleware";
import getIframeInBrowserCallback from './util/iframeInDialogCallback';
import { printUtils, onAssociativeSelectedClick } from './shareUtils';

const Browser = Loadable({
  name: 'Browser', propsMiddleware: browserMiddleware, loader: () => import(
    /* webpackChunkName: "browser" */
    './Browser')
}) as BrowserType;

Browser.defaultProps = defaultProps;

const AutoCompleteSearch = Loadable({
  name: 'AutoCompleteSearch', propsMiddleware: autoCompleteMiddleware, loader: () => import(
    /* webpackChunkName: "browser" */
    './auto-complete/AssociativeSearch')
}) as AssociativeSearchType;

AutoCompleteSearch.defaultProps = defaultProps;

const AutoCompleteSelected = Loadable({
  name: 'AutoCompleteSelected', propsMiddleware: autoCompleteMiddleware, loader: () => import(
    /* webpackChunkName: "browser" */
    './auto-complete/AssociativeSelected')
}) as AssociativeSelectedType;

const SelectedDetailCard = Loadable({
  name: 'SelectedDetailCard', loader: () => import(
    /* webpackChunkName: "browser" */
    './auto-complete/SelectedDetailCard')
}) as SelectedDetailCardType;

AutoCompleteSelected.defaultProps = defaultProps;

const AssociativeBrowser = Loadable({
  name: 'AssociativeBrowser', propsMiddleware: associativeBrowserMiddleware, loader: () => import(
    /* webpackChunkName: "browser" */
    './types-browser/AssociativeBrowser')
}) as TypesBrowserType;

AssociativeBrowser.defaultProps = defaultTypesProps;

const BrowserWindow = Loadable({
  name: 'BrowserWindow', propsMiddleware: browserMiddleware, loader: () => import(
    /* webpackChunkName: "browser" */
    './BrowserWindow')
}) as BrowserWindowType;

BrowserWindow.defaultProps = defaultProps;

const BrowserPanel = Loadable({
  name: 'BrowserPanel', propsMiddleware: browserMiddleware, loader: () => import(
    /* webpackChunkName: "browser" */
    './BrowserPanel')
}) as BrowserPanelType;

BrowserPanel.defaultProps = defaultProps;

const TypesBrowser = Loadable({
  name: 'TypesBrowser',
  propsMiddleware: typeBrowserMiddleware,
  loader: () => import(
    /* webpackChunkName: 'browser' */
    './types-browser/TypesBrowser'
  )
}) as TypesBrowserType;

const DetailTable = Loadable({
  name: 'TypesBrowserDetailTable',
  loader: () => import(
    /* webpackChunkName: 'browser' */
    './types-browser/components/DetailTable'
  )
}) as TypesBrowserDetailListType;

DetailTable.defaultProps = defaultTypesBrowserDetailTableProps;

TypesBrowser.DetailTable = DetailTable;

TypesBrowser.defaultProps = defaultTypesProps;

TypesBrowser.onAssociativeSelectedClick = (arg) =>  onAssociativeSelectedClick({ ...arg, isTypesBrowser: true});

const ShareBrowser = Loadable({
  name: 'ShareBrowser',
  propsMiddleware: shareBrowserMiddleware,
  loader: () => import(
    /* webpackChunkName: 'browser' */
    './share-browser/ShareBrowser'
  )
}) as ShareBrowserType;

const ReadOnlyDetailTable = Loadable({
  name: 'ShareBrowserReadOnlyDetailTable',
  loader: () => import(
    /* webpackChunkName: 'browser' */
    './share-browser/components/ReadOnlyDetailTable'
  )
}) as ShareBrowserDetailListType;

ReadOnlyDetailTable.defaultProps = defaultShareBrowserDetailTableProps;

ShareBrowser.DetailTable = ReadOnlyDetailTable;

ShareBrowser.defaultProps = defaultShareProps;

ShareBrowser.onAssociativeSelectedClick = (arg) =>  onAssociativeSelectedClick({ ...arg, isTypesBrowser: true});

Browser.AssociativeBrowser = AssociativeBrowser;

Browser.BrowserWindow = BrowserWindow;

Browser.BrowserPanel = BrowserPanel;

Browser.AutoCompleteSearch = AutoCompleteSearch;

Browser.AutoCompleteSelected = AutoCompleteSelected;

SelectedDetailCard.defaultProps = defaultSelectedDetailCardProps;

Browser.SelectedDetailCard = SelectedDetailCard;

Browser.getIframeInBrowserCallback = getIframeInBrowserCallback;

Browser.printUtils = printUtils;

Browser.onAssociativeSelectedClick = onAssociativeSelectedClick;

/* 子组件 */
export { TypesBrowser, ShareBrowser, AssociativeBrowser, BrowserWindow, AutoCompleteSearch, AutoCompleteSelected };

/* context相关 */
export { useBrowserProps, useBrowserState, withBrowser, BrowserPropsContext, BrowserStateContext } from './panel/BrowserContext';

/* 工具方法 */
export { getIframeInBrowserCallback };

/* 浏览按钮基础类型声明 */
export type { BrowserProps, TabsData as BrowserTabsData, FootersData as BrowserFootersData, ValueType as BrowserValueType, BrowserAfterChange } from './types';

/* 联想搜索组件类型声明 */
export type { BrowserAssociativeProps, BrowserOperationsType } from './auto-complete/types';

/* 组合浏览按钮类型声明 */
export type { TypesBrowserProps, TypesBrowserOption, TypesBrowserData, TypesBrowserValueType, TypesBrowserAfterChange } from './types-browser/types';

/* 共享权限浏览按钮声明 */
export type { ShareBrowserProps, ShareBrowserOption, ShareBrowserData, ShareBrowserValueType, ShareBrowserChangeType, ShareBrowserAfterChange } from './share-browser/types';

/* 扩展组件的类型声明 */
export type {
  BrowserListProps, BrowserTreeProps, BrowserTableProps, BrowserDialogProps, BrowserSearchAdvancedProps, BrowserSearchAdvancedPanelProps, BrowserSearchAdvancedLayoutProps,
  BrowserCustomContentProps, BrowserCustomPanelContentProps, BrowserSelectedPanelProps, BrowserDynamicRenderComponentProps, BrowserCorsComponentProps,
} from './types';

/* 内部组件的类型声明 */
export type {
  BrowserExtraButtonProps, BrowserExtraDialogProps, BrowserExtraButtonPositionType, BrowserExtraButtonCallbackType, BrowserExtraButtonCallbackAction,
  BrowserMenuButtonProps, BrowserStatusButtonProps, BrowserTipsProps,
  BrowserClickableItemProps, BrowserClickableHrmCardOption, BrowserClickableItemOption, BrowserClickableLinkOption,
  BrowserDialogButtonProps,
} from './components/index';

export default Browser;
