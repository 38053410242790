import { getLabel, request } from "@weapp/utils";
import { constants, constantsM } from '../../lib';
import { BrowserAssociativeState, SelectedDetailCardProps } from "./auto-complete/types";
import { BrowserDialogProps, BrowserPanelProps, BrowserProps, MBrowserProps } from "./types";
import { MTypesBrowserProps, TypesBrowserDetailListProps, TypesBrowserProps } from "./types-browser/types";
import { ShareBrowserProps, MShareBrowserProps, ShareBrowserDetailListProps } from "./share-browser/types";

const { browserClsPrefix } = constants;
const { mBrowserClsPrefix } = constantsM;

export const MBrowserDialogRoute = '/browser'; // 移动端浏览按钮 弹框页面 路由
export const MTypesBrowserSelectedRoute = '/types_selected'; // 人员范围选择已选列表

// 针对流程兼容处理：wfcRequest（旧），wflRequest（新）
// 关联按钮类型
export const associativeTypes = [
  'mainlineBrowser', 'taskBrowser', 'wfcRequest', 'wflRequest', 'calendarBrowser', 'frpt_component',
  'customer', 'clue', 'orderform', 'saleChance', 'production', 'contract',
  'marketactivity', 'planBrowser', 'competitor', 'belongMainlineBrowser',
];

// 限制已选展示数据类型（人员、部门、分部、群组、岗位、角色、组织、外部联系人、人员组织多选）
export const useMaxDisplaySelectedTypes = [
  'resource', 'department', 'subcompany', 'channel', 'position',
  'role', 'organization', 'external', 'combinationResource'
]

// 已选列表放开排序功能
export const enableSelectedSortable = [
  'resource', 'department', 'subcompany', 'channel', 'position',
  'role', 'organization', 'external', 'combinationResource'
];

/** 人员组织相关浏览框（人员、部门、分部、群组、岗位、角色、组织、外部联系人、人员组织多选） */
export const personnelOrgBrowser = [
  'resource', 'department', 'subcompany', 'channel', 'position',
  'role', 'organization', 'external', 'combinationResource'
];

/** 多类型浏览按钮人员组织相关的 option key */
export const personnelOrgOptionKey = [
  'user', 'dept', 'subcompany', 'group', 'position', 'all',
  'role', 'organization', 'external', 'allExternal', 'combinationResource'
];

// 联想搜索框默认状态
export const defaultAssociativeState: BrowserAssociativeState = {
  visible: false,
  loading: false,
  hiddenMoreBtn: true,
  activeIndex: 0,
  searchValue: '',
  selectOptions: [],
  addDialogVisible: false,
  extendDialogVisible: false,
};

/* PC端浏览按钮默认的props */
export const defaultProps: BrowserProps = {
  dataKey: 'id',
  groupKey: 'type',
  refreshable: true,
  disabledTabCache: true,
  showCheckStrictly: true,
  defaultCheckStrictly: true,
  defaultHideMore: true,
  disabledSelectedSort: true,
  prefixCls: browserClsPrefix,
  dataURL: '/api/{module}/common/browser/data/{type}',
  countURL: '/api/{module}/common/browser/count/{type}',
  dataConditionURL: '/api/{module}/common/browser/dataCondition/{type}',
  permissionURL: '/api/{module}/common/browser/permission/{type}',
  completeURL: '/api/{module}/common/browser/complete/{type}',
  conditionURL: '/api/{module}/common/browser/condition/{type}',
  destURL: '/api/{module}/common/browser/destData/{type}',
  leftDataURL: '/api/{module}/common/browser/leftData/{type}',
  propURL: '/api/{module}/common/browser/prop/{type}',
  historyURL: '/api/{module}/common/browser/history/{type}',
  addDataURL: '/api/{module}/common/browser/addData/{type}',
  associateURL: '/api/{module}/common/browser/associate/{type}',
  fetchData: request,
};

/* 移动端浏览按钮默认的props */
export const mDefaultProps: MBrowserProps = {
  dataKey: 'id',
  groupKey: 'type',
  disabledTabCache: true,
  showCheckStrictly: true,
  defaultCheckStrictly: true,
  showVoiceToTextBtn: false,
  disabledSelectedSort: true,
  prefixCls: mBrowserClsPrefix,
  treeType: 'list',
  dataURL: '/api/app/{module}/common/browser/data/{type}',
  countURL: '/api/app/{module}/common/browser/count/{type}',
  permissionURL: '/api/app/{module}/common/browser/permission/{type}',
  completeURL: '/api/app/{module}/common/browser/complete/{type}',
  conditionURL: '/api/app/{module}/common/browser/condition/{type}',
  destURL: '/api/app/{module}/common/browser/destData/{type}',
  leftDataURL: '/api/app/{module}/common/browser/leftData/{type}',
  propURL: '/api/app/{module}/common/browser/prop/{type}',
  historyURL: '/api/app/{module}/common/browser/history/{type}',
  addDataURL: '/api/app/{module}/common/browser/addData/{type}',
  associateURL: '/api/app/{module}/common/browser/associate/{type}',
  fetchData: request,
}

/* PC端组合浏览按钮默认的props */
export const defaultTypesProps: TypesBrowserProps = {
  ...defaultProps as TypesBrowserProps,
  type: '',
  multiple: true,
  combinationURL: '/api/{module}/common/browser/combination/{type}',
};

/* 移动端组合浏览按钮默认的props */
export const mDefaultTypesProps: MTypesBrowserProps = {
  ...mDefaultProps as TypesBrowserProps,
  type: '',
  multiple: true,
  showSelectedPanel: true,
  combinationURL: '/api/app/{module}/common/browser/combination/{type}',
}

/* 移动端端浏览按钮弹框默认的props */
export const mDefaultDialogProps: BrowserDialogProps = {
  width: '100%',
};

export const defaultPanelProps: BrowserPanelProps = {
  panelHeight: 550,
};

/* PC端分享浏览按钮默认的props */
export const defaultShareProps: ShareBrowserProps = {
  ...defaultProps as ShareBrowserProps,
  type: '',
  multiple: true,
  // combinationURL: '/api/{module}/common/browser/combination/{type}',
  secelInfoURL: '/api/{module}/common/querySecelInfo',
  authURL: '/api/app/{module}/component/data/permission/form/query'
};

/* 移动端共享浏览按钮默认的props */
export const mDefaultShareProps: MShareBrowserProps = {
  ...mDefaultProps as MShareBrowserProps,
  type: '',
  multiple: true,
  // combinationURL: '/api/app/{module}/common/browser/combination/{type}',
  secelInfoURL: '/api/{module}/common/querySecelInfo',
  authURL: '/api/app/{module}/component/data/permission/form/query'
}

export const defaultSelectedDetailCardProps: SelectedDetailCardProps = {
  prefixCls: browserClsPrefix
}

export const defaultTypesBrowserDetailTableProps: TypesBrowserDetailListProps = {
  prefixCls: `${browserClsPrefix}-types-detail`
}

export const defaultShareBrowserDetailTableProps: ShareBrowserDetailListProps = {
  prefixCls: `${browserClsPrefix}-share-detail`
}
