import { action, observable } from "mobx";
import { utils } from '../../../lib';
import { AnyObj } from "../../../types/common";
import { deepClone, getRandom, isEmpty, isObject, needSync } from '../../../utils/index';
import { FormDatas, FormInitAllDatas, FormLayoutProps, FormLayoutType } from '../../form/index';
import { FormItemProps, FormSingleItemProps } from '../../form-item/index';
import { getCascadeItems } from '../../form/utils/index';
import { FieldProps } from "../types";
import { getBrowserConfigUtils, getLayoutFromItemKey, isCustomField } from "../util/browser-config/index.async";
import { RuleItemsTable, SearchAdvancedStoreType } from "./Store";
import { RuleTable, CascadeRules } from "./types";
import { isMultiDatePickerType } from '../../date-picker/utils/index';
import getUtils from "./common";

const { isNumber } = utils;
// 高级搜索老数据转换
class TransDatasStore {
  parent: SearchAdvancedStoreType;
  constructor(parent: SearchAdvancedStoreType) {
    this.parent = parent;
  }

  ruleTable: RuleTable = {}; // 常用筛选老数据兼容规则
  @observable keepOriginData: boolean = true; // 过滤无用数据时，无字段展示，保留传入的data数据
  @observable enableLatestFormConfig: boolean = true; // 常用筛选数据回显以最新的表单配置展示（layout+items）, 默认存为版本
  @observable defaultData: AnyObj = {}; // 常用筛选默认数据，不展示，但需作为条件

  @action
  setState = (params: Partial<TransDatasStoreType>) => {
    Object.keys(params).forEach((key) => {
      const storeKey = key as keyof TransDatasStoreType;
      // @ts-ignore
      this[storeKey] = params[storeKey];
    })
  }

  /**
   * 获取item内别名规则
   * 老数据datas {item的别名： item的value}
   * 布局(layout)需获取 根据别名转换的items的key
   * @param item 
   * @returns 
   */
  @action
  getRuleItemsTable = (item: Array<string>) => {
    const { formStore: { items } } = this.parent;
    let rule: RuleItemsTable = {};
    item && item.forEach((it) => {
      const customParameterName = items[it].customParameterName;
      if (customParameterName) {
        rule[customParameterName] = it;
      }
    })
    return rule;
  }

  /**
   * 根据id获取layout配置
   * @param layoutId 
   * @returns 
   */
  @action
  getLayout = (layoutId: string, layouts?: Array<FormLayoutType>) => {
    const { formStore: { layout: formLayout } } = this.parent;
    let layout = {};
    (layouts || formLayout).forEach((row) => {
      const col = row.find((c) => c.id === layoutId);
      if (col) layout = col;
    })
    return layout;
  }

  /**
   * 转换datas数据格式
   * 老数据： {
   *    createTimes: [ // createTimes --- layout的id
   *      { value: ['2021-07-01'] } // value ---- items的key
   *    ]
   * }
   * 新数据：
   * {
   *   value: ['2021-07-01']
   * }
   * @param oldDatas 
   */
  @action
  changeOldSingleData = (data: FormDatas, labelId: string, ruleItemsTable: any) => {
    let newData = {};
    Object.keys(data).forEach((dataKey) => {
      if (ruleItemsTable[dataKey]) {
        if (labelId) {
          newData = {
            ...newData,
            [`${ruleItemsTable[dataKey]}_${labelId}`]: data[dataKey],
          }
        } else {
          newData = {
            ...newData,
            [ruleItemsTable[dataKey]]: data[dataKey],
          }
        }
        return;
      }
      if (labelId) {
        newData = {
          ...newData,
          [`${dataKey}_${labelId}`]: data[dataKey],
        }
      } else {
        newData = {
          ...newData,
          [dataKey]: data[dataKey],
        }
      }
    })
    return newData;
  }
  /**
   * 保存转换后的新数据格式
   */
  @action
  saveOldData = () => {
    getUtils().then((defaultModule) => defaultModule.saveOldData(this));  
  }
  /**
  * et高级搜索常用筛选老数据转换(仅处理布局) 
  * et 老数据格式例子
  * {
  *    createTimes: [ // createTimes --- layout的id
  *      { value: ['2021-07-01'] } // value ---- items的key
  *    ]
  * }
  * @param oldDatas 
  */
  @action
  transOldDatas = (oldDatas: AnyObj, disabledUpdateData?: boolean) => {
    let newData = {};
    const { colSpan, formStore: { addCustomLayout, updateDatas } } = this.parent;
    Object.keys(oldDatas).forEach((layoutId) => {
      const layout = this.ruleTable[layoutId];
      const data = oldDatas[layoutId];
      if (data instanceof Array) {
        data.forEach((da, index) => {
          if (da.fieldId) {
            const nowLayout = this.getLayout(da.fieldId) as FormLayoutProps;
            const nowRuleItemsTable = this.getRuleItemsTable(nowLayout.items);
            if (nowLayout.groupId === 'commonGroup' && !nowLayout.hide && index === 0) {
              newData = {
                ...newData,
                ...this.changeOldSingleData(da, '', nowRuleItemsTable),
              }
            } else {
              addCustomLayout('otherGroup', colSpan, nowLayout.labelId || nowLayout.id, {
                changeSAOldData: (labelId: string) => this.changeOldSingleData(da, labelId, nowRuleItemsTable),
              });
            }
          } else if (layout) {
            if (index === 0 && !layout.hide) { // 默认布局常用条件（显示）
              newData = {
                ...newData,
                ...this.changeOldSingleData(da, '', layout.ruleItemsTable),
              }
            } else { // 新增其他条件
              addCustomLayout('otherGroup', colSpan, layout.labelId || layout.id, {
                changeSAOldData: (labelId: string) => this.changeOldSingleData(da, labelId, layout.ruleItemsTable),
              });
            }
          }
        })
      }
    })
    updateDatas(newData);
    // 老数据转换后更新数据库
    if (!disabledUpdateData) this.saveOldData();
  }
  /**
   * hasMatchRule
   * @param itemKey 
   * @param cascadeRules 
   * @returns 
   */
  @action
  hasMatchRule = (itemKey: string, cascadeRules?: CascadeRules): boolean => {
    /**
     * 基于20210919改动 commitid = 21c19870
     * transCustomLayout中当itemKey对应的layout已经存在时，更新其他字段的数据，🈶️级联关系的时候也会再次更新items配置
     * 因此仅需要针对级联关系为主要字段，也就是cascadeRules?.[itemKey]存在的字段判断是否根据级联关系更新items即可
     * (
     *  级联关系复杂的需要业务通过Form的onChange或customHide自行处理，目前仅支持两级级联关系的数据布局转换
     * )
     */
    return cascadeRules?.[itemKey];
    // if (cascadeRules) {
    //   if (cascadeRules[itemKey]) return true;
    //   const cascadeRulesKeys = Object.keys(cascadeRules);
    //   const cascadeRulesKeyLen = Object.keys(cascadeRules).length;
    //   let matchRule = false;
    //   for (let i=0; i < cascadeRulesKeyLen; i++) {
    //     if (matchRule) break;
    //     const data = cascadeRules[cascadeRulesKeys[i]];
    //     if (Object.prototype.toString.call(data) === '[object Array]') {
    //       matchRule = data.indexOf(itemKey) >= 0;
    //     } else if (Object.prototype.toString.call(data) === '[object Object]') {
    //       return this.hasMatchRule(itemKey, data);
    //     }
    //   }
    //   return matchRule;
    // }
    // return false;
  }
  /**
   * filterCascadeRules
   * @param items 当前items配置
   * @param cascadeRules 联动规则
   * @param otherParams 其他数据（所有data、当前字段的value值）
   * @returns 
   */
  @action
  filterCascadeRules = (items: Array<string>, cascadeRules?: CascadeRules, otherParams?: AnyObj) => {
    const value = otherParams?.value, itemKey = otherParams?.itemKey;
    if (cascadeRules && value && itemKey) {
      if (cascadeRules[itemKey]) {
        const rule = cascadeRules[itemKey] instanceof Array ?
          isEmpty(value) ? [] :
            cascadeRules[itemKey]
          : cascadeRules[itemKey][value];
        if (rule) {
          /**
           * http://gitlab.ecology10.cn/FRONTEND/weapp-ui/-/issues/2487
           * 高级搜索常用筛选字段联动：满足值联动配置规则
           */
          let nowRule = rule;
          if (Object.prototype.toString.call(rule) === '[object Object]' && rule.items) {
            nowRule = rule.items;
          }
          const beforeItems = items.length > 1 ? items.slice(0, items.indexOf(itemKey) + 1) : items;
          const nowItems = getCascadeItems(otherParams?.data, nowRule, cascadeRules); // 联动带出字段
          return [...beforeItems, ...nowItems];
        }
      }
    }
    return items;
  }
  /**
   * 通过items获取当前layout的数据
   * @param items的key 
   * @returns layout
   */
  @action
  getLayoutFromItemKey = (itemKey: string, layout: FormLayoutType[], otherParams?: AnyObj) => {
    let nowLayout = null;
    const customIndex = itemKey.indexOf('_customField'); // layout内绑定的items不带customField标记
    if (customIndex >= 0) {
      itemKey = itemKey.slice(0, customIndex);
    }
    let matchRule = false, row = 0, col = 0;
    if (layout) {
      const rowLen = layout.length;
      for (let rowIndex = 0; rowIndex < rowLen; rowIndex++) {
        if (nowLayout) break;
        const colLen = layout[rowIndex].length;
        for (let colIndex = 0; colIndex < colLen; colIndex++) {
          if (nowLayout) break;
          /* 获取初始化联动规则带出的item配置 */
          /**
           * colIndex === 0 代表，联动的item配置，按照从第一个字段开始判断
           * 当A->B->C联动，此时cascadeRules: {A: B, B: C}, 仅判断A即可，因此不支持 存在字段ABC，却只有B联动C的情况
           */
          matchRule = colIndex === 0 && this.hasMatchRule(itemKey, layout[rowIndex][colIndex].cascadeRules);
          if (layout[rowIndex][colIndex].items?.indexOf(itemKey) >= 0 || matchRule) { // items配置存在当前字段或者级联关系内包含当前字段
            const nowItems = this.filterCascadeRules(layout[rowIndex][colIndex].items, layout[rowIndex][colIndex].cascadeRules, {
              ...otherParams,
              itemKey,
            });
            nowLayout = {
              ...layout[rowIndex][colIndex],
              items: nowItems,
              labelSpan: isNumber(this.parent.formStore.customLabelSpan) ? this.parent.formStore.customLabelSpan : layout[rowIndex][colIndex]?.labelSpan,
            };
            row = rowIndex;
            col = colIndex;
          }
        }
      }
    }
    return {
      nowlayout: getLayoutFromItemKey(this.parent, nowLayout, otherParams),
      matchRule,
      row,
      col,
    };
  }

  /**
   * getShowItems 返回默认展示的items字段名称(即custom !== true的分组内所有layout设置了hide!=true的字段)
   * @param content 
   */
  @action
  getShowItems = (content: FormInitAllDatas, otherParams?: AnyObj) => {
    let showItems: Set<string> = new Set();
    /**
     * http://gitlab.ecology10.cn/FRONTEND/weapp-ui/-/issues/2784
     * 未配置groups及groups为[]时，默认为['commonGroup'](常用分组)
     */
    const groupIds = content?.groups && content?.groups.length > 0 ? content?.groups.filter((group) => !group.custom)?.map((group) => group.id) : ['commonGroup'];
    const customHides = this.parent.formStore.customHides || {};
    if (content.layout) {
      content.layout?.forEach((row) => {
        row.forEach((col) => {
          const hide = otherParams?.filterItemsBaseOnCustomHides && customHides[col.id] !== undefined ? customHides[col.id] : col.hide;
          if (!hide && col.custom !== true && (!col.groupId || (col.groupId && groupIds.indexOf(col.groupId) >= 0))) {
            col.items?.forEach((itemKey) => {
              if (otherParams?.filterItemsBaseOnCustomHides) {
                /*
                  http://gitlab.ecology10.cn/FRONTEND/weapp-ui/-/issues/2321  问题5
                  常用分组，通过常用条件设置隐藏的字段，customHides[col.id]=true，也需要过滤抛出给业务的数据(用于filterLessData)
                */
                customHides[col.id] !== true && showItems.add(itemKey); // customHide内col.id对应的标记为true，代表隐藏字段，不存在不代表改字段隐藏
              } else {
                showItems.add(itemKey);
              }
            })
          }
        })
      })
    }
    return showItems;
  }
  /**
   * getAllItemsFromLayout 获取当前布局内所有的items配置，包括级联关系
   * @param layout 
   * @returns 
   */
  @action
  getAllItemsFromLayout = (cascadeRules: CascadeRules, items: Array<string>) => {
    let allItems = items;
    if (cascadeRules) {
      Object.keys(cascadeRules).forEach((cascaKey) => {
        const data = cascadeRules[cascaKey];
        if (Object.prototype.toString.call(data) === '[object Array]') {
          allItems = allItems.concat(data);
        } else if (Object.prototype.toString.call(data) === '[object Object]') {
          allItems = this.getAllItemsFromLayout(data, allItems);
        }
      })
    }
    return allItems;
  }
  /**
   * transDatePickerConfig
   * 移动端日期字段配置转换
   * @param items 
   */
  @action
  transDatePickerConfig = (items: FormItemProps) => {
    let customItems = deepClone(items) as FormItemProps;
    if (items) {
      Object.keys(items).forEach((itemKey) => {
        const item = items[itemKey];
        if (
          (item.itemType === 'DATETIMEPICKER' && (item.isRange || item.otherParams?.isRange)) ||
          (
            item.itemType === 'DATEPICKER' &&
            (item.isRange || item.otherParams?.isRange || isMultiDatePickerType(item.type) || isMultiDatePickerType(item.otherParams?.type))
          )
        ) {
          customItems[itemKey] = {
            ...customItems[itemKey],
            itemType: 'DATETIMEPICKER',
            isRange: true,
            inSearchAdvanced: true,
            otherParams: {
              ...(customItems[itemKey]?.otherParams || {}),
              isRange: true,
              inSearchAdvanced: true,
            }
          }
        }
      })
    }
    return customItems;
  }

  /**
   * 转换日期SelectedKeyPrior字段(日期区间存为固定值功能)
   * 开启enableLatestFormConfig，item配置以业务传入的为准，业务不会存储是否存为固定值标记，因此需按照data内存储的SelectedKeyPrior标记更新item
   * @param item 
   * @returns 
   */
  @action
  _transSelectedKeyPrior = (item: FormSingleItemProps, data: any) => {
    if (!this.enableLatestFormConfig) return item;
    if (isObject(item) && isObject(data) && needSync('selectedKeyPrior', data)) {
      return {
        ...item,
        selectedKeyPrior: data.selectedKeyPrior,
        otherParams: {
          ...(item.otherParams || {}),
          selectedKeyPrior: data.selectedKeyPrior,
        }
      }
    }
    return item;
  }

  // 更新自定义布局数据 ---- 转换布局数据内部使用
  @action
  changeSingleCustomLayout = (customLayout: FormLayoutType[], labelId: string, layout: any) => {
    let row = null;
    for (let rowIndex = 0; rowIndex < customLayout.length; rowIndex++) {
      row = customLayout[rowIndex].find((col) => col.labelId === labelId);
      if (row) {
        customLayout[rowIndex].forEach((col) => {
          if (col.labelId === labelId) {
            Object.keys(layout).forEach((key) => {
              layout[key] && (col[key] = layout[key]);
            })
          }
        })
        break;
      }
    }
    return customLayout;
  }

  /**
   * 根据配置返回布局信息
     开启enableLatestFormConfig，以业务返回的最新布局未准
   * @param content 
   * @returns 
   */
  @action
  _getFormConfig = (content: FormInitAllDatas, disabledLatestFormConfig?: boolean) => {
    const items = this.enableLatestFormConfig && !disabledLatestFormConfig ? this.parent.formStore.initItems : content.items;
    const layout = this.enableLatestFormConfig && !disabledLatestFormConfig ? this.parent.formStore.layout : content.layout;
    const groups = this.enableLatestFormConfig && !disabledLatestFormConfig ? this.parent.formStore.groups : content.groups;
    return {
      items, layout, groups
    }
  }
  /**
   * 常用筛选自定义布局转换
   * 常用筛选保存默认layout、items、groups及所有的datas数据
   * 数据量过大，影响接口速度，则自定义布局从datas内推
   * 自定义布局指分组custom=true对应的layout布局
   */
  /**
   * 2022-11-11定
   * 接后台自定义设置浏览框高级搜索需求，重新确定需求。
   * 隐藏的常用条件不显示，但作为条件
   * 前提：
      第1步：常用筛选设置了常用条件A=xxx
      第2步：前台将常用条件A隐藏
      第3步：无后台设置项或生效的为前台的个人设置
    前台：选择常用条件的时候
    （1）高级搜索如何显示？期望：不显示A=xxx
    （2）数据是以哪个条件设置为准？ 期望： A=xxx生效 

    实现方式：
    1.transCustomLayout 判断常用条件字段(无customFields后缀)，且被隐藏（showItems内无对应数据），数据存入defaultData备份
    2.满足1，阻止新增字段
    3.getSearchValue时调用filterUselessDatas方法过滤数据，在filterUselessDatas方法，保留defaultData，作为搜索条件项返回
    4.取消选中/删除 常用筛选需清空defaultData，避免脏数据污染
   */
  @action
  transCustomLayout = (content: FormInitAllDatas, disabledLatestFormConfig?: boolean, readOnlyItems?: Set<string | undefined>, hideLayouts?: Set<string | undefined>, customHideLayouts?: Set<string | undefined>, _otherConfigs?: AnyObj) => {
    if (content && content.customLayout) { // 存储过其他条件布局的数据无需转换
      return content;
    }
    const { transItems, transLayout, transCommonGroupItems } = getBrowserConfigUtils();
    const { colSpan } = this.parent;
    let { data } = content;
    let { items, layout, groups } = this._getFormConfig(content, disabledLatestFormConfig);
    layout = transLayout?.(layout, hideLayouts, _otherConfigs) || layout; // 浏览框自定义设置高级搜索布局处理
    const showItems = this.getShowItems(_otherConfigs ? { ...content, layout } : content); // 获取当前默认展示的字段
    let customLayout = { otherGroup: [] as FormLayoutType[] }; // 获取当前自定义布局（高级搜索组件常用条件分组id=commonGroup、其他条件分组id=otherGroup）
    if (this.parent.isMobile) items = this.transDatePickerConfig(items); // pc端的日期区间规则转换成H5
    let customItems = { ...items } as FormItemProps;
    const otherGroup: AnyObj = {}; // {labelId：layout布局}（一个layout支持配置多个items，则data中labelId会重复）
    const customItemConfig: AnyObj = {};; // {labelId: item's keys}
    const otherConfigs = { customItems, ..._otherConfigs };
    /*
      1.customItemConfig记录data里面不带customField标记的数据，但当前默认不展示的字段（showItems内没有该字段）
      2.这一类数据需在otherGroup内新增一行显示，且每行存在一个或多个字段值
      3.由于data内不可重名，则有且仅有一行数据会是没有customField标记，却需要自定义新增一行展示的
      4.综上所述，以labelId，对应存储items的key即可找到对应关系，判断当前是需要新增一行(add custom layout)还是更新数据(update value)
    */
    if (data) {
      Object.keys(data).forEach((dataKey) => {
        const customIndex = dataKey.indexOf('_customField');
        let labelId = dataKey.slice(customIndex + 1);
        let itemKey = dataKey.slice(0, customIndex); // items对应的key
        let customItemKey = dataKey;
        const mustDisplayInOtherGroup = customIndex < 0; // 数据没有_customField后缀却又要显示在自定义分组里面的字段需生成一个自定义的labelId
        if (mustDisplayInOtherGroup && !showItems.has(dataKey)) {
          labelId = `customField${getRandom()}`;
          itemKey = dataKey;
          customItemKey = `${dataKey}_${labelId}`;
        }
        let forbidAddCustomLayout = mustDisplayInOtherGroup && _otherConfigs?.forbidAddCustomLayout && !_otherConfigs?.needAddCustomLayoutKeys?.has(dataKey);
        const { hasDefaultConfig, needTransBrowserConfigLayout } = this.parent.browserConfigStore || {};
        // 存在常用条件+常用条件被隐藏+不存在后台设置或生效的为前台的个人设置 = 不展示，但要作为搜索条件
        if (mustDisplayInOtherGroup && isCustomField(customItemKey) && (!hasDefaultConfig || !needTransBrowserConfigLayout)) {
          this.defaultData = { ...this.defaultData, [dataKey]: data[dataKey] }
          return;
        }
        /* 后台设置禁用/隐藏的常用分组字段，非空的字段才需要展示在其他分组内 */
        if (this.parent.browserConfigStore?.isFieldEmpty?.(itemKey, layout, { data, value: data[dataKey], mustDisplayInOtherGroup })) {
          forbidAddCustomLayout = true;
        }
        /* -------------------------------------------------------------------------------------------------------------- */
        if (!forbidAddCustomLayout && items[itemKey] && (customIndex >= 0 || (!showItems.has(dataKey)))) { // 默认显示字段分组（custom=false）内没有匹配的字段，需新增其他条件显示
          let customItemKeys = '';
          // 通过customItemConfig存储的item配置判断，是继续新增行还是更新数据
          // mustDisplayInOtherGroup ：常用分组的数据，但是默认不显示的字段，需要在其他条件组显示
          if (mustDisplayInOtherGroup) {
            const customItemConfigKey = Object.keys(customItemConfig);
            const customItemConfigLen = customItemConfigKey.length;
            for (let i = 0; i < customItemConfigLen; i++) {
              const customLabelId = customItemConfigKey[i] as string;
              if (customItemConfig[customLabelId]?.indexOf(dataKey) >= 0) {
                customItemKeys = customLabelId;
                break;
              }
            }
          }
          if (customItemKeys) { // 对应字段已经存在
            /* ----------------------- 新增items配置 ------------------------------- */
            customItems[`${dataKey}_${customItemKeys}`] = this._transSelectedKeyPrior(customItems[`${dataKey}_${customItemKeys}`] || {
              ...items[itemKey],
              value: data[dataKey],
            }, data[dataKey]);
            customItems[`${dataKey}_${customItemKeys}`] = transItems(`${dataKey}_${customItemKeys}`, customItems[`${dataKey}_${customItemKeys}`] as any, readOnlyItems, otherConfigs) as FormSingleItemProps;
            /* ----------------------- 新增data数据 ------------------------------- */
            data = {
              ...data,
              [`${dataKey}_${customItemKeys}`]: data[dataKey],  // 数据key与item的key一致，数据与items内value保持一致
            } //同步默认显示在其他分组的数据
            /* ----------------------- 更新layout ------------------------------- */
            /*
              对应字段已经存在，需更新items配置，解决带级联关系，且主字段数据后返回的情况下，联动失效的问题.
              即当字段A带出字段B的联动关系，字段B的数据在字段A之后返回，layout中items配置的关系会解析错误(接口无法保证数据返回的前后关系)
            */
            const { matchRule, nowlayout } = this.getLayoutFromItemKey(itemKey, layout, { data, value: data[dataKey], labelId: customItemKeys }) as unknown as FormLayoutProps;
            if (nowlayout?.cascadeRules && matchRule) { // 更新字段，有级联关系，且能够匹配上的需更新items
              customLayout = {
                ...customLayout,
                otherGroup: this.changeSingleCustomLayout(customLayout.otherGroup, customItemKeys, {
                  items: nowlayout.items,
                  disabled: customHideLayouts?.has(customItemKeys),
                }),
              }
            }
          } else {  // 新增行, 必须更新layout
            /* ----------------------- 新增布局 ------------------------------- */
            const { nowlayout, matchRule } = this.getLayoutFromItemKey(itemKey, layout, { data, value: data[dataKey], labelId }) as unknown as FormLayoutProps;
            if (nowlayout) {
              // 新增自定义字段
              const field: FormLayoutProps = {
                ...nowlayout,
                labelId, // 随机数标记，区分layout id重复的字段
                groupId: 'otherGroup',
                colSpan,
                selectedFieldId: nowlayout.id, // label 下拉框默认选中项
                customId: nowlayout.id, // 自定义组 layout id 
                disabled: customHideLayouts?.has(labelId),
                hide: false,
              };
              /* ----------------------- 新增items配置 ------------------------------- */
              customItems[customItemKey] = this._transSelectedKeyPrior(customItems[customItemKey] || {
                ...items[itemKey],
                value: data[dataKey],
              }, data[dataKey]);
              customItems[customItemKey] = transItems(customItemKey, customItems[customItemKey] as any, readOnlyItems, otherConfigs) as FormSingleItemProps;
              /* ----------------------- 新增data数据 ------------------------------- */
              !forbidAddCustomLayout && (data = {
                ...data,
                [`${customItemKey}`]: data[dataKey], // 数据key与item的key一致，数据与items内value保持一致
              }) //同步默认显示在其他分组的数据
              /* ------------------------------------------------------------------ */
              /**
               * 解决常用筛选，其他条件分组，级联部分字段空白的问题
               * 原因：配置cascadeRules(级联关系)的字段，默认不显示的item字段配置会丢失，造成切换字段值，联动的字段带不出来的情况
               */
              const allItems = this.getAllItemsFromLayout(nowlayout, nowlayout.items);
              const allItemsSet = new Set(allItems);
              allItemsSet.size > 0 && allItemsSet.forEach((it) => {
                if (!customItems[`${it}_${labelId}`]) {
                  customItems[`${it}_${labelId}`] = {
                    ...items[it],
                  }
                  customItems[`${it}_${labelId}`] = transItems(`${it}_${labelId}`, customItems[`${it}_${labelId}`] as any, readOnlyItems, otherConfigs) as FormSingleItemProps;
                  /**
                   * <特殊情况>：首轮nowlayout获取不到
                   * mustDisplayInOtherGroup需再次更新data，以解决不修改数据直接保存常用筛选，带级联关系的部分字段数据丢失的情况
                   */
                  if (mustDisplayInOtherGroup) {
                    data = {
                      ...data,
                      [`${it}_${labelId}`]: data[`${it}_${labelId}`] || data[it],
                    }
                  }
                }
              })

              /* ----------------------------- 更新customLayout并标记新增layout --------------------------------- */
              if (!otherGroup[`${labelId}`]) {
                otherGroup[`${labelId}`] = field;
                const len = customLayout.otherGroup?.length || 0;
                if (len === 0 || !colSpan) { // 未设置colSpan，则一行一列新增
                  customLayout.otherGroup?.push(
                    [field],
                  )
                } else if (colSpan && customLayout.otherGroup) {
                  // 设置了colSpan，则layout布局规则不起作用，新增项加在最后一行就行
                  customLayout.otherGroup[len - 1].push(field);
                }
                if (mustDisplayInOtherGroup) {
                  customItemConfig[`${labelId}`] = field.items;
                }
              } else if (otherGroup[`${labelId}`].items?.indexOf(itemKey) >= 0 || matchRule) { // 对应getLayoutFromItemKey方法
                /**
                 * http://gitlab.ecology10.cn/FRONTEND/weapp-ui/-/issues/2487
                 * 修复其他分组字段联动失效问题
                 */
                otherGroup[`${labelId}`].items = field.items; // 引用类型，暂无需遍历customLayout内数据
              }
            } else {
              // <特殊情况>
              // nowlayout 取不到，则当前dataKey为级联关系上默认不显示的字段
              // 则本轮新增字段处理轮空，需等处理主字段数据的时候处理联动关系，获取对应的layout中的items配置，一并更新数据
            }
          }
        } else {
          /**
           * http://gitlab.ecology10.cn/FRONTEND/weapp-ui/-/issues/2738
           * 常用分组字段联动处理
           */
          const { nowlayout, row, col } = this.getLayoutFromItemKey(dataKey, layout, { data, value: data[dataKey] }) as unknown as FormLayoutProps;
          if (nowlayout?.cascadeRules && nowlayout?.cascadeRules[dataKey]) { // 当常用分组字段存在联动规则，且存在当前dataKey对应的字段联动规则，items配置需更新
            layout[row][col].items = nowlayout.items;
          }
          /**
           * http://gitlab.ecology10.cn/FRONTEND/weapp-ui/-/issues/2752
           * 常用分组selectedKeyPrior标记处理
           */
          customItems[dataKey] = this._transSelectedKeyPrior(customItems[dataKey] || {
            ...items[dataKey],
            value: data[dataKey],
          }, data[dataKey]);
        }
      })
    }
    // const lostConditionDatas = this.__getLostConditionData(data); // 需在initForm之前获取数据处理
    this.parent.formStore.initForm({ ...content, data, layout, items: customItems, groups, forbidSyncDatasFromItems: true }); // 常用筛选条件的数据以data为准，设置forbidSyncDatasFromItems，以免items内value污染默认值
    this.parent.formStore.resetForm(); // 清除上次选中项的多余数据

    transCommonGroupItems?.(readOnlyItems, this.parent.formStore, otherConfigs); // 适配浏览框自定义设置，常用分组只读数据

    // this.parent.formStore.updateDatas(lostConditionDatas); // 更新当前丢失数据的条件字段值
    /* -- 更新formStore内allFields(不更新searchAdvancedStore内的allFields，用于取消选中重置) ------------------------ */
    let allFields: FieldProps[] = [];
    layout?.forEach((row) => {
      row.forEach((col) => {
        const temp = this.parent.getInitField(col, content);
        allFields.push(temp);
      })
    })
    this.parent.formStore.setAllFields(allFields);
    /* --------------------------------------------------------------------------------------------------------- */
    if (customLayout) {
      this.parent.formStore.setAllCustomLayout(customLayout, true);
      const chooseData = this.parent.commonFilterStore?.chooseData || {};
      this.parent.commonFilterStore?.setState({
        chooseData: {
          ...chooseData,
          customLayout, // 存储当前的自定义布局
          customItems, // 存储当前的自定义items
        }
      })
    }
    this._fixedInitData(customItems);
    return { customLayout, items: customItems, layout };
  }

  /**
   * 内部使用
   * fix(searchadvanced): 修复条件关系字段默认值丢失问题
   * http://gitlab.ecology10.cn/FRONTEND/weapp-ui/-/issues/2321    (问题3 --- 引发 http://gitlab.ecology10.cn/FRONTEND/weapp-ui/-/issues/2794，逻辑冲突)
   * 
   * 复现场景：
   * 隐藏带条件关系的字段，存为常用筛选。此时先开放之前隐藏的字段，再选中保存的常用筛选项。隐藏的字段中条件关系默认值未带上
   * 
   * 原因：
   * 由于高级搜索常用筛选回显数据完全以data为准(transCustomLayout内设置forbidSyncDatasFromItems)，而当前字段默认值以初始化表单时的data为准
   * 当字段隐藏后存为常用筛选，不会存储隐藏字段的值，因此，再次开启会丢失默认值
   * 
   *  解决方案：
   * 选中常用筛选项，更新字段默认值
   * 
   * 需更新的字段需满足以下条件：
   * 1.选中了常用筛选
   * 2.datas内没有字段默认值，且store.allDatas内设置了默认值
   * 3.store.allDatas 仅在高级搜索组件通过init初始化时设置，即高级搜索组件初始化
   * 4.formStore.initDatas等选中常用筛选也会被更新
  */
  // 获取当前丢失数据的条件字段值
  @action
  __getLostConditionData = (data: FormDatas) => {
    const commonValue: FormDatas = {};
    const initItems = this.parent.allDatas.items;
    const initDatas = this.parent.allDatas.data;
    if (this.parent.commonFilterStore.id) {
      this.parent.commonCondition?.forEach((col) => {
        col.items?.forEach((itemKey: string) => {
          const initData = initDatas[itemKey] || initItems[itemKey]?.value; // 优先取initDatas的值，同Form组件设计
          if (isEmpty(data?.[itemKey]) && !isEmpty(initData)) {
            commonValue[itemKey] = initData;
          }
        })
      })
      return commonValue;
    }
    return {};
  }
  /*
    * http://gitlab.ecology10.cn/FRONTEND/weapp-ui/-/issues/2672
    * 原因：forbidSyncDatasFromItems=true, Form默认值强制以data为准的情况
    * 对于字段仅存在其他分组的情况，其他分组添加搜索条件新增的条件关系字段(item.condition=true)无法带出默认值
    * 以下两种情况，在其他分组显示。
        1.分组id不为commonGroup
        2.常用分组内默认不显示的字段
    * 
    * 解决方案：initForm(初始化后)，单独更新formStore.initData，补充仅存在其他分组字段的默认值(补充不带customField标记的数据)
    */
  @action
  _fixedInitData = (initItems: FormItemProps) => {
    const { formStore: { setState, initDatas, allFields, customHides } } = this.parent;
    // 获取非常用分组itemKey集合
    let itemKeys = new Set();
    allFields?.forEach((field) => {
      if (field.groupId !== 'commonGroup' || (field.groupId === 'commonGroup' && customHides[field.id as string] === true)) {
        field.items.forEach((itemkey: string) => itemKeys.add(itemkey));
      }
    })
    let resInitDatas = { ...initDatas };
    Object.keys(initItems).forEach((datakey) => {
      if (itemKeys.has(datakey) && isEmpty(initDatas[datakey]) && !isEmpty(!initItems[datakey].value)) {
        resInitDatas = {
          ...resInitDatas,
          [datakey]: initItems[datakey].value,
        }
      }
    })
    setState({ initDatas: resInitDatas });
  }
  /**
   * filterUselessDatas 过滤无用data，即没有展示出的字段信息
   */
  @action
  filterUselessDatas = (content: FormInitAllDatas) => {
    const showItems = this.getShowItems(content, { filterItemsBaseOnCustomHides: true }); // 获取当前默认展示的字段
    const { customLayout } = content;
    const showCustomItems = new Set(); // 其他条件组默认展示的字段
    if (customLayout) {
      Object.keys(customLayout).forEach((customKey) => {
        const layout = customLayout[customKey];
        layout?.forEach((row) => {
          row.forEach((col) => {
            if (!col.hide) {
              col.items?.forEach((itemKey) => {
                showCustomItems.add(`${itemKey}_${col.labelId}`);
              })
            }
          })
        })
      })
    }
    let datas: FormDatas = {};
    if (showCustomItems.size === 0 && showItems.size === 0 && !this.keepOriginData) return {};
    if (content?.data) {
      Object.keys(content?.data).forEach((dataKey) => {
        if (content?.data[dataKey] !== null && content?.data[dataKey] !== undefined) {
          // 展示的字段+后台隐藏的字段(有默认值)
          if (showCustomItems.has(dataKey) || showItems.has(dataKey) || this.parent.browserConfigStore?.hasDefaultData?.(dataKey) || needSync(dataKey, this.defaultData)) {
            datas = datas ? {
              ...datas,
              [dataKey]: content?.data[dataKey],
            } : {
              [dataKey]: content?.data[dataKey],
            }
          }
        }
      })
    }
    return datas;
  }
}

export type TransDatasStoreType = Pick<TransDatasStore, keyof TransDatasStore>;
export default TransDatasStore;