import { MultiBtnDataType, PrimaryKeyType, DateType } from '../../types'
import { isEqual, isNil } from '../../../../utils'
import { dayjs, getLabel, classUseMemo } from '@weapp/utils'
import { toJS } from 'mobx'

// -------------------------------------数据常量-------------------------------------
const dateAry = ()=> [
  {
    type: 'today',
    title: getLabel('30091', '今天'),
    startDate: dayjs(),
    endDate: dayjs(),
  },
  {
    type: 'yesterday',
    title: getLabel('58169', '昨天'),
    startDate: dayjs().subtract(1, 'day'),
    endDate: dayjs().subtract(1, 'day'),
  },
  {
    type: 'tomorrow',
    title: getLabel('58170', '明天'),
    startDate: dayjs().add(1, 'day'),
    endDate: dayjs().add(1, 'day'),
  }
]
const monthAry = ()=>[
  {
    type: 'thisMonth',
    title: getLabel('30242', '本月'),
    startDate: dayjs().set('date', 1),
    endDate: dayjs()
      .set('month', dayjs().month() + 1)
      .set('date', 0),
  },
  {
    type: 'lastMonth',
    title: getLabel('58178', '上一月'),
    startDate: dayjs()
      .set('month', dayjs().month() - 1)
      .set('date', 1),
    endDate: dayjs()
      .set('month', dayjs().month() - 1 + 1)
      .set('date', 0),
  },
  {
    type: 'nextMonth',
    title: getLabel('58179', '下一月'),
    startDate: dayjs()
      .set('month', dayjs().month() + 1)
      .set('date', 1),
    endDate: dayjs()
      .set('month', dayjs().month() + 1 + 1)
      .set('date', 0),
  },
]
const season = dayjs().quarter() //本季度开始日
const seasonAry = ()=>[
  {
    type: 'thisSeason',
    title: getLabel('58180', '本季'),
    startDate: dayjs()
      .set('month', (season - 1) * 3)
      .set('date', 1),
    endDate: dayjs()
      .set('month', season * 3)
      .set('date', 0),
  },
  {
    type: 'lastSeason',
    title: getLabel('58182', '上一季'),
    startDate: dayjs()
      .set('month', (season - 1 - 1) * 3)
      .set('date', 1),
    endDate: dayjs()
      .set('month', (season - 1) * 3)
      .set('date', 0),
  },
  {
    type: 'nextSeason',
    title: getLabel('58183', '下一季'),
    startDate: dayjs()
      .set('month', (season - 1 + 1) * 3)
      .set('date', 1),
    endDate: dayjs()
      .set('month', (season + 1) * 3)
      .set('date', 0),
  },
]
const yearAry =()=> [
  {
    type: 'thisYear',
    title: getLabel('30573', '今年'),
    startDate: dayjs().set('month', 0).set('date', 1),
    endDate: dayjs()
      .set('year', dayjs().year() + 1)
      .set('month', 0)
      .set('date', 0),
  },
  {
    type: 'firstHalfYear',
    title: getLabel('18856', '上半年'),
    startDate: dayjs()
      .set('year', dayjs().year())
      .set('month', 0)
      .set('date', 1),
    endDate: dayjs()
      .set('year', dayjs().year())
      .set('month', 6)
      .set('date', 0),
  },
  {
    type: 'nextHalfYear',
    title: getLabel('19143', '下半年'),
    startDate: dayjs()
      .set('year', dayjs().year())
      .set('month', 6)
      .set('date', 1),
    endDate: dayjs()
      .set('year', dayjs().year() + 1)
      .set('month', 0)
      .set('date', 0),
  },
  {
    type: 'lastYear',
    title: getLabel('21195', '去年'),
    startDate: dayjs()
      .set('year', dayjs().year() - 1)
      .set('month', 0)
      .set('date', 1),
    endDate: dayjs()
      .set('year', dayjs().year() - 1 + 1)
      .set('month', 0)
      .set('date', 0),
  },
  {
    type: 'nextYear',
    title: getLabel('58186', '明年'),
    startDate: dayjs()
      .set('year', dayjs().year() + 1)
      .set('month', 0)
      .set('date', 1),
    endDate: dayjs()
      .set('year', dayjs().year() + 1 + 1)
      .set('month', 0)
      .set('date', 0),
  },
]

/**
 * @description: 动态产生原生数组（只有点击的时候才会开始计算）
 * @param {number} weekStart
 * @return {*}
 */
export function genPrimaryAry(weekStart: number = 0) {
  const d = dayjs().day()
  const diff = weekStart <= d ? weekStart - d : weekStart - d - 7 //每周开始日调整
  // const diff = weekStart <= dayjs().day() ? weekStart - dayjs().day() : weekStart - dayjs().day() - 7 //每周开始日调整
  const weekAry = ()=>[
    {
      type: 'thisWeek',
      title: getLabel('28591', '本周'),
      startDate: dayjs().add(diff, 'day'),
      endDate: dayjs().add(diff, 'day').add(6, 'day'),
    },
    {
      type: 'lastWeek',
      title: getLabel('70231', '上一周'),
      startDate: dayjs().add(diff, 'day').subtract(7, 'day'),
      endDate: dayjs().add(diff, 'day').add(6, 'day').subtract(7, 'day'),
    },
    {
      type: 'nextWeek',
      title: getLabel('58173', '下一周'),
      startDate: dayjs().add(diff, 'day').add(7, 'day'),
      endDate: dayjs().add(diff, 'day').add(6, 'day').add(7, 'day'),
    },
  ]

  const primaryAry: MultiBtnDataType[] = [
    ...dateAry(),
    ...weekAry(),
    ...monthAry(),
    ...seasonAry(),
    ...yearAry(),
  ]
  primaryAry.map((item: MultiBtnDataType) => {
    item.startDate = (item.startDate as dayjs.Dayjs).hour(0).minute(0).second(0)
    item.endDate = (item.endDate as dayjs.Dayjs).hour(23).minute(59).second(59)
    return item
  })
  return primaryAry
}

/**
 * @description: 根据title的type动态拿到数据
 * @param {number} weekStart
 * @return {*}
 */
export function getPrimaryInfo(type: string, primaryKey?: PrimaryKeyType[], customMultiBtnData?: MultiBtnDataType[], weekStart: number = 0) {
  const d = dayjs().day()
  const diff = weekStart <= d ? weekStart - d : weekStart - d - 7 //每周开始日调整
  const season = dayjs().quarter() //本季度开始日
  let info: MultiBtnDataType | undefined
  if (isNil(primaryKey) || (primaryKey.length > 0 && primaryKey.indexOf(type as any) !== -1)) {
    switch (type) {
      case 'today':
        info = {
          type: 'today',
          title: getLabel('30091', '今天'),
          startDate: dayjs(),
          endDate: dayjs(),
        }
        break;
      case 'yesterday':
        info = {
          type: 'yesterday',
          title: getLabel('58169', '昨天'),
          startDate: dayjs().subtract(1, 'day'),
          endDate: dayjs().subtract(1, 'day'),
        }
        break;
      case 'tomorrow':
        info = {
          type: 'tomorrow',
          title: getLabel('58170', '明天'),
          startDate: dayjs().add(1, 'day'),
          endDate: dayjs().add(1, 'day'),
        }
        break;
      case 'thisWeek':
        info = {
          type: 'thisWeek',
          title: getLabel('28591', '本周'),
          startDate: dayjs().add(diff, 'day'),
          endDate: dayjs().add(diff, 'day').add(6, 'day'),
        }
        break;
      case 'lastWeek':
        info = {
          type: 'lastWeek',
          title: getLabel('70231', '上一周'),
          startDate: dayjs().add(diff, 'day').subtract(7, 'day'),
          endDate: dayjs().add(diff, 'day').add(6, 'day').subtract(7, 'day'),
        }
        break;
      case 'nextWeek':
        info = {
          type: 'nextWeek',
          title: getLabel('58173', '下一周'),
          startDate: dayjs().add(diff, 'day').add(7, 'day'),
          endDate: dayjs().add(diff, 'day').add(6, 'day').add(7, 'day'),
        }
        break;
      case 'thisMonth':
        info = {
          type: 'thisMonth',
          title: getLabel('30242', '本月'),
          startDate: dayjs().set('date', 1),
          endDate: dayjs()
            .set('month', dayjs().month() + 1)
            .set('date', 0),
        }
        break;
      case 'lastMonth':
        info = {
          type: 'lastMonth',
          title: getLabel('58178', '上一月'),
          startDate: dayjs()
            .set('month', dayjs().month() - 1)
            .set('date', 1),
          endDate: dayjs()
            .set('month', dayjs().month() - 1 + 1)
            .set('date', 0),
        }
        break;
      case 'nextMonth':
        info = {
          type: 'nextMonth',
          title: getLabel('58179', '下一月'),
          startDate: dayjs()
            .set('month', dayjs().month() + 1)
            .set('date', 1),
          endDate: dayjs()
            .set('month', dayjs().month() + 1 + 1)
            .set('date', 0),
        }
        break;
      case 'thisSeason':
        info = {
          type: 'thisSeason',
          title: getLabel('58180', '本季'),
          startDate: dayjs()
            .set('month', (season - 1) * 3)
            .set('date', 1),
          endDate: dayjs()
            .set('month', season * 3)
            .set('date', 0),
        }
        break;
      case 'lastSeason':
        info = {
          type: 'lastSeason',
          title: getLabel('58182', '上一季'),
          startDate: dayjs()
            .set('month', (season - 1 - 1) * 3)
            .set('date', 1),
          endDate: dayjs()
            .set('month', (season - 1) * 3)
            .set('date', 0),
        }
        break;
      case 'nextSeason':
        info = {
          type: 'nextSeason',
          title: getLabel('58183', '下一季'),
          startDate: dayjs()
            .set('month', (season - 1 + 1) * 3)
            .set('date', 1),
          endDate: dayjs()
            .set('month', (season + 1) * 3)
            .set('date', 0),
        }
        break;
      case 'thisYear':
        info = {
          type: 'thisYear',
          title: getLabel('30573', '今年'),
          startDate: dayjs().set('month', 0).set('date', 1),
          endDate: dayjs()
            .set('year', dayjs().year() + 1)
            .set('month', 0)
            .set('date', 0),
        }
        break;
      case 'firstHalfYear':
        info = {
          type: 'firstHalfYear',
          title: getLabel('18856', '上半年'),
          startDate: dayjs()
            .set('year', dayjs().year())
            .set('month', 0)
            .set('date', 1),
          endDate: dayjs()
            .set('year', dayjs().year())
            .set('month', 6)
            .set('date', 0),
        }
        break;
      case 'nextHalfYear':
        info = {
          type: 'nextHalfYear',
          title: getLabel('19143', '下半年'),
          startDate: dayjs()
            .set('year', dayjs().year())
            .set('month', 6)
            .set('date', 1),
          endDate: dayjs()
            .set('year', dayjs().year() + 1)
            .set('month', 0)
            .set('date', 0),
        }
        break;
      case 'lastYear':
        info = {
          type: 'lastYear',
          title: getLabel('21195', '去年'),
          startDate: dayjs()
            .set('year', dayjs().year() - 1)
            .set('month', 0)
            .set('date', 1),
          endDate: dayjs()
            .set('year', dayjs().year() - 1 + 1)
            .set('month', 0)
            .set('date', 0),
        }
        break;
      case 'nextYear':
        info = {
          type: 'nextYear',
          title: getLabel('58186', '明年'),
          startDate: dayjs()
            .set('year', dayjs().year() + 1)
            .set('month', 0)
            .set('date', 1),
          endDate: dayjs()
            .set('year', dayjs().year() + 1 + 1)
            .set('month', 0)
            .set('date', 0),
        }
        break;
      default:
        break;
    }
  }
  if (info) {
    info.startDate = (info.startDate as dayjs.Dayjs).hour(0).minute(0).second(0)
    info.endDate = (info.endDate as dayjs.Dayjs).hour(23).minute(59).second(59)
  }
  if (customMultiBtnData && customMultiBtnData.length > 0) {
    info = customMultiBtnData.find((item) => item.type === type)
  }
  return info
}

/**
 * @description: 用于根据key动态计算日期范围
 * @param {PrimaryKeyType} primaryKey
 * @return {*}
 */
export function getRangeInfoByPrimaryKey(primaryKey: PrimaryKeyType, weekStart: number = 0, customMultiBtnData?: MultiBtnDataType[]) {
  if (!primaryKey) {
    return -1
  } else {
    const primaryAry: MultiBtnDataType[] = genPrimaryAry(weekStart)
    const keyAry = customMultiBtnData
      ? [...customMultiBtnData, ...primaryAry]
      : primaryAry //注意这里的custom的data需要放在primary的前面，保证find的时候优先以自定义的为主
    const data = keyAry.find((item) => item.type === primaryKey)
    if (data) {
      const startDate = typeof data.startDate === 'function' ? data.startDate() : data.startDate
      const endDate = typeof data.endDate === 'function' ? data.endDate() : data.endDate
      data.startDate = new Date(dayjs(startDate).format())
      data.endDate = new Date(dayjs(endDate).format())
      return data
    } else {
      return -1
    }
  }
}

/**
 * @description: 用于计算头部快捷选择按钮类型
 * @param {MultiBtnDataType} multiBtnGroupData
 * @param {string} rangeDate
 * @return {*}
 */
export function computedMultiType(multiBtnGroupData: MultiBtnDataType[] = [], rangeDate: DateType[] = [], timeFormat?: 'HH:mm:ss' | 'HH:mm' | 'HH') {
  if (isEqual(rangeDate, []) || isNil(rangeDate)) {
    return ''
  } else {
    const answer = multiBtnGroupData?.find((item) => {
      const st = typeof item.startDate === 'function' ? item.startDate() : item.startDate
      const et = typeof item.endDate === 'function' ? item.endDate() : item.endDate
      if (!timeFormat) {
        return dayjs(rangeDate[0]).isSame(dayjs(st), 'day') && dayjs(rangeDate[1]).isSame(dayjs(et), 'day')
      } else {
        let type: any = 'second'
        if (timeFormat === 'HH') {
          type = 'hour'
        } else if (timeFormat === 'HH:mm') {
          type = 'minute'
        } else {
          type = 'second'
        }
        // const type = timeFormat === 'HH:mm:ss' ? 'second' : 'minute'
        return dayjs(rangeDate[0]).isSame(dayjs(st), type) && dayjs(rangeDate[1]).isSame(dayjs(et), type)
      }
    })
    return answer ? answer.type : ''
  }
}

/**
 * @description:  获取每个按钮内容值
 * @param {number} weekStart
 * @param {PrimaryKeyType} primaryKey
 * @param {MultiBtnDataType} customMultiBtnData
 * @return {*}
 */
export function getMultiBtnGroupData(primaryKey?: PrimaryKeyType[], customMultiBtnData?: MultiBtnDataType[], weekStart: number = 0) {
  const primaryAry: MultiBtnDataType[] = genPrimaryAry(weekStart)
  let customAry: MultiBtnDataType[] = []
  //如果外部自定义数组
  if (primaryKey) {
    primaryKey.forEach((item) => {
      primaryAry.forEach((ele) => {
        if (item === ele.type) {
          customAry.push(ele)
        }
      })
    })
  } else {
    customAry = primaryAry
  }
  let multiBtnData: MultiBtnDataType[] = []
  multiBtnData = multiBtnData.concat(customAry)
  return customMultiBtnData ? multiBtnData.concat(toJS(customMultiBtnData)) : multiBtnData
}
