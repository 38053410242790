import { ComponentType, CSSProperties, ReactNode } from 'react';
import { RequestOptionConfig } from '@weapp/utils';
import { AxiosResponse, AxiosRequestConfig, Method } from 'axios';
import { ListData } from '../list/index'
import { BaseProps, DataProps, SingleType, AnyObj } from '../../types/common';
import {
  SelectOptionData,
  DialogProps,
  MDialogProps,
  MListData,
  IconNames,
  SecretSelectOptionType,
  SecretSelectProps,
  SecretSelectValueType,
  MSecretSelectProps,
  ITableColumn,
  ITableProps,
  MenuProps
} from '../../lib';
import * as uploadTools from './utils/tools'

export enum ILISTOPTIONTYPEENUM {
  // 名称超出换行，附件信息展示在名称下方，附件大小展示在名称右方，鼠标移入操作栏覆盖附件大小
  OPTIONHOVER = 'optionHover',
  // 名称超出换行，附件信息展示在名称下方，鼠标移入悬浮展示操作栏
  OPTIONPOPOVER = 'optionPopover',
  // 名称超出换行，附件信息展示在名称下方，鼠标移入操作栏覆盖附件信息
  OPTIONCOVER = 'optionCover',
  // 名称超出省略，附件信息展示在名称右方，鼠标移入操作栏覆盖附件信息
  NORMAL = 'normal'
}

export enum OPTIONHOVERPOSITION {
  TOPLEFT = 'topLeft',
  TOPRIGHT = 'topRight',
}

export enum IUPLOADBATCHOPTTYPE {
  SHOWBATCHSIGN = 'showBatchSign',
  BATCHDOWNLOAD = 'batchDownload',
  BATCHDELETE = 'batchDelete'
}

/** 批量操作 option 类型（组件内使用） */
export type batchOption = {
  id: IUPLOADBATCHOPTTYPE;
  content: string;
  icon: string;
  visible: boolean;
}

export type ValueType = string[] | SelectOptionData[];

export type BeforeUploadFileType = File | Blob | boolean | string;

export type Action = string | ((file: IFile) => string | PromiseLike<string>);

export type UploadRequestHeader = Record<string, string>;

export type UploadRequestMethod = 'POST' | 'PUT' | 'PATCH' | 'post' | 'put' | 'patch';

export type UploadListOptionType = "optionHover" | 'optionPopover' | "optionCover" | "normal";
export type UploadOptionHoverPosition = "topLeft" | "topRight";


export interface UploadData extends ListData {
  /** 是否主附件 */
  mainAccess?: boolean;
  /** 自定义主附件角标图标 */
  mainAccessIcon?: string;
  /** 文件后缀名 */
  fileExtendName?: string;
  /** 附件 ID */
  fileid?: string;
  /** 附件名称 */
  filename?: string;
  /** 附件大小(单位M或kb,用来显示) */
  filesize?: string;
  /** 附件大小,单位:字节 */
  filesizeByte?: number;
  /** 附件图标 */
  icon?: string;
  /** 图片地址 */
  imgSrc?: string;
  /** 是否可下载 */
  showLoad?: boolean;
  /** 是否可删除 */
  showDelete?: boolean;
  /** 非图片模式下是表示文件是否是图片格式 */
  isImg?: boolean;
  /** 上传人名称 */
  username?: string;
  /** 上传时间 */
  uploaddate?: string;
  /** 上传人 id */
  userid?: string;
  /** 加密附件 id */
  fileidCode?: string;
  /** 操作按钮（权限）配置 */
  options?: AnyObj[];
  /**
   * 文件状态
   * 0 或者没有值: 附件正常状态
   * 1: 删除状态【文件和当前业务去已除了关联关系，包括文件已经被彻底删除】
   * 2: 文件不存在状态【跟进此附件id，文件服务也查询不到任何信息】
   */
  fileStatus?: number | string;
}

export type UploadOptionType = {
  id: string,
  icon: IconNames,
  content: string,
  url?: string,
  moreType?: boolean,
  onClick?: (key: string, file?: IFile) => void;
}

export enum IUploadListEnum {
  IMAGE = "image",
  LIST = "list"
}

export interface UploadTableProps extends BaseProps {
  weId?: string;
  routeId?: string;
  uploadPrefixCls?: string;
  module?: string;
  autoUpload?: boolean;
  secretProtect?: boolean;
  secLevelDisplayFormat?: number;
  isOpenClassification?: boolean;
  isLocalSecurityAllow?: boolean;
  listT: UploadData[];
  listB: ListData[];
  tableData: UploadData[];
  sortable?: boolean;
  showDelete?: boolean;
  showEdit?: boolean;
  showVersion?: boolean;
  showAppeal?: boolean;
  showRename?: boolean;
  showReUpload?: boolean;
  showPreview?: boolean;
  showDownload?: boolean;
  showEditSecLevel?: boolean;
  showSignButtons?: boolean;
  showUploadTime?: boolean;
  showUploader?: boolean;
  isDeleteConfirm?: boolean;
  uploadedOptionsWhites?: string[];
  uploadedOptionsBlacks?: string[];
  /** 待上传操作配置 */
  uploadingOptions?: UploadOptionType[];
  /** 待上传文件操作按钮配置白名单 */
  uploadingOptionsWhites?: string[];
  /** 待上传文件操作按钮配置黑名单 */
  uploadingOptionsBlacks?: string[];
  showBatchSign?: boolean;
  batchDownload?: boolean;
  batchDelete?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  listTLen?: number;
  listBLen?: number;
  tableTitle?: string;
  searchValue?: string;
  searchPlaceholder?: string;
  updater: JSX.Element;
  commonParams?: AnyObj;
  keywords?: string;
  /** 添加文件次数 */
  addFileNum?: number;
  loginFree?: boolean;
  tableProps?: Partial<ITableProps>,
  /** 自定义密级接口 key 值（必传） */
  secretLevelKey?: string;
  /** 自定义密级接口 url */
  secretLevelUrl?: string;
  /** 自定义密级接口 headers */
  secretLevelHeaders?: AnyObj;
  /** 自定义密级接口 params */
  secretLevelParams?: AnyObj;
  /** 自定义密级接口 method */
  secretLevelMethod?: Method;
  isLoadSetting?: boolean;
  onSearch: (keywords: string) => void;
  onOptionClick: (item: string | AnyObj, curitem: UploadData) => Promise<void>;
  onBatchSign: (ids?: string[]) => void;
  onBatchDelete: (dListT: UploadData[], dListB: ListData[], newListT: UploadData[], newListB: ListData[]) => Promise<void>;
  onDrag: (data: any[]) => void;
  onBadgeClick?: (data: UploadData) => void;
  onRename: (value: string) => void;
  // 待上传事件
  onCancelUpload: (file: IFile) => void;
  onBreakpointUpload: (file: IFile) => void;
  onOptionClickByB: (option: string | SelectOptionData, data: ListData) => void;
  /** 自定义 columns */
  getColumns?: (columns: ITableColumn[]) => ITableColumn[];
  /** 自定义选择框配置 */
  getSelectionProps?: (data: any, rowIndex: number | string) => { visible: boolean, disabled: boolean };
  onBeforeTableBatchDelete?: (params: { selectedKeys: string[], deleteData: UploadData[] }) => Promise<any> | boolean | void;
  onBeforeTableBatchDownload?: (params: { selectedKeys: string[], downloadIds: string[] }) => Promise<any> | boolean | void;
}

export interface UploadProps extends BaseProps, DataProps<UploadData> {
  ref?: any;
  documentId?: string;
  type?: 'simple' | 'default' | 'inside' | 'table',
  listOptionType?: UploadListOptionType,
  optionHoverPosition?: UploadOptionHoverPosition,
  wrappedComponentRef?: any;
  name?: string;
  data?: UploadData[];// 附件数据
  headers?: UploadRequestHeader;
  method?: UploadRequestMethod;
  uploadTitle?: string;//上传图标（按钮）显示名称
  uploadUrl?: string;//文件上传服务器接口地址
  reUploadUrl?: string;// 重上传服务器接口地址
  deleteConfirmParams?: AnyObj;// 删除确认弹层参数
  uploadParams?: object | ((file: IFile | string | Blob) => object);// 上传参数
  wrap?: boolean; //是否换行
  maxCount?: number;//限制文件个数
  maxSize?: number;//最大上传大小限制，单位为 MB
  minSize?: number;//最小上传大小限制，单位为 MB
  totalMaxSize?: number;//总上传大小限制，单位为 MB
  limitType?: string;//限制上传的文件类型，文件后缀名以逗号隔开
  autoUpload?: boolean;//自动上传或手动批量上传
  sortable?: boolean;//是否可拖拽排序
  /** 附件搜索框 placeholder */
  searchPlaceholder?: string;
  listType?: 'list' | 'img';//已上传服务器文档展现形式
  imgListType?: 'horizontal' | 'vertical', //图片列表横向排列或纵向排列  移动端不支持该参数
  imgOptionType?: 'bottom' | 'right',
  showUpload?: boolean;//是否启用显示上传附件按钮
  hideSucessMessage?: boolean; // 是否影藏成功的提示信息
  imgWidth?: number;//listType = 'img' 情况下列表图片宽度
  imgHeight?: number;//listType = 'img' 情况下列表图片高度
  imgStyle?: CSSProperties;// 图片样式
  errorMsg?: string;//自定义错误提示信息，将直接展示错误信息，屏蔽上传功能
  prohibitType?: string;//禁止上传类型
  directory?: boolean; //是否支持选择目录上传
  withCredentials?: boolean;
  buttonType?: 'default' | 'image' | 'drag' | 'basic';// 上传按钮类型（basic 是为了表单开的，他们需要使用标准上传按钮和简易模式列表）
  showListUploaded?: boolean;//显示已上传文件列表
  showListUploading?: boolean;//显示上传中文件列表
  disabled?: boolean;//禁用上传
  readOnly?: boolean;//是否只读
  multiple?: boolean;//单选多选
  capture?: string;// 文件上传控件中媒体拍摄的方式
  accept?: string;// 规定控件能选择的文件类型
  operateOptions?: Array<Array<SelectOptionData>>;
  multipartSize?: number;// 当文件大小超过时,开启分片上传
  chunkSize?: number;// 分片大小
  timeout?: number;  // 超时设置,

  /** 分片上传失败（网络原因）自动重试次数（默认 2） */
  retryCount?: number;
  /** 分片上传失败（网络原因）自动重试延迟（默认300） */
  retryDelay?: number;

  batchDelete?: boolean;
  batchDownload?: boolean;
  batchMenuProps?: Partial<MenuProps>;

  displayTip?: boolean;
  operateButtonStyle?: CSSProperties;
  isCardDetailForm?: boolean;
  // Event
  customOptionRender?: (option: SelectOptionData) => ReactNode;
  onChange?: (listT?: UploadData[], listB?: UploadData[], reUpload?: boolean) => void;//组件状态变化，抛出最新列表
  onUploading?: (status: string, listT: UploadData[], listB: ListData[]) => void;//文件上传状态  uploading：上传中; uploaded: 上传完成; skipAll: 全部跳过（文件重名校验时使用）
  // getShowListDatas?: () => void;//已上传服务器服务器列表、待上传列表，以及上传状态
  onDelete?: (item: UploadData, listT: UploadData[], listB: ListData[]) => void;//删除的回调
  onAdd?: (files: IFile[], listB: IFile[]) => void;// 添加文件
  onFilesAdded?: (listB: ListData[]) => void;
  onPreview?: () => void;// 预览回调
  onEdit?: () => void;// 编辑回调
  /** 排序回调 */
  onSortEnd?: (listT: UploadData[]) => void;


  onBatchDelete?: (listT: UploadData[], listB: ListData[]) => void;
  onBeforeTableBatchDelete?: (params: { selectedKeys: string[], deleteData: UploadData[] }) => Promise<any> | boolean | void;
  onBeforeTableBatchDownload?: (params: { selectedKeys: string[], downloadIds: string[] }) => Promise<any> | boolean | void;
  /************************************* 自定义渲染  *************************************/
  renderTitleUpload?: (itme: UploadData) => ReactNode | string;    // 自定义渲染标题
  renderContentUploadedL?: (item: UploadData, eventProps?: any, otherParams?: AnyObj) => ReactNode;
  renderContentUploadedR?: (item: UploadData, eventProps?: any, otherParams?: AnyObj) => ReactNode;
  renderListUploaded?: (data: UploadData[], el: any, listCom: any, eventProps?: AnyObj) => ReactNode | void;  // 自定义渲染已上传列表
  renderListUploading?: (data: UploadData[], el: any, list: any, eventProps?: AnyObj) => ReactNode | void;  // renderListUploading
  renderUploadingContentL?: (item: UploadData, eventProps?: any, otherParams?: AnyObj) => ReactNode;  // 自定义渲染待上传列表content
  renderUploadingContentR?: (item: UploadData, eventProps?: any, otherParams?: AnyObj) => ReactNode;  // 自定义渲染待上传列表content
  renderUploadingTitle?: (itme: UploadData) => ReactNode | string;    // 自定义渲染待上传列表title
  customRenderFileIcon?: (extName?: string, item?: UploadData | IFile) => ReactNode;  // 文件图标自定义
  renderUploadTips?: (limitType?: string, maxSize?: number, maxCount?: number) => String; // 渲染提示语
  beforeUpload?: (
    file: IFile,
    FileList: IFile[],
    isEm?: boolean
  ) => BeforeUploadFileType | Promise<void | BeforeUploadFileType>;
  onStart?: (file: IFile) => void;
  customRequest?: (option: UploadRequestOption) => void;
  customErrorInfo?: (file: IFile, type: number) => string;
  customSuccessInfo?: (file: IFile) => string;
  customRenameInfo?: (data: ListData, type: string, response?: any) => string;
  customDeleteInfo?: (data: ListData, type: string, response?: any) => string;
  renderUploadButton?: (eventProps: any, inputDom: ReactNode) => ReactNode;
  onOptionClick?: (value: SingleType<ValueType>, fileData: UploadData, fileIndex: number) => void;
  onBeforeOptionClick?: (value: SingleType<ValueType>, fileData: UploadData, fileIndex: number) => Promise<any> | boolean | void;
  onBadgeClick?: (data: UploadData) => void;
  onError?: (fileInfo: IFile, errorInfo: string, response?: any) => void;  // 上传失败回调
  onSuccess?: (fileInfo: IFile, successInfo: string, response?: any, data?: UploadData) => void;  // 上传成功回调
  onUploadingOptionClick?: (value: SingleType<ValueType>, fileData: UploadData, fileIndex: number) => void;
  onBeforeUploadingOptionClick?: (value: SingleType<ValueType>, fileData: UploadData, fileIndex: number) => Promise<any> | boolean | void;
  /** 上传过程回调 */
  onProgress?: (listB: ListData[], response: UploadProgressEvent | { percent: number }, origin: IFile) => void;

  showBatchSign?: boolean;  // 是否显示批量签署按钮
  showSignButtons?: boolean;  // 是否展示签署按钮
  showDelete?: boolean;  // 是否展示删除按钮
  showEdit?: boolean;  // 是否显示编辑按钮
  showRename?: boolean;  // 是否显示重命名按钮
  showReUpload?: boolean;  // 是否展示重新上传按钮
  showPreview?: boolean;  // 是否展示预览
  showDownload?: boolean;  // 是否展示下载按钮
  showVersion?: boolean;  // 是否展示历史版本按钮
  showAppeal?: boolean;  // 是否展示申述按钮
  showEditSecLevel?: boolean;  // 是否展示密级修改按钮

  fileDataName?: string;
  getShowListData?: (listUploaded: UploadData[], listUploading: ListData[]) => void;
  uploadingData?: ListData[];
  disabledType?: 'disabled' | 'hidden';
  /** 图片上传的上传按钮需要已上传的在一行显示 */
  isUpdaterLineOfRow?: boolean;
  /** 上传是否走组件内部接口 */
  isDeleteInterface?: boolean;
  deleteUrl?: string;
  /** 删除传参 */
  deleteParams?: AnyObj;
  /** 编辑传参 */
  editParams?: AnyObj;
  isDeleteConfirm?: boolean;  // 删除前是否确认
  isCancelConfirm?: boolean;  // 取消前是否确认
  onDeleteCancel?: () => void; // 删除取消回调
  onDeleteSure?: (item: UploadData, listT: UploadData[], listB: ListData[]) => void;  // 删除确认回调
  onBatchDeleteSure?: (dListT: UploadData[], dListB: ListData[], listT: UploadData[], listb: ListData[]) => void;  // 批量删除前确认回调
  // onBatchDeleteSure?: ()

  isListTShowImage?: boolean; // 作为列表展示时，图片是否单独一行展示
  routeId?: string;  // routeId（用作 corsLink）
  detailId?: string;  // routeId（用作 corsLink）
  batchUploadCompleted?: (successFiles: IFile[], failedFiles: IFile[], total?: number) => void;  // 批量上传完成回调
  onUploadComplete?: (successFiles: UploadData[], failedFiles: UploadData[], total?: number, otherParams?: AnyObj) => void;

  isComment?: boolean;  // 是否评论（内部 api）
  loginFree?: boolean;  // 免登录标识

  singleUpload?: boolean;  // 单文件上传
  singleUploadButtnType?: string;  // 单文件上传后，按钮展示类型（hidden：隐藏，reUpload：重新上传）

  /************************************* 密级  *************************************/
  secretProtect?: boolean;  // 是否启用附件密级（默认： false）
  secretConfim?: boolean;  // 是否开启密级确认框（默认： true）
  secretReUploadConfim?: boolean;  //  是否开启重上传密级确认框（false）

  /** 密级等级（默认： undefined） */
  secretLevel?: string;
  /** 保密期限 */
  secretLevelValidity?: string;
  secretLevelInfo?: string;  // 缺少密级提示（默认： "请填写密级！")
  secretLevelValidityInfo?: string  // 缺少密级期限提示默认： "请填写密级期限！")
  secretLevelHelpTip?: string;  // 密级填写辅助信息（默认："保密期限填写格式：3个月、10年、20年、长期…不能是纯数字"）
  secretLevelDialogProps?: Partial<DialogProps>;  // 附件密级确认框配置

  secretLevelAutoAdjust?: boolean;  // 开启密级监听，密级自动调整

  secLevelDisplayFormat?: number;  // 密级显示格式：1: 密级 + 期限；2: 仅密级

  /** 自定义密级接口 key 值（必传） */
  secretLevelKey?: string;
  /** 自定义密级接口 url */
  secretLevelUrl?: string;
  /** 自定义密级接口 headers */
  secretLevelHeaders?: AnyObj;
  /** 自定义密级接口 params */
  secretLevelParams?: AnyObj;
  /** 自定义密级接口 method */
  secretLevelMethod?: Method;

  /** 自定义本地标密接口 key 值（必传） */
  localSecurityKey?: string;
  /** 自定义本地标密接口 url */
  localSecurityUrl?: string;
  /** 自定义本地标密接口 headers */
  localSecurityHeaders?: AnyObj;
  /** 自定义本地标密接口 params */
  localSecurityParams?: AnyObj;
  /** 自定义本地标密接口 method */
  localSecurityMethod?: Method;

  /************************************* 待上传配置  *************************************/
  uploadingOptions?: UploadOptionType[]

  /************************************* 参数配置  *************************************/
  commonParams?: AnyObj;  // 接口通用参数（所有接口都会带上的参数）
  /************************************* 名称重复校验  *************************************/
  directoryId?: string;
  checkName?: boolean;

  getInstance?: (uploadInstance: any) => void // 获取 upload 实例
  fetch?: (options: RequestOptionConfig) => any;

  checkSensitiveWords?: boolean;  // 是否开启敏感词检测

  /** 拖拽上传优化 */
  dragUpload?: boolean;  // 是否支持拖拽上传
  dragInfo?: ReactNode;  // 拖拽区域文字信息
  dragGlobal?: boolean;  // 全局监听拖拽上传（文件进入页面会出现拖拽区域弹框的现象）
  dragHighlight?: boolean;  // 文件拖拽进入时是否高亮显示拖拽区域

  /** 是否支持粘贴上传 */
  pasteUpload?: boolean;
  /** 粘贴上传说明信息 */
  pastePlaceholder?: string;

  /** 二维码地址 */
  qrcodeUrl?: string;

  // 附件信息
  showUploader?: boolean;  // 是否显示上传人
  showUploadTime?: boolean;  // 是否显示上传时间
  showFileSize?: boolean; // 显示文件大小
  /**** 自定义预览地址 ****/
  previewURL?: string;
  /* 自定义批量删除地址 */
  batchDeleteURL?: string;

  /** 已上传文件操作按钮配置白名单 */
  uploadedOptionsWhites?: string[];
  /** 已上传文件操作按钮配置黑名单 */
  uploadedOptionsBlacks?: string[];
  /** 待上传文件操作按钮配置白名单 */
  uploadingOptionsWhites?: string[];
  /** 待上传文件操作按钮配置黑名单 */
  uploadingOptionsBlacks?: string[];

  isLoadSetting?: boolean;

  // 选择文件前回调
  beforeChooseFile?: () => boolean | Promise<boolean>;
  // 添加文件前回调
  beforeAddFile?: (files: IFile[], props?: any) => boolean | IFile[] | Promise<boolean | IFile[]>;
  // afterChooseFile?: (files: any, props?: any) => boolean | AnyObj;
  // 上传文件前回调（实验性 api，暂不对外提供）
  beforeUploadFile?: (file: any, uploadParams?: AnyObj) => boolean | AnyObj | Promise<boolean | AnyObj>;
  // 是否自动移除上传失败的待上传文件（实验性 api，暂不对外提供）
  isClearErrorFile?: boolean;
  /** 设置附件类型组 */
  sameFormatTypes?: string[][];
  /** 用于判断 accept 调整的 ua */
  acceptUa?: {
    browser?: string,
    version?: string,
    os?: string,
  }

  /** 所有操作都结束后触发的事件 */
  onUploadFinally?: () => void;
  /* 接口headers参数 */
  requestHeaderParams?: AnyObj;
  onUploadCancle?: (item: UploadData, listT: UploadData[], listB: ListData[]) => void;
  /** 超大文件 md5 计算时分片大小 */
  md5ChunkSize?: number;
  /** 异步上传 */
  async?: boolean;
  /** 图片上传压缩 */
  isCompressImg?: boolean;

  /** 上传按钮点击事件 */
  onUploaderClick?: () => void;

  /** 缩略图展示类型，默认为 small */
  thumbnailFormat?: 'small' | 'big' | 'image';

  // 是否在表单中
  inForm?: boolean;
  // 是否走表单特殊的行高逻辑
  readOnlyLineHeightStandard?: boolean;


  /********************************* type='table' *********************************/
  /** type 为 table 时设置左侧 title */
  tableTitle?: string;
  /** type 为 table 时复写 table 的参数 */
  tableProps?: Partial<ITableProps>;
  /** type 为 table 时复写 columns */
  getTableColumns?: (columns: ITableColumn[]) => ITableColumn[];
  /********************************* type='inside' *********************************/
  /** 列表选中状态 */
  selectedFileId?: string;
}

export type FilePreviewOptions = {
  fileId: string;
  fileIds?: string[] | undefined;
  data?: UploadData;
  datas?: UploadData[];
  loginFree?: boolean;
  isImage?: boolean | undefined;
  module?: string;
  onError?: (() => void) | undefined;
  onSuccess?: (() => void) | undefined;
  params?: AnyObj;
  commonParams?: AnyObj;
  canDownload?: boolean;
  previewURL?: string;
  requestHeaderParams?: AnyObj;
}

export type UploadType = ComponentType<UploadProps> & {
  FileList: ComponentType<UploadProps>,
  uploadTools: typeof uploadTools,
  SensitiveWordsDialog: ComponentType<SensitiveWordsDialogProps>;
  filePreview: (options: FilePreviewOptions) => void;
  download: (option: { fileId: string, loginFree?: boolean; module?: string; params?: AnyObj; }) => void;
  /** 自定义本地标密接口时，key 必传 */
  getAttachmentLocalSecurityInfo: (options?: {
    key: string,
    url?: string,
    params?: AnyObj,
    headers?: AnyObj,
    method?: Method,
  }) => Promise<{ isAllow?: boolean; verifyFormat?: string; }>;
  /** 自定义本地标密接口时 localSecurityKey 必传, 自定义密级接口时 secretLevelKey 必传  */
  doLocalSecurityValidate: (files: any[], secretLevel: string, options?: {
    requestHeaderParams?: AnyObj;
    localSecurityKey?: string;
    localSecurityUrl?: string;
    localSecurityParams?: AnyObj;
    localSecurityMethod?: Method;
    localSecurityHeaders?: AnyObj;
    /** 自定义密级接口 key 值（必传） */
    secretLevelKey?: string;
    /** 自定义密级接口 url */
    secretLevelUrl?: string;
    /** 自定义密级接口 headers */
    secretLevelHeaders?: AnyObj;
    /** 自定义密级接口 params */
    secretLevelParams?: AnyObj;
    /** 自定义密级接口 method */
    secretLevelMethod?: Method;
  }) => Promise<any[]>;
  uploadImage: (props: UploadRequestOption) => Promise<{ imgUrl: string; data: UploadData; }>;
  uploadFile: (props: UploadRequestOption) => Promise<UploadData>;
  checkFileTypeBySystem: (ext: string) => Promise<boolean>;
}

export type MUploadType = ComponentType<MUploadProps> & {
  MUploadDetail: ComponentType<MUploadProps>,
  MSensitiveWordsDialog: ComponentType<MSensitiveWordsDialogProps>;
  MFileList: ComponentType<MUploadProps>,
  /** 自定义本地标密接口时（key 必传） */
  getAttachmentLocalSecurityInfo: (options?: {
    key?: string,
    url?: string,
    method?: Method,
    params?: AnyObj,
    headers?: AnyObj,
  }) => Promise<{
    isAllow?: boolean;
    verifyFormat?: string;
  }>,
}

export type MUploadProps =
  Omit<UploadProps, 'onSuccess' | 'onError' | 'renderContentUploadedL' | 'renderContentUploadedR'>
  & {
    isShowAll?: boolean;
    uploadDetail?: boolean;
    isShowLabel?: boolean;  // 是否展示 label
    showFullName?: boolean;  // 是否展示全部名称
    onBatchSign?: any;
    onBatchDelete?: any;
    onBatchDeleteSure?: any;
    /**
     * 设置 FormItem 的标签
     * @title 标签
     */
    label?: string;
    /**
     * 设置 FormItem 的标签占位比例
     * @title 标签占位比例
     */
    labelSpan?: number;

    // e10 sdk 相关
    // 功能选择
    sourceType?: sourceType[];
    // 相册选择（可控制只能选照片、只能选视频、以及照片和视频都可以选择）
    ablumType?: "all" | "photo" | "video";
    // chooseImage 相关
    // 图片是否生成水印（EM sdk 生效）
    isAddWaterMark?: boolean;
    // 拍摄的照片/视频是否保存在相册中，1表示保存（默认），0表示不保存
    isSaveAlbum?: boolean;
    // 拍摄、选择的照片是否允许编辑，1表示允许（默认），0表示不允许
    isEditAlbumPhoto?: boolean;
    // 图片压缩类型，1表示用户可以选择是否原图，2表示固定压缩，3表示固定原图
    compressType?: 1 | 2 | 3;
    //FileList 模式下的列表选项
    fileListType?: 'useDetail' | 'noUseDetail';
    //是否是FileList
    isFileList?: boolean;
    onError?: (fileInfo: IFile | AnyObj, errorInfo: string, response?: any) => void;  // 上传失败回调
    onSuccess?: (fileInfo: IFile | AnyObj, successInfo: string, response?: any) => void;  // 上传成功回调

    secretLevelDialogProps?: Partial<MDialogProps>;
    /** 自定义渲染 title */
    renderContentUploaded?: (item: UploadData, eventProps?: any) => ReactNode;
    renderTitleUploaded?: (item: UploadData) => ReactNode | string;
    renderUploadingContent?: (item: UploadData, eventProps?: any) => ReactNode;
    /** 渲染已上传按钮（外部容器的已上传按钮） */
    renderUploadedButton?: (eventProps: any, inputDom: React.ReactNode) => React.ReactNode;

    /** cardDetailForm 中的列表显示需要特殊处理 */
    isCardDetailForm?: boolean;

    /** 自定义空页面提示 - MUploadDetail 中会用 */
    emptyContent?: ReactNode;
    /** 隐藏展开全部按钮，需要配合 isShowAll 使用（暂只有评论组件使用，不开放给外界) */
    hideExpandBtn?: boolean;
    /** 限制单次文件选择数量 */
    selectNum?: number;
    /** 不使用 em 上传 */
    noUseEmUpload?: boolean;

    // 是否在表单中
    inForm?: boolean;
    // 是否走表单特殊的行高逻辑
    readOnlyLineHeightStandard?: boolean;
  };

export type sourceType = "camera" | "video" | "album" | "file"

export interface ParsedFileInfo {
  origin: IFile;
  uploadUrl: string;
  uploadParams: AnyObj;
  parsedFile: Exclude<BeforeUploadFileType, boolean>;
  requests?: any;
  file: File;
  willRemove?: boolean;
}

export type UploaderProps =
  Omit<MUploadProps, 'onError' | 'onProgress' | 'onSuccess' | 'batchUploadCompleted' | 'secretLevelDialogProps' | 'afterChooseFile' | 'beforeChooseFile'>
  & {
    listType?: 'list' | 'img';
    fileParams?: AnyObj;
    otherParams?: AnyObj;
    listB: ListData[];
    sourceType?: sourceType[];

    highlightDragArea?: boolean;

    isOpenClassification?: boolean;
    /** 是否本地标密上传 */
    isLocalSecurityAllow?: boolean;
    resourceOptionInfo?: SecretSelectOptionType[];  // 密级弹框-密级选项
    /** 是否成功获取文件上传系统设置 */
    isLoadSetting?: boolean;
    onSuccess?: (data: any, response: AxiosResponse, origin: IFile, oldFile?: UploadData, isUploadOnlyOne?: boolean) => void,
    renderUploadButton?: (eventProps: any, inputDom?: ReactNode, otherParams?: AnyObj) => ReactNode,
    onAdd: (files: ListData[]) => Promise<{ files?: any[], canUpload: boolean }>,
    onProgress?: (event: UploadProgressEvent | { percent: number }, origin: IFile) => void;
    /** 文件解析进度提示 */
    onFileParsingProgress?: (params: {
      origin: IFile,
      type: string,
      percent: number
    }) => void;
    onError?: (isUploadOnlyOne: boolean, file: IFile, type?: number, res?: any,) => void;
    onUploading?: (status: string, data?: UploadData) => void;

    batchUploadCompleted?: (count: number) => void;  // 批量上传完成

    updateListB: (origin: IFile | any, data?: UploadData, type?: 'success' | 'fail') => Promise<void>;

    // 选择文件前回调
    beforeChooseFile?: (props?: any) => Boolean | Promise<any> | void;
    // 选择文件后回调
    afterChooseFile?: (files: any, props?: any) => Boolean | Promise<any> | void;
    // 触发上传前回调（实验性 api，暂不对外提供）
    beforeStartUpload?: (props?: any) => Boolean | Promise<any> | void;
    // 上传文件前回调（实验性 api，暂不对外提供）
    // beforeUploadFile?: (file: any, uploadParams: AnyObj) => Boolean | Promise<any> | void;


    getInstance?: (uploadInstance: any) => void // 获取 upload 实例
  };

/**
 request.ts  包装请求发起,完成整个过程,提供请求响应完的相关回调
 Uploader.tsx  选文件,处理请求后的数据的相关逻辑
 Upload.tsx  UI展示:附件列表,上传按钮,进度条之类的
 */

export interface IFile extends File, AnyObj {
  uid: string;
  secretLevel?: string;
  secretLevelValidity?: string | null;
  validity?: string;
  cancelUpload?: () => void;
  willRemove?: boolean;
  replaceFlag?: boolean;
  flag?: 'skip' | 'cover' | 'rename';
  // 当前文件是否是替换附件
  reUpload?: boolean;
  // 被替换附件的 id
  replacedFileId?: string;
}

export interface VerifyFileNameData {
  file: IFile,
  uid: string,
  flag: 'skip' | 'cover' | 'rename',
  rename: string,
  fileNameError: boolean
}

interface requestOption {
  fileId?: string;
  url?: string;
  params?: AnyObj;
  method?: UploadRequestMethod;
  isMobile?: boolean;  // 是否移动端
  loginFree?: boolean; // 是否免登录
  module?: string;  // 模块标识
  headers?: AnyObj;
}

// 更新签署状态 option
export interface UpdateFileStatusRequestOption extends requestOption {
  docId: string,
  refId: string,
}

export interface SecRequestOption extends requestOption {
}

export interface EditSecRequestOption extends requestOption {
  module?: string;
}

// 申述接口 option
export interface AppealRequestOption extends requestOption {
}

// 重命名接口 option
export interface RenameRequestOption extends requestOption {
  module?: string;
}

// 编辑接口 option
export interface EditRequestOption extends requestOption {
}

// 预览接口 option
export interface PreviewRequestOption extends requestOption {
}

// 下载接口 option
export interface DeleteRequestOption<T = any> extends requestOption {
  onSuccess?: (body: T) => void;
  onError?: (body?: T) => void;
  headers?: UploadRequestHeader;
}

// 批量下载接口
export interface BatchDownloadRequestOption extends requestOption {
}
// 批量下载进度
export interface BatchDownloadPercentRequestOption extends requestOption { }
// 批量上传
export interface BatchDeleteRequestOption extends requestOption { }

export type SliceProgressFunc = (event: UploadProgressEvent | { percent: number }, origin: IFile) => void;

export type PreCheckDataType = {
  /** uploadType => 秒传：second；普通上传：common；分片上传：chunk */
  uploadType: 'common' | 'chunk' | 'second',
  preUploadId: string,
  /** 分片上传信息 */
  chunkDto?: {
    chunkSize: number,
    chunkCount: number,
    chunkUploadId: string,
    nowChunk: number,
  },
  /** 秒传信息 */
  uploadModuleDto?: UploadData
}

// 切片上传 option type
export interface SliceUploadRequestOption<T = any> extends AxiosRequestConfig {
  onProgress?: SliceProgressFunc;
  onError?: (event: UploadRequestError | ProgressEvent, type?: number, res?: any) => void;
  onSuccess?: (body: T, response: AxiosResponse, origin: IFile) => void;
  // onUploading?:(status:string)=>void;
  uploadParams?: AnyObj;
  filename?: string;
  name?: string;
  file: Exclude<BeforeUploadFileType, File | boolean> | IFile;
  withCredentials?: boolean;
  uploadUrl?: string;
  headers?: UploadRequestHeader;
  method?: UploadRequestMethod;
  fileDataName?: string;
  loginFree?: boolean;
}

// 普通上传
export interface UploadRequestOption<T = any> extends AxiosRequestConfig {
  /** 文件解析进度（md5 解析） */
  onFileParsingProgress?: (params: {
    origin: IFile,
    type: string,
    percent: number
  }) => void;
  /** 上传进度 */
  onProgress?: (event: UploadProgressEvent) => void;
  /** 上传失败回调 */
  onError?: (event: UploadRequestError | ProgressEvent, body?: T, code?: number) => void;
  /** 上传成功回调 */
  onSuccess?: (body: T, xhr: AxiosResponse, file?: IFile) => void;
  // onUploading?:(status:string)=>void;
  uploadParams?: AnyObj;
  filename?: string;
  name?: string;
  file: IFile;
  withCredentials?: boolean;
  uploadUrl?: string;
  headers?: UploadRequestHeader;
  method?: UploadRequestMethod;
  fileDataName?: string;
  loginFree?: boolean;
  /** 超大文件 md5 计算时分片大小 */
  md5ChunkSize?: number;
}

export interface UploadFileRequestOption<T = any> extends Omit<UploadRequestOption, 'onError' | 'onSuccess'> {
  onProgress?: (event: UploadProgressEvent) => void;
  uploadParams?: AnyObj;
  filename?: string;
  name?: string;
  file: IFile;
  withCredentials?: boolean;
  uploadUrl?: string;
  headers?: UploadRequestHeader;
  method?: UploadRequestMethod;
  fileDataName?: string;
  loginFree?: boolean;
}

export interface UploadProgressEvent extends ProgressEvent {
  percent?: number;
  chunk?: number;
}

export interface UploadRequestError extends Error {
  status?: number;
  method?: UploadRequestMethod;
  url?: string;
}

export interface UploadState {
  listB: ListData[];
  listT: UploadData[];
  curFile: UploadData;
  visible?: boolean;
  curEditName?: string;
  curIndex?: number;
  curViewFile: UploadData;
  versionVisible?: boolean;
  pluginUseDetailVisible?: boolean;
  routeId?: string;
  randomRoute?: string;
  detailVisible?: boolean;
  deleteVisible?: boolean;
  deleteItem?: ListData;
  deleteIndex?: number;
  selectIds?: string[];
  selectRows?: any[];
  deleteListUploading?: boolean;
  fileListType?: 'useDetail' | 'noUseDetail'
  // uploaderEventProps?: any;
  // uploaderInputDom?: any;
  _isUpload?: boolean;
  isShowAll?: boolean;
  parentPath?: string;

  /**********************  密级  *******************/
  /** 是否已经初始化过密级配置 */
  isInitSecretSecurity?: boolean;
  secVisible?: boolean;  // 密级弹框显隐
  secValidityVisible?: boolean;  // 本地标密上传 - 密级期限设置弹框显隐
  /** 是否开启密级上传 */
  isOpenClassification?: boolean;
  /** 密级选项 */
  resourceOptionInfo?: SecretSelectOptionType[];
  /** 密级期限填写辅助信息 */
  secretLevelHelpTip: string,

  /** 是否开启本地标密上传 */
  isLocalSecurityAllow?: boolean;
  /** 本地标密格式 */
  localSecurityVerifyFormat?: string;

  secLevel?: string;
  secretLevel?: string;  // 密级等级
  secLevelValidity?: string;
  secretLevelValidity?: string;  // 密级期限

  /** 传递给 FilePicker 用作多余上传信息的内容 */
  fileParams?: AnyObj;
  secretLevelInfo: string;  // 缺少密级提示（默认： "请填写密级！")
  secretLevelValidityInfo: string  // 缺少密级期限提示默认： "请填写密级期限！")
  /************************************* 待上传配置  *************************************/
  uploadingOptions?: UploadOptionType[]
  /************************************* 重复名称校验  *************************************/
  reNameVisible?: boolean;
  folderName?: string;
  reNameFiles?: IFile[];

  /*** 密级确认框 ***/
  cacheFiles?: any[];
  __afterChooseFileSecret?: boolean;
  editSecType: 'none' | 'uploaded' | 'uploading' | 'uploadingDataUpdate' | 'localSecurity';

  /** 密级期限填写框 */
  cacheValidityDatas?: AttachmentSecurityValidateType[];

  /** 敏感词 **/
  banVisible?: boolean;
  cacheBanVisible?: boolean;
  sensitiveWords?: any[];

  /* 优化密级弹框 */
  dragPopupVisible?: boolean;
  highlightDragArea?: boolean;

  /** 附件搜索功能 */
  // 搜索框内容
  searchValue?: string;
  // 搜索关键字
  keywords?: string;
  /** 批量签署文件 id */
  batchSignFileIds?: string[];

  /** 添加文件次数 */
  addFileNum?: number;
  /** 是否成功获取文件上传系统设置 */
  isLoadSetting?: boolean;
}

export interface ListBottomContext {
  weId?: string | null;
  prefixCls?: string;
  item: ListData;
  listB: ListData[];
  sortable?: boolean;
  cancelUpload?: (file: IFile) => void;
  onBreakpointUpload?: (file: IFile) => void;
  isComment?: boolean;
  type?: 'simple' | 'default' | 'inside' | 'table';
  autoUpload?: boolean;
  onOptionClick?: (value: SingleType<ValueType>, fileData: ListData) => void;
  resourceOptionInfo?: SecretSelectOptionType[];
  /** 是否展示文件大小 */
  showFileSize?: boolean;
  /** 待上传文件操作按钮配置白名单 */
  uploadingOptionsWhites?: string[];
  /** 待上传文件操作按钮配置黑名单 */
  uploadingOptionsBlacks?: string[];
  /************************************* 待上传配置  *************************************/
  uploadingOptions?: UploadOptionType[];
  /************************************* 密级  *************************************/
  isOpenClassification?: boolean;
  /** 是否开启本地标密上传 */
  isLocalSecurityAllow?: boolean;
  secretProtect?: boolean;
  secretLevel?: string;
  secLevelDisplayFormat?: number;
    /** 自定义密级接口 key 值（必传） */
    secretLevelKey?: string;
    /** 自定义密级接口 url */
    secretLevelUrl?: string;
    /** 自定义密级接口 headers */
    secretLevelHeaders?: AnyObj;
    /** 自定义密级接口 params */
    secretLevelParams?: AnyObj;
    /** 自定义密级接口 method */
    secretLevelMethod?: Method;
  /************************************* 密级  *************************************/
  readOnly?: boolean;
  disabled?: boolean;
  /************************************* 自定义渲染  **********************************************/
  renderUploadingContentL?: (item: UploadData, eventProps?: any, otherParams?: AnyObj) => ReactNode;  // 自定义渲染待上传列表content
  renderUploadingContentR?: (item: UploadData, eventProps?: any, otherParams?: AnyObj) => ReactNode;  // 自定义渲染待上传列表content
  renderUploadingTitle?: (itme: UploadData) => ReactNode | string;    // 自定义渲染待上传列表title
  customRenderFileIcon?: (extName?: string, item?: UploadData | IFile) => ReactNode;  // 文件图标自定义
}

export interface ListTopContext {
  weId?: string | null;
  type?: 'simple' | 'default' | 'inside' | 'table';
  listOptionType?: UploadListOptionType;
  optionHoverPosition?: UploadOptionHoverPosition,
  prefixCls?: string;
  wrap?: boolean;
  item: UploadData;
  listT: UploadData[];
  readOnly?: boolean;
  disabled?: boolean;
  listType?: string;
  imgListType?: string;
  imgWidth?: number;
  imgHeight?: number;
  imgStyle?: CSSProperties;
  sortable?: boolean;
  operateOptions?: Array<Array<SelectOptionData>>;

  showDelete?: boolean;  // 是否展示删除按钮
  showEdit?: boolean;  // 是否显示编辑按钮
  showRename?: boolean;  // 是否显示重命名按钮
  showReUpload?: boolean;  // 是否展示重新上传按钮
  showPreview?: boolean;  // 是否展示预览
  showDownload?: boolean;  // 是否展示下载按钮
  showVersion?: boolean;  // 是否展示历史版本按钮
  showSignButtons?: boolean;  // 是否展示签署按钮
  showAppeal?: boolean;  // 是否展示申述按钮
  showEditSecLevel?: boolean;  // 是否展示密级修改按钮
  // 附件信息
  showUploader?: boolean;  // 是否显示上传人
  showUploadTime?: boolean;  // 是否显示上传时间
  showFileSize?: boolean; // 显示文件大小

  isListTShowImage?: boolean;
  isUpdaterLineOfRow?: boolean;
  isComment?: boolean;
  imgOptionType?: 'bottom' | 'right';
  loginFree?: boolean;
  uploadParams?: object | ((file: IFile | string | Blob) => object);// 上传参数
  resourceOptionInfo?: SecretSelectOptionType[];
  secretProtect?: boolean;
  secLevelDisplayFormat?: number;  // 密级显示格式：1: 密级 + 期限；2: 仅密级
  buttonType?: 'default' | 'image' | 'drag' | 'basic';// 上传按钮类型（basic 是为了表单开的，他们需要使用标准上传按钮和简易模式列表）
  showUpload?: boolean;
  hasChildren?: boolean;
  operateButtonStyle?: CSSProperties;
  dragUpload?: boolean;

  /** 已上传文件操作按钮配置白名单 */
  uploadedOptionsWhites?: string[];
  /** 已上传文件操作按钮配置黑名单 */
  uploadedOptionsBlacks?: string[];

  /** 缩略图展示类型，默认为 small */
  thumbnailFormat?: 'small' | 'big' | 'image';

  // 是否在表单中
  inForm?: boolean;
  // 是否走表单特殊的行高逻辑
  readOnlyLineHeightStandard?: boolean;
  
  /** 自定义密级接口 key 值（必传） */
  secretLevelKey?: string;
  /** 自定义密级接口 url */
  secretLevelUrl?: string;
  /** 自定义密级接口 headers */
  secretLevelHeaders?: AnyObj;
  /** 自定义密级接口 params */
  secretLevelParams?: AnyObj;
  /** 自定义密级接口 method */
  secretLevelMethod?: Method;

  isLoadSetting?: boolean;

  onPreview?: (curitem: MListData, index: number) => void;
  onEdit?: () => void;
  onDownload?: (item: ListData, index: number) => void;
  onOptionClick?: (value: SingleType<ValueType>, fileData: ListData, fn?: () => void) => void;
  onBadgeClick?: (data: UploadData) => void;
  onDelete?: (item: ListData, index: number) => void;
  onRename?: (value: string) => void;

  customRenameInfo?: (item: ListData, type: string, res?: any) => string;
  /**********************  密级  *******************/
  isOpenClassification?: boolean;
  /** 本地标密上传 */
  isLocalSecurityAllow?: boolean;
  /** 接口参数 */
  commonParams?: AnyObj;  // 接口通用参数（所有接口都会带上的参数）
  /** 复写 title */
  renderContentUploadedL?: (item: UploadData, eventProps?: any, otherParams?: AnyObj) => ReactNode;
  renderContentUploadedR?: (item: UploadData, eventProps?: any, otherParams?: AnyObj) => ReactNode;
  renderTitleUpload?: (itme: UploadData) => ReactNode | string;
  customRenderFileIcon?: (extName?: string, item?: UploadData | IFile) => ReactNode;  // 文件图标自定义

  /* 自定义预览地址 */
  previewURL?: string;
  onBeforeOptionClick?: (value: SingleType<ValueType>, fileData: UploadData, fileIndex: number) => Promise<any> | boolean | void;
  renderUploadButton?: (eventProps: any, inputDom: ReactNode) => ReactNode;

  /********************************* type='inside' *********************************/
  /** 列表选中状态 */
  selectedFileId?: string;
}

export interface MListTopContext extends ListTopContext {
  onClickMore?: (data: MListData, index: number) => void,
  resourceOptionInfo?: SecretSelectOptionType[];
  renderContentUploaded?: (item: UploadData, eventProps?: any) => ReactNode;
  renderTitleUploaded?: (item: UploadData) => ReactNode | string;
}

export interface MUploadDialogProps<VT extends ValueType = ValueType> extends DialogProps, MUploadProps {
  changeVisible?: (visible: boolean) => void;
  history?: AnyObj;
  randomRoute?: string;
  parentPath?: string;
  setParentPath: (parentPath: string) => void;
  isContentEmpty?: boolean
}


export interface RowButtonProps {
  weId?: string | null;
  prefixCls?: string;
  item: MListData,
  listB: MListData[],
  isComment?: boolean,
  autoUpload?: boolean,
  resourceOptionInfo?: SecretSelectOptionType[];
  /**********************  密级  *******************/
  secLevelDisplayFormat?: number;
  isOpenClassification?: boolean;
  /** 是否开启本地标密上传 */
  isLocalSecurityAllow?: boolean;
  secretProtect?: boolean;
  secretLevel?: string;
  /************************************* 待上传配置  *************************************/
  uploadingOptions?: UploadOptionType[]
  /************************************* 状态  *************************************/
  disabled?: boolean;//禁用上传
  readOnly?: boolean;//是否只读

  showFullName?: boolean;  // 是否展示全部名称
  imgWidth?: number;
  imgHeight?: number;
  listType?: 'list' | 'img';
  isBatchPanel?: boolean; //批量操作按钮不需要右侧的操作按钮和取消按钮

  showFileSize?: boolean;
  uploadingOptionsBlacks?: string[];
  uploadingOptionsWhites?: string[];

  /** 自定义密级接口 key 值（必传） */
  secretLevelKey?: string;
  /** 自定义密级接口 url */
  secretLevelUrl?: string;
  /** 自定义密级接口 headers */
  secretLevelHeaders?: AnyObj;
  /** 自定义密级接口 params */
  secretLevelParams?: AnyObj;
  /** 自定义密级接口 method */
  secretLevelMethod?: Method;

  cancelUpload?: (file: MListData) => void;
  editSecLevel?: (curitem: MListData, type: "uploaded" | "uploading") => void;
  onClickMore?: (data: MListData, index: number) => void
  renderUploadingContent?: (item: UploadData, eventProps?: any, otherParams?: AnyObj) => ReactNode;
  customRenderFileIcon?: (extName?: string, item?: UploadData | IFile) => ReactNode;  // 文件图标自定义
  onBreakpointUpload?: (file: IFile) => void;
}

export type AddErrorInfoFunc = (isUploadOnlyOne: boolean, file: IFile, errorInfo: any, type: number, res?: any,) => void;

export interface ProcessErrorType {
  type?: number,
  isUploadOnlyOne?: boolean,
  message?: string,
  file: IFile,
  maxCount?: number,
  maxSize?: number,
  totalMaxSize?: number,
  res?: any,
  customErrorInfo?: (file: IFile, type: number) => string,
  addErrorInfo?: AddErrorInfoFunc;
}


/****************************hover展示操作按钮********************************************************** */


export type UploadToolKeys = "prefixCls" | "type" | "listOptionType";

export type UploadToolType = Pick<UploadProps, UploadToolKeys>
  & {
    weId: string;
    isHover: boolean;
    optButtons: any;
    moreOptButtons: any;
    item: any;
    index: number;
    onOptionClick: (value: SingleType<ValueType>, fileData: UploadData) => void;
    [_x: string]: any;
    optionHoverPosition?: UploadOptionHoverPosition,
  }

export type UploadMoreBtnType = Omit<UploadToolType, "optButtons" | "isHover">
  & {
    isShow?: boolean;
    showLabel?: boolean;
    showIcon?: boolean;
    showArrowIcon?: boolean;
    moreIcon?: string;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
  }

export interface SensitiveWordsDialogProps {
  prefixCls?: string;
  visible?: boolean;
  data?: any[];
  onSure?: (files: VerifyFileNameData[]) => void;
  onClose?: (files: VerifyFileNameData[]) => void;
}

export interface MSensitiveWordsDialogProps {
  prefixCls?: string;
  visible?: boolean;
  data?: any[];
  parentPath?: string;
  randomRoute?: string;
  onSure?: (files: VerifyFileNameData[]) => void;
  onClose?: (files: VerifyFileNameData[]) => void;
  onWillUnmount?: (files: VerifyFileNameData[]) => void;
}

export interface AttachmentSecurityValidateType {
  id: string,
  file: any,
  name: string,
  type: number,
  errorMsg: string,
  secretLevel: string,
  secretLevelValidity: string,
}


export interface BatchSecretValidityDialogProps {
  prefixCls?: string
  visible?: boolean;
  secretLevelValidityInfo?: string;
  onClose?: () => void;
  onSure?: (datas?: any[]) => void;
  datas?: AttachmentSecurityValidateType[];
  /* 接口headers参数 */
  requestHeaderParams?: AnyObj;
  /** 自定义密级接口 key 值（必传） */
  secretLevelKey?: string;
  /** 自定义密级接口 url */
  secretLevelUrl?: string;
  /** 自定义密级接口 headers */
  secretLevelHeaders?: AnyObj;
  /** 自定义密级接口 params */
  secretLevelParams?: AnyObj;
  /** 自定义密级接口 method */
  secretLevelMethod?: Method;
}

/********** 密级弹框 **********/
export type SecretSelectDialogProps = SecretSelectProps & {
  visible: boolean;
  onClose?: () => void,
  onSure?: (value: SecretSelectValueType, option?: SecretSelectOptionType) => void,
  dialogProps?: DialogProps;
  editSecType?: string;
  isLocalSecurityAllow?: boolean;
  localSecurityVerifyFormat?: string;
};

export type MSecretSelectDialogProps = MSecretSelectProps & {
  visible: boolean;
  onClose?: () => void,
  onSure?: (value: SecretSelectValueType, option?: SecretSelectOptionType) => void,
  dialogProps?: MDialogProps;
  editSecType?: string;
  isLocalSecurityAllow?: boolean;
  localSecurityVerifyFormat?: string;
};

export type SecretSelectDialogState = {
  // value: SecretSelectValueType,
};


