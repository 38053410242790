import { ReactText } from "react";
import { action, computed, observable, isObservableArray } from "mobx";
import { FormDatas, FormInitAllDatas, FormLayoutProps, FormLayoutType, FormStore, FormStoreType, FormValue } from '../../form/index';
import { OptionsType, SelectValueType } from '../../select/index';
import { FieldProps, QuickSearchDatas, SingleFormDatas, SearchDatas, ResetType } from "../types";
import HistoryStore from './HistoryStore';
import CommonFilterStore from './CommonFilterStore';
import { getLabel, RequestOptionConfig, RequestPromise } from '@weapp/utils';
import { isEmpty as judgeEmpty, isEqual, needSync, getRandom, isCnWord, deepClone } from '../../../utils/index';
import { FormSingleItemProps } from '../../form-item/index';
import TransDatasStore, { TransDatasStoreType } from "./TransDatasStore";
import { AnyObj } from "../../../types/common";
import RewriteApiStore, { RewriteApiStoreType } from "./RewriteApiStore";
import { ScopeValue } from "../../scope";
import DatePicker, { DatePickerDateType } from "../../date-picker";
import { isMultiDatePickerType } from "../../date-picker/utils";
import { getInitField as _getInitField, isCustomLayoutEmpty } from "../util";
import { getCustomBrowserConfigStore, isCustomBrowser } from "./custom-browser-config";
import getUtils from "./common";
import { getQuickSearchStore } from "./QuickSearchStore";
import { RuleTable } from "./types";
import { needBrowserIncludingSub, registerBrowserIncludingSub } from "./browser-including-subordinate";
import { loadBrowserConfigUtils, getBrowserConfigUtils, isSetting } from "../util/browser-config/index.async";

const { getRangeInfoByPrimaryKey } = DatePicker;

export const initConditionValue = 'and';
const initConditionData: () => OptionsType = () => [
  { id: 'and', content: getLabel('1224', '所有') },
  { id: 'or', content: getLabel('1225', '任一') },
]

const initQuickSearch = (store: SearchAdvancedStoreType, conditionId: string, allDatas: SearchAdvancedInitAllDatas) => {
  return getQuickSearchStore()?.then((defaultModule) => {
    store.quickSearchStore = new defaultModule.default(store);
    return store.quickSearchStore.init(conditionId, allDatas);
  })
}
export interface RuleItemsTable {
  [key: string]: string,
}

export interface SearchAdvancedFormLayoutType extends FormLayoutType {
  /* 浏览框 高级搜索自定义设置功能(接口特殊数据处理、暂不对外开放) */
  disable?: boolean; // 条件禁用（同时禁用常用条件、其他分组、常用条件设置）；
  readonly?: boolean; // 整行所有字段只读
}
export interface SearchAdvancedInitAllDatas extends FormInitAllDatas {
  module: string,
  conditionId: string,
  requestHeaderParams?: AnyObj;
  layout: Array<SearchAdvancedFormLayoutType>,
  fetchData?: (options: RequestOptionConfig) => RequestPromise;
  /* 存在必填字段 */
  isSearchRequired?: boolean;
  /* 记录浏览框的type(用作浏览框内高级搜索特殊处理) */
  type?: string;
  /* 自定义快捷搜索与高级搜索面板字段的映射关系(自定义浏览框内使用，对应layoutIds) */
  quickSearchKeys?: string[];
  browserIncludingSubDatas?: AnyObj; // 含下级数据
}

interface CommonLayoutConfig {
  [key: string]: FormLayoutProps,
}

export interface SearchAdvancedStoreProps {
  /* ---------------------------- commonFilterStore ------------------------------ */
  setPublicSnapSavingAuth?: (currentModule?: string) => boolean; // 自定义存为公用常用筛选权限
  setSnapSavingCondition?: ((store: SearchAdvancedStoreType) => boolean) | undefined; // 自定义条件为空判断

  /* ---------------------------- store ------------------------------ */
  customFilterFormDatas?: ((datas: FormDatas, otherParams: any) => FormDatas) | undefined;
}
export default class SearchAdvancedStore {

  customFilterFormDatas: ((datas: FormDatas, otherParams: any) => FormDatas) | undefined;

  constructor(props?: SearchAdvancedStoreProps) {
    if (props?.setSnapSavingCondition) this.commonFilterStore.setSnapSavingCondition = props?.setSnapSavingCondition;
    if (props?.setPublicSnapSavingAuth) this.commonFilterStore.setPublicSnapSavingAuth = props?.setPublicSnapSavingAuth;
    if (props?.customFilterFormDatas) this.customFilterFormDatas = props?.customFilterFormDatas;
  }
  @observable conditionId = '';
  @observable module = 'common';
  @observable requestHeaderParams = {};
  allDatas: SearchAdvancedInitAllDatas = { module: 'common', conditionId: '', layout: [], items: {}, data: {}, groups: [] };
  formStore: FormStoreType = new FormStore();
  @observable visible = false;
  @observable isInitSearchAd = false;
  @observable.shallow allFields: FieldProps[] = [];
  @observable commonCondition: FieldProps[] = []; // 常用条件设置字段，需过滤仅可在其他条件新增的字段
  @observable.shallow initCommonCondition: FieldProps[] = [];
  @observable searchValue: string = '';
  @observable loading: boolean = false;

  // 语音转文字显隐控制
  @observable voiceBtnVisible: boolean = false;
  // 当前环境是否支持语音转文字功能
  @observable canVoiceToText: boolean = false;

  // 条件范围
  @observable conditionValue: SelectValueType = initConditionValue;
  @observable.shallow conditionData: OptionsType = initConditionData();

  // 快捷搜索
  @observable quikSearchVisible: boolean = false;
  @observable.shallow initQuikSearchTypes: FieldProps[] = [];
  @observable quikSearchTypes: FieldProps[] = [];
  @observable quikSearchValue: ReactText = '';

  // 历史搜索
  historyStore: HistoryStore = new HistoryStore(this);

  // 常用筛选
  commonFilterStore: CommonFilterStore = new CommonFilterStore(this);

  // 高级搜索返回数据集合
  @observable.shallow searchDatas: SearchDatas = {};

  // 拖拽和删除（移动端标记）
  @observable chooseId: string = '';
  @observable isMobile: boolean = false;

  // 常用条件/快捷搜索条件设置
  @observable settingVisible?: boolean = false;
  @observable hSSetting?: boolean = false;
  @observable maxNumberOfQuickSearchCondition?: number = -1;
  @observable minNumberOfQuickSearchCondition?: number = 1;
  @observable quickSearchKey = '';
  @observable quickSearchKeys?: string[];

  @observable initAfterGetField: boolean = true; // 自定义行为 false 表示屏蔽
  @observable hasCommonConfig: boolean = true;

  @observable colSpan?: number = 12; // 默认两列布局

  @observable.shallow commonLayoutConfig?: CommonLayoutConfig = {}; // 常用筛选原始数据，用于排序

  @observable rangePickerFieldKeys: Set<string> = new Set(); // 日期区间字段需转换固定值的字段key值

  @observable rangePickerFieldKeysTemp: Array<string> = []; // 日期区间字段需转换固定值的字段key值(临时备份，用于日期区间数据拦截处理 ---- registerRangePickerFieldUtils)

  transDatasStore: TransDatasStoreType = new TransDatasStore(this);

  @observable filterUselessDatas: boolean = true; //过滤无用data，即没有展示出的字段信息 

  quickSearchStore?: any;

  rewriteApiStore: RewriteApiStoreType = new RewriteApiStore(this); // 接口拦截公共逻辑处理

  @observable showDefaultCondition: boolean = false; // 展示默认条件

  @observable defaultCondition: FormLayoutType = []; // 展示默认条件 layout 集合

  browserConfigStore?: any;

  @observable isSearchRequired?: boolean = false; // 存在必填字段

  customBrowserConfigStore?: any;

  @observable requiredVisible?: boolean = false; // 必填标记提示

  @observable disableRequiredTip?: boolean = false; // 强制禁止必填提示

  storeId: string = getRandom(); // 标记store

  browserIncludingSubStore?: any; // 浏览框含下级逻辑
  mustReDoSearch?: boolean = false; // 必须重新发起search请求
  initFunction?: any;
  needBrowserIncludingSub?: boolean; // 是否需要含下级功能
  disabledBrowserIncludingSub?: boolean = false; // 禁止含下级需求
  // 快捷搜索
  @computed get quikSearchNames() {
    const value = this.quikSearchTypes.filter(his => !his.hide).map(his => his.content).join('/');
    return value && !isCnWord() ? `"${value}"` : value;
  }

  @computed get isEmpty() {
    let datas = this.formStore.datas;
    if (this.filterUselessDatas) { // 过滤无用data
      datas = this.transDatasStore.filterUselessDatas({
        data: datas,
        items: this.formStore.items,
        layout: this.formStore.layout,
        groups: this.formStore.groups,
        customLayout: this.formStore.customLayout,
      });
    }
    return this.judgeEmptyFunc(datas);
  }
  @computed get isEqual() {
    const datas = this.formStore.datas;
    const initDatas = this.formStore.initDatas;
    return isEqual(datas, initDatas) as boolean;
  }

  /**
   * http://gitlab.ecology10.cn/FRONTEND/weapp-ui/-/issues/2595
   * 日期固定值的layout需以this.formStore.allFields为准，解决流程模块联动带出表单字段，固定值选择框不显示的问题
   */
  @computed get hasRangePickerField() {
    return this.formStore.allFields?.find((field) => field.enableCheckDateValueType);
  }

  @computed get rangeFields() {
    return this.formStore.allFields?.filter((field) => field.enableCheckDateValueType);
  }

  @computed get requestParams() {
    return this.requestHeaderParams ? { headers: this.requestHeaderParams } : {};
  }

  @action judgeEmptyFunc = (datas: FormDatas) => {
    const items = this.formStore.items;
    if (Object.keys(datas).length === 0) return true;
    let isEmpty = true;
    const keys = Object.keys(datas);
    for (let i = 0; i < keys.length; i++) {
      const tempKey = keys[i];
      const dataEmpty = this.specialFieldEmptyRules(items[tempKey], datas[tempKey]);
      if (!items[tempKey]?.condition && !dataEmpty) {
        isEmpty = false;
      }
      if (!isEmpty) return;
    }
    return isEmpty;
  }

  @action judgeEmptyRangePickerValue(value: any) {
    return judgeEmpty(value) || ((isObservableArray(value) || Array.isArray(value)) && !value[0] && !value[1]); // 日期区间数据特殊处理，兼容['', '']数据判空处理
  }

  @action judgeRangePickerEmpty(data: any) {
    return !data || (data && this.judgeEmptyRangePickerValue(data.value) && judgeEmpty(data.selectedKey));
  }

  @action specialFieldEmptyRules = (item: FormSingleItemProps, data: FormValue) => {
    if (item?.itemType && ['DATEPICKER', 'DATETIMEPICKER'].indexOf(item?.itemType) >= 0
      && Object.prototype.toString.call(data) === '[object Object]')
      return this.judgeRangePickerEmpty(data);
    if (item?.itemType && ['SCOPE'].indexOf(item?.itemType) >= 0) {
      const value = data as ScopeValue;
      return (judgeEmpty(value?.min) && judgeEmpty(value?.max)) || judgeEmpty(data);
    }
    return judgeEmpty(data);
  }

  @action
  setState = (params: Partial<SearchAdvancedStoreType>) => {
    Object.keys(params).forEach((key) => {
      const storeKey = key as keyof SearchAdvancedStoreType;
      // @ts-ignore
      this[storeKey] = params[storeKey];
    })
  }

  @action
  clear = () => {
    this.rewriteApiStore.clear();
  }

  @action
  addRangePickerFieldKey = (rangePickerFieldKey: string) => {
    this.rangePickerFieldKeys.add(rangePickerFieldKey);
  }

  @action
  removeRangePickerFieldKey = (rangePickerFieldKey: string) => {
    this.rangePickerFieldKeys.delete(rangePickerFieldKey);
  }

  @action
  setChooseId = (chooseId: string) => {
    this.chooseId = chooseId;
  }

  @action
  setIsMobile = (isMobile: boolean) => {
    this.isMobile = isMobile;
  }

  // 接口loading
  @action
  setLoading = (loading: boolean) => {
    this.loading = loading;
  }

  // 面板显示/隐藏
  @action
  setVisible = (visible: boolean) => {
    this.visible = visible;
  }

  // 条件范围
  @action
  setConditionValue = (value: SelectValueType) => {
    this.conditionValue = value;
  }

  @action
  setConditionData = (conditionData: OptionsType) => {
    this.conditionData = conditionData;
  }

  // 设置所有字段类型
  @action
  setAllFields = (allFields?: FieldProps[]) => {
    this.allFields = allFields || [];
    const common = allFields?.filter((field) => !field.custom && field.groupId === 'commonGroup' && field.disable !== true) || [];
    this.commonCondition = common;
    this.initCommonCondition = common;
    this.formStore.setAllFields(allFields || []);
  }

  // 高级搜索面板唯一值
  @action
  setConditionId = (conditionId: string) => {
    this.conditionId = conditionId;
    // 同步historyStore、commonFilterStore内conditionId
    this.historyStore?.setConditionId(conditionId);
    this.commonFilterStore?.setConditionId(conditionId);
  }

  // 高级搜索模块接口标识
  @action
  setModule = (module: string) => {
    this.module = module;
  }

  // 常用条件显示隐藏设置
  @action
  onChangeHide = (check: boolean, id?: string, hSSetting?: boolean) => {
    if (hSSetting) {
      this.setState({
        quikSearchTypes: this.quikSearchTypes.map((field) => {
          if (field.id === id) {
            return { ...field, hide: !check }
          }
          return field;
        })
      })
      return;
    }
    this.setState({
      commonCondition: this.commonCondition.map((field) => {
        if (field.id === id) {
          return { ...field, hide: !check }
        }
        return field;
      })
    });
  }

  // 常用条件设置面板搜索
  @action
  doSearchField = (val: string, allFields?: FieldProps[], hSSetting?: boolean) => {
    if (!allFields) return;
    if (hSSetting) {
      this.setState({
        quikSearchTypes: this.quikSearchTypes.map((field) => {
          return {
            ...field,
            show: !val ? true : (field.title && field.title.indexOf(val) >= 0) || false,
          }
        })
      })
      return;
    }
    this.setState({
      commonCondition: this.commonCondition.map((field) => {
        return {
          ...field,
          show: !val ? true : field.groupId === 'commonGroup' && (!val || (field.title && field.title.indexOf(val) >= 0)) ? true : false,
        }
      })
    })
  }

  // 获取所有常用条件设置面板字段 --- 公共接口
  @action
  getAllFields = (conditionId: string) => {
    return getUtils().then((defaultModule) => defaultModule.getAllFields(this, conditionId));
  }

  @action
  getDefaultValue = (item: FormSingleItemProps, key: string) => {
    let dataKey = key;
    if (key.indexOf('_customField') >= 0) { // 自定义新增字段
      dataKey = key.slice(0, key.indexOf('_customField'));
    }
    const safeItem = item ?? {};
    const itemType = safeItem.itemType ?? '';
    if (itemType === 'INPUTNUMBER') return '';
    else if (itemType === 'RADIO') return '';
    else if (itemType === 'SCOPE') return { min: '', max: '' };
    else if (itemType === 'CHECKBOX') return safeItem?.data || safeItem?.otherParams?.data || safeItem?.options || safeItem?.otherParams?.options ? [] : false;
    else if (itemType === 'DATEPICKER') return safeItem?.isRange || safeItem?.otherParams?.isRange || isMultiDatePickerType(safeItem?.type) || isMultiDatePickerType(safeItem?.otherParams?.type) ? [] : '';
    else if (itemType === 'DATETIMEPICKER' || 'TIMEPICKER') return safeItem?.otherParams?.isRange ? [] : '';
    else if (itemType === 'BROWSER' || itemType === 'BROWSERWINDOW' || itemType === 'TYPESBROWSER') return [];
    return '';
  }

  @action
  doChangeAllDatas = (allDatas: SearchAdvancedInitAllDatas) => {
    const tempData = {
      data: { ...allDatas?.data },
      groups: deepClone(allDatas?.groups),
      layout: deepClone(allDatas?.layout),
      module: allDatas?.module,
      conditionId: allDatas?.conditionId,
      type: allDatas?.type,
      items: {},
    }
    if (allDatas.items) {
      Object.keys(allDatas.items).forEach((key) => {
        tempData.items = {
          ...tempData.items,
          [key]: {
            ...allDatas.items[key],
            value: allDatas.items[key].value || this.getDefaultValue(allDatas.items[key], key),
          }
        }
      })
    }
    this.setState({ allDatas: tempData });
  }

  /**
   * initField
   * 获取field字段配置（其他条件及日期区间兼容转换）
   */
  @action
  getInitField = (col: FormLayoutProps, allDatas: FormInitAllDatas) => _getInitField(col, allDatas, this.isMobile);
  @action
  initSearchAd = (allDatas: SearchAdvancedInitAllDatas, needTransBrowserConfigLayout?: boolean, isReset?: boolean) => {
    /*
      issue: http://gitlab.ecology10.cn/FRONTEND/weapp-ui/-/issues/3475
      高级搜索内浏览框直接展示输入框
    */
    const { items: itemsPre } = allDatas;
    if (itemsPre) {
      let items = { ...itemsPre };
      Object.keys(itemsPre).forEach((itemKey) => {
        if (itemsPre[itemKey]?.itemType === 'BROWSER') {
          items[itemKey] = {
            ...items[itemKey],
            browserBean: {
              ...items[itemKey].browserBean,
              browserAssociativeProps: {
                ...items[itemKey].browserBean?.browserAssociativeProps,
                displayInput: true,
              }
            }
          }
        } else if (items[itemKey]?.itemType === 'TYPESBROWSER') {
          items[itemKey] = {
            ...items[itemKey],
            typesBrowserBean: {
              ...items[itemKey].typesBrowserBean,
              browserAssociativeProps: {
                ...items[itemKey].typesBrowserBean?.browserAssociativeProps,
                displayInput: true,
              }
            }
          }
        } else if (items[itemKey]?.itemType === 'SELECT' && items[itemKey]?.condition) {
          /*
            select 组件默认聚焦文字内容过长省略的时候展开功能。   no.2201062
          */
          items[itemKey] = {
            ...items[itemKey],
            focusAutoExpand: true,
            focusAutoExpandProps: {
              autoExpandUseAbsolute: true,
              autoExpandParentCls: 'ui-formItem-wrapper-condition' // 向上查找的参考 需要特殊处理 通过class查找对应容器 不超过容器内容区大小
            },
          }
        }
      })
      this.setState({
        allDatas: {
          ...allDatas,
          items
        }
      })
    }
    const { transBrowserConfigDatas } = getBrowserConfigUtils();
    const { content: formInitDatas, needInit } = transBrowserConfigDatas?.(allDatas, this, needTransBrowserConfigLayout, isReset) || { content: allDatas, needInit: true };
    this.doChangeAllDatas(formInitDatas);
    if (needInit) this.formStore.initForm(formInitDatas);
    // 初始化allFields数据
    const allFields: FieldProps[] = [], quikSearchTypes: FieldProps[] = [], defaultCondition: FormLayoutType = [];
    const ruleTable: RuleTable = {};
    const { layout } = allDatas;
    this.setState({ chooseId: '' });
    this.commonFilterStore.setId('');
    layout.forEach((row) => {
      row.forEach((col) => {
        const temp = this.getInitField(col, formInitDatas);
        allFields.push(temp);
        if (col.defaultShow) defaultCondition.push(col); // 非默认展示条件
        ruleTable[col.id] = temp;
        if (col.customParameterName) ruleTable[col.customParameterName] = temp;
        /* -------------------------------------------- */
        if (col.needQuickSearch) {
          quikSearchTypes.push(temp);
        }
      })
    })
    this.transDatasStore.setState({ ruleTable });
    if (needInit) this.setAllFields(allFields);
    if (!this.initAfterGetField) this.setQuikSearchTypes(quikSearchTypes); // 包含自定义行为的需等接口返回后处理
    if (needInit) this.formStore.setAllFields(allFields);
    this.setState({ defaultCondition }); // 存储非默认条件 layout 数据
    if (defaultCondition.length > 0) {
      this.setState({ showDefaultCondition: true });
      this.formStore.setState({ groups: [] });
      this.formStore.setLayout([defaultCondition]);
    }
  }

  // 高级搜索常用条件字段排序处理
  @action
  sortCommonCondition = (layout: FormLayoutType[], tempOldData: any, justSort?: boolean) => {
    const otherLayout: FormLayoutType[] = [];
    let commonLayout: FormLayoutProps[] = [];
    let tempCommonLayout: CommonLayoutConfig = {};
    const orderData = tempOldData?.sort((a: any, b: any) => {
      return parseInt(a?.sort) - parseInt(b?.sort);
    }) || [];
    layout.forEach((row) => {
      const nowRow = row.map((col) => {
        const tempCol = orderData.find((da: { layoutId: string; }) => da.layoutId === col.id);
        if (tempCol) {
          tempCommonLayout = justSort ? {
            ...tempCommonLayout,
            [`${col.id}`]: col,
          } : {
            ...tempCommonLayout,
            [`${col.id}`]: {
              ...col,
              hide: !tempCol.open,
            },
          }
          return justSort ? col : {
            ...col,
            hide: !tempCol.open,
          }
        }
        tempCommonLayout = {
          ...tempCommonLayout,
          [`${col.id}`]: col,
        }
        return col;
      })
      if (nowRow && nowRow[0]?.groupId !== 'commonGroup') {
        otherLayout.push(nowRow);
      }
    })
    this.commonLayoutConfig = tempCommonLayout;
    const orderIds = new Set();
    orderData.forEach((da: { layoutId: string; }) => {
      orderIds.add(da.layoutId);
      if (tempCommonLayout[da.layoutId]) {
        commonLayout.push(tempCommonLayout[da.layoutId]);
      }
    })
    /**
     * http://gitlab.ecology10.cn/FRONTEND/weapp-ui/-/issues/2321  问题4
     * 问题点：新增的字段，queryCommonConditions接口返回的排序数据内容没有，新增的字段丢失(控制处理范围为常用分组，groupId=commonGroup)
     */
    let unSortedData: FormLayoutProps[] = [];
    if (orderData.length !== tempCommonLayout.length && tempCommonLayout) {
      Object.keys(tempCommonLayout).forEach((layoutId) => {
        !orderIds.has(layoutId) && tempCommonLayout[layoutId].groupId === 'commonGroup' && unSortedData.push(tempCommonLayout[layoutId]);
      })
    }
    commonLayout = commonLayout.concat(unSortedData);
    return [commonLayout, ...otherLayout];
  }

  // 注册自定义浏览框高级搜索逻辑
  @action
  registerCustomBrowserConfig = async (allDatas: SearchAdvancedInitAllDatas) => {
    if (isCustomBrowser(allDatas?.module, allDatas?.type)) {
      return getCustomBrowserConfigStore().then((defaultModule) => {
        const CustomBrowserConfigStore = defaultModule.default;
        this.setState({
          customBrowserConfigStore: new CustomBrowserConfigStore(this),
        })
        this.customBrowserConfigStore?.init();
        return true;
      })
    }
    return new Promise((resolve) => resolve(true))
  }
  // 高级搜索面板初始化
  @action
  init = async (allDatas: SearchAdvancedInitAllDatas, isReset?: boolean) => {
    const { module, conditionId, requestHeaderParams, fetchData, isSearchRequired, quickSearchKeys } = allDatas;
    let nState: AnyObj = {
      isInitSearchAd: true,
      module,
      quickSearchKeys,
    }
    if (needSync('isSearchRequired', allDatas)) {
      nState = {
        ...nState,
        isSearchRequired: isSearchRequired,
      };
    }
    if (requestHeaderParams) {
      nState = {
        ...nState,
        requestHeaderParams,
      }
    }
    this.setState(nState)
    this.historyStore.setModule(module);
    this.commonFilterStore.setModule(module);
    fetchData && this.rewriteApiStore.register({ fetchData });
    if (needBrowserIncludingSub(allDatas) && !this.disabledBrowserIncludingSub) {
      this.needBrowserIncludingSub = true;
    }
    const p1 = () => new Promise(async (resolve, reject) => {
      if (this.initAfterGetField) {
        if (!isReset) await initQuickSearch(this, conditionId, allDatas);
        this.getAllFields(conditionId).then(action(async (result: any) => {
          if (result && result.data && result.data.length > 0) {
            let { layout, ...resAllDatas } = allDatas;
            this.setState({ hasCommonConfig: true });
            this.initSearchAd({ layout: this.sortCommonCondition(layout, result.data), ...resAllDatas }, false, isReset);
          } else {
            this.initSearchAd(allDatas, true, isReset);
          }
          if (this.needBrowserIncludingSub) {
            registerBrowserIncludingSub(this, allDatas, isReset);
          }
          // 初始化请求默认的筛选条件
          const { module, conditionId } = allDatas;
          this.commonFilterStore.queryDefaultSnaps(module, conditionId);
          if (!isReset) {
            const customBrowserConfigResult = await this.registerCustomBrowserConfig(allDatas);
            if (customBrowserConfigResult) resolve(allDatas);
          } else {
            resolve(allDatas);
          }
        })).catch(error => reject(error));
      } else {
        this.initSearchAd(allDatas, true, isReset);
        if (this.needBrowserIncludingSub) {
          registerBrowserIncludingSub(this, allDatas, isReset);
        }
        if (!isReset) {
          const customBrowserConfigResult = await this.registerCustomBrowserConfig(allDatas);
          if (customBrowserConfigResult) resolve(allDatas);
        } else {
          resolve(allDatas);
        }
      }
    });
    if (isSetting(allDatas?.data, allDatas)) {
      const storePromise = import(
        /* webpackChunkName: "searchadvanced_browser_include_sub" */
        './BrowserConfigStore'
      )
      const loadPromise = await loadBrowserConfigUtils();
      if (loadPromise && !this.browserConfigStore) {
        return storePromise.then((defaultModule) => {
          const browserConfigStore = new defaultModule.default(this);
          this.browserConfigStore = browserConfigStore;
          return p1?.();
        });
      }
    }
    return p1?.();
  }

  @action
  enableBrowserIncludingSub = (allDatas?: SearchAdvancedInitAllDatas) => {
    /* 适用于默认关闭，重新强制开启的场景 */
    if (this.disabledBrowserIncludingSub && !this.needBrowserIncludingSub) {
      this.disabledBrowserIncludingSub = false;
      this.needBrowserIncludingSub = true;
      registerBrowserIncludingSub(this, allDatas || this.allDatas);
    }
  }

  // updateCommonConditions 更新用户自定义设置（默认显示字段）
  @action
  updateCommonConditions = (isMobile?: boolean) => {
    getUtils().then((defaultModule) => defaultModule.updateCommonConditions(this, isMobile));
  }

  /* ------------------------------- 快捷搜索 ------------------------------- */
  @action
  setQuikSearchVisible = (quikSearchVisible: boolean) => {
    this.quikSearchVisible = quikSearchVisible;
  }

  @action
  setQuikSearchTypes = (quikSearchTypes: FieldProps[]) => {
    this.quikSearchTypes = quikSearchTypes;
    this.initQuikSearchTypes = quikSearchTypes;
  }

  @action
  setQuikSearchValue = (quikSearchValue: ReactText) => {
    this.quikSearchValue = quikSearchValue;
    this.customBrowserConfigStore?.setQuikSearchValue();
  }

  /* ------------------------------- 搜索数据整合 ------------------------------- */
  /**
   * 转换日期区间数据：
     1.日期区间字段新增 xxx_obj 数据，抛出日期字段所有数据
     2.data内抛出item.selectedKeyPrior配置，代表当前存储的是否为固定值
   * @param it 字段的key
   * @param itKey 返回数据的key
   * @param result 转换前的数据
   * @returns 
   */
  @action
  addExtraDataForDateRangePicker = (it: string, itKey: string, result: AnyObj) => {
    const allItems = this.formStore?.items; // 表单字段配置
    const formDatas = this.formStore?.datas; // 表单字段配置
    if (this.formStore?.isMatchDateRangeRules(allItems[it])) {
      // 特殊需求 items支持配置realDateValue，true：只取数组(value)、false只取selectedKey，不传根据目前的逻辑走
      const otherParams = allItems[it].otherParams || {};
      if (needSync('realDateValue', allItems[it]) || needSync('realDateValue', otherParams)) {
        const nowValue = formDatas[it] as AnyObj;
        const realDateValue = allItems[it].realDateValue || otherParams.realDateValue;
        const selectedKeyPrior = allItems[it].selectedKeyPrior || otherParams.selectedKeyPrior;
        let nowDateValue = nowValue?.value;
        /**
         * 1.设置了selectedKeyPrior = true，代表日期字段的受控以selectedKey为准
         * 2.设置了realDateValue = true，代表最终业务需要的结果以value为准
         * 对于以上情况，返回的结果需以selectedKey为准，且通过日期组件抛出的方法(getRangeInfoByPrimaryKey)转换数据格式
         * 问题issue: http://gitlab.ecology10.cn/FRONTEND/weapp-ui/-/issues/2442
         * 需求issue: http://gitlab.ecology10.cn/FRONTEND/weapp-ui/-/issues/2332
         */
        if (realDateValue && selectedKeyPrior && nowValue?.selectedKey) {
          // no.2927753 【e10】解决高级搜索中日期范围动态计算周起始日不正确的问题 注意在日期组件中这个周起始日后来默认调整为1了，但是此方法出现的较早，此方法中默认是0，所以在这里需要手动传一下
          const defaultWeekStart = window?.ECODE?.locale?.weekstartday ?? window?.ECODE?.locale?.tweekstartday ?? 1
          const date = !nowValue?.selectedKey ? -1 : getRangeInfoByPrimaryKey(nowValue?.selectedKey, allItems[it].weekStart || otherParams?.weekStart || defaultWeekStart, allItems[it].customMultiBtnData || otherParams?.customMultiBtnData);
          const format = allItems[it].format || otherParams?.format || (
            allItems[it].itemType === 'DATEPICKER' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss'
          )
          if (date !== -1) { // 除了区间组件的toady等数据，其余均为-1
            nowDateValue = this.formStore.transDefaultDate(date.startDate as DatePickerDateType, date.endDate as DatePickerDateType, format);
          }
        }
        /* --------------------------------------------------------------------------------------------------------------- */
        result = {
          ...result,
          [`${itKey}`]: allItems[it].realDateValue || otherParams.realDateValue ? nowDateValue : nowValue?.selectedKey || false,
        };
      }
      /* 
        一、日期区间字段value值标准：按照items当前配置返回日期区间的value值。selectedKeyPrior=true，返回selectedKey数据，在formStore.getFormDatas中处理
        二、处理http://gitlab.ecology10.cn/FRONTEND/weapp-ui/-/issues/2595：存在选中高级搜索常用筛选项时，datas以当前常用筛选保存的数据为准
      */
      const { chooseData, id } = this.commonFilterStore;
      let temp_selectedKeyPrior = allItems[it].selectedKeyPrior || allItems[it].otherParams?.selectedKeyPrior || false;
      if (id && chooseData && typeof chooseData.content === 'string') {
        const snapDatas = JSON.parse(chooseData.content);
        temp_selectedKeyPrior = snapDatas?.data[it]?.selectedKeyPrior || false;
      }
      result = {
        ...result,
        [`${itKey}_obj`]: formDatas[it],
        [`${itKey}_selectedKeyPrior`]: temp_selectedKeyPrior,
        // [`${itKey}_selectedKeyPrior`]: this.rangePickerFieldKeysTemp.indexOf(it) < 0, // 日期区间存为固定值，保存常用筛选时使用 --- 历史取值逻辑
      };
    }
    return result;
  }
  @action
  getData = (items: string[], allDatas: FormDatas) => {
    const allItems = this.formStore?.items; // 表单字段配置
    const formDatas = this.formStore?.datas; // 表单字段配置
    let res: SingleFormDatas = {};
    if (!allDatas || !allItems) return res;
    items.forEach((it) => {
      const nowData = allDatas[it];
      if (!judgeEmpty(nowData)) { // 常用条件组(custom=false)
        const itKey = allItems[it].customParameterName || it; // item支持别名
        const customParameters = allItems[it].customParameters || {};
        let result = {
          [`${itKey}`]: typeof nowData === 'string' ? nowData?.trim() : nowData,
          ...customParameters,
        };
        /* ---- 日期区间字段新增 xxx_obj 数据，抛出日期字段所有数据 ---- */
        result = this.addExtraDataForDateRangePicker(it, itKey, result);
        /* ----------------------------------------------------- */
        result = this.browserIncludingSubStore?.transBrowserData(it, itKey, result) || result;
        res = {
          ...res,
          main: 'main' in res ? {
            ...res['main'],
            ...result,
          } : result
        }
      }
    })
    const result: Array<FormDatas> = [];
    Object.keys(res).forEach((k) => {
      result.push(res[k]);
    })
    return result;
  }

  /* ------------------------ 获取其他条件组(custom=true)当前items配置 ------------------------------------------- */
  @action
  getCustomLayouts = (id: string) => {
    const { customLayout } = this.formStore; // form内custom=true分组的layout配置
    let items = {};
    if (customLayout) {
      Object.keys(customLayout).forEach((groupId) => { // customLayout以groupId为分组存储数据
        customLayout[groupId].forEach((row) => {
          row.forEach((col) => {
            if (col.customId === id) items = { // customId为custom=true分组的唯一id标识(非id, 与Form设计有关)
              ...items,
              [`${col.labelId}`]: col.items, // 通过labelId区分: （原因）字段可重复，因此customId、items会重复
            }
          })
        })
      })
    }
    return items;
  }

  /**
   * 
   * @param type 方法调用来源，组件内部使用
   * @returns 
   */
  @action
  getSearchValue = () => {
    // 面板表单数据
    const { getFormItemField, allFields, layout } = this.formStore;
    let result: AnyObj = {};
    const allItems = this.formStore?.items; // 表单字段配置
    const tempAllDatas = typeof this.customFilterFormDatas === 'function' ?  // 拦截form数据，自定义过滤数据
      this.customFilterFormDatas?.(this.formStore?.datas, { items: allItems, datas: this.formStore?.datas })
      : this.formStore?.getFormDatas(); // 表单所有数据
    const allDatas = this.filterUselessDatas ? this.transDatasStore?.filterUselessDatas?.({
      data: tempAllDatas,
      items: allItems,
      groups: this.formStore?.groups,
      layout: this.formStore?.layout,
      customLayout: this.formStore?.customLayout,
    }) : tempAllDatas;
    allFields?.forEach((field) => {
      if (field.id) {
        const customField = getFormItemField(field.id, layout);
        const customLayouts: AnyObj = this.getCustomLayouts(field.id); // 其他条件组(获取当前items配置，由于存在联动，items与常用条件组不一致)(custom=true)
        const res = this.getData(customField.items, allDatas) as Array<FormDatas>; // 常用条件组数据集合转换(custom=false)
        const lens = Object.keys(customLayouts).length;
        if (lens > 0 && allDatas && allItems) {
          Object.keys(customLayouts).forEach((labelId) => {
            const nowItems = customLayouts[`${labelId}`];
            if (nowItems) {
              let tempData = {};
              nowItems.forEach((itemKey: string) => {
                const dataKey = itemKey;
                const itKey = allItems[dataKey].customParameterName || dataKey; // item支持别名
                const customParameters = allItems[dataKey].customParameters || {}; // item支持传入自定义参数
                const data = allDatas[`${itemKey}_${labelId}`]; // datas中存储数据以item的key+labelId区分
                if (!judgeEmpty(data)) {
                  tempData = {
                    ...tempData,
                    [`${itKey}`]: data,
                    ...customParameters,
                  }
                  tempData = this.addExtraDataForDateRangePicker(`${itemKey}_${labelId}`, itKey, tempData);
                  tempData = this.browserIncludingSubStore?.transBrowserData(`${itemKey}_${labelId}`, itKey, tempData) || tempData;
                } else if (allItems[`${itemKey}_${labelId}`]?.condition && !judgeEmpty(allItems[`${itemKey}_${labelId}`]?.value)) {
                  tempData = {
                    ...tempData,
                    [`${itKey}`]: allItems[`${itemKey}_${labelId}`]?.value,
                    ...customParameters,
                  }
                }
              });
              if (Object.keys(tempData).length > 0) {
                res.push(tempData);
              }
            }
          })
        }
        if (res.length > 0) {
          const key: string = field.customParameterName || field.id; // layout支持别名，别名可重名
          if (needSync(key, result)) {
            result = {
              ...result,
              [`${key}`]: [...result[key], ...res],
            }
          } else {
            result = {
              ...result,
              [`${key}`]: res,
            }
          }
        }
      }
    })
    // 快捷搜索数据
    const quickSearchResults: QuickSearchDatas = {};
    if (this.quikSearchValue) {
      let quickvalue = this.quikSearchValue;
      if (typeof quickvalue === 'string') {
        quickvalue = quickvalue.trim();
      }
      if (this.quikSearchTypes.length > 0 && this.quikSearchNames) {
        this.quikSearchTypes.filter((type) => !type.hide).forEach((type) => {
          type.id && (quickSearchResults[type.id] = quickvalue);
        })
      } else if (this.quickSearchKey) {
        quickSearchResults[this.quickSearchKey] = quickvalue;
      }
    }
    const searchDatas = {
      formDatas: result,
      quickSearchDatas: quickSearchResults,
      type: this.conditionValue,
    }
    this.searchDatas = searchDatas;
    return searchDatas;
  }

  /**
   * 重置高级搜索
   * @param callBack 重置回调
   */
  @action
  reset = (callBack?: ResetType) => {
    const { formStore, formStore: { initForm, setAllCustomLayout }, commonFilterStore: { setId, setState, id, chooseData } } = this;
    /* 存在浏览框自定义设置默认数据(未选中常用筛选) */
    const { updateSnapes } = getBrowserConfigUtils();
    if (this.browserConfigStore?.hasDefaultConfig) {
      this.browserConfigStore?.reset?.().then(() => {
        if (id && chooseData.content) {
          const con = JSON.parse(chooseData.content as string);
          updateSnapes?.(con, this, { forbidMessage: true });
          setId(id);
          setState({ chooseData });
        }
        this.browserIncludingSubStore?.reset?.();
        typeof callBack === 'function' && callBack();
      });
      return;
    }
    if (id && chooseData.content) {
      const con = JSON.parse(chooseData.content as string);
      /* 
        http://gitlab.ecology10.cn/FRONTEND/weapp-ui/-/issues/2321
        chooseData，代表取消选中常用筛选项；
        _getFormConfig：统一返回表单布局；
      */
      let { items, layout, groups } = this.transDatasStore._getFormConfig(con);
      if (layout) {
        const { customLayout, customItems } = chooseData;
        if (isCustomLayoutEmpty(customLayout)) { // 无其他条件组数据，执行resetForm即可
          formStore?.resetForm();
        } else {
          initForm({ ...con, layout, groups, items: customItems || items });
          formStore?.resetForm(); //清除上次选中项的多余数据
          if (customLayout) { // 接口数据不再存储自定义布局，由transCustomLayout内部处理并存储
            setAllCustomLayout(customLayout, true);
          }
        }
        this.browserIncludingSubStore?.reset?.();
        typeof callBack === 'function' && callBack();
        return;
      }
    }
    formStore?.resetForm();
    this.browserIncludingSubStore?.reset?.();
    typeof callBack === 'function' && callBack();
  }

  @action
  validate = (config?: AnyObj) => {
    this.formStore.setState({ validateHiddenItems: false }); // 隐藏的字段不需要校验
    return getUtils().then((defaultModule) => {
      return defaultModule.validate(this, config);
    })
  }
}

export type SearchAdvancedStoreType = Pick<SearchAdvancedStore, keyof SearchAdvancedStore>;
