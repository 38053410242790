import { extensions } from './extenMap';
import { getSystemUploadCheckConfig } from './systemUploadCheck';
import { diff, intersection, union } from './util';

const mimeMap: { [key: string]: string | string[] } = {};

export const setMimeMap = (extensions: {
  [key: string]: string | string[];
}) => {
  const setListMimes = (mime: string, ext: string) => {
    if (mimeMap[mime]) {
      if (typeof mimeMap[mime] === 'string') {
        mimeMap[mime] = [mimeMap[mime] as string, ext];
      } else {
        (mimeMap[mime] as string[]).push(ext);
      }
    } else {
      mimeMap[mime] = ext;
    }
  }

  for (const ext in extensions) {
    if (Object.prototype.hasOwnProperty.call(extensions, ext)) {
      const mimes = extensions[ext];
      if (typeof mimes == 'string') {
        setListMimes(mimes, ext);
      } else {
        mimes.forEach(mime => {
          setListMimes(mime, ext);
        });
      }
    }
  }
}

setMimeMap(extensions);

/**
 * Returns file extension for a content type string. If no suitable extensions
 * are found, 'mime' is used as the default extension
 *
 * @param {String} mimeType Content type to be checked for
 * @return {String} File extension
 */
export function detectExtension(mime: string) {
  mime = mime.toString().toLowerCase().replace(/\s/g, '');

  if (!(mime in mimeMap)) {
    return mime;
  }

  return mimeMap[mime];
}

/**
 * Returns content type for a file extension. If no suitable content types
 * are found, `.${extension}` is used as the default content type
 *
 * @param {String} extension Extension to be checked for
 * @return {String} File extension
 */
export function detectMimeType(extension = '') {
  extension = extension.toString().toLowerCase().replace(/\s/g, '').replace(/^\./g, '')

  if (!(extension in extensions)) {
    return `.${extension}`;
  }

  return extensions[extension];
}

/**
//  * 获得限制上传类型
//  * @param {文件限制} extStr string
//  * @returns any[]
//  */
export const ext2mimes = function (extStr: string) {
  let result: string[] = [];
  const items = extStr.split(/,|;/);
  items.forEach((item) => {
    if (!item) return;
    // 将后缀名加入返回结果
    // 只使用 mimeType 作为筛选方法的话，根据浏览器的不同可识别文件可能有误
    const ext = item.replace(/\s|\./g, () => '');
    result.push(`.${ext}`);
    // 将 mimeType 加入返回结果
    const mimeTypes = detectMimeType(item);
    if (typeof mimeTypes === 'string') {
      result.push(mimeTypes);
    } else {
      result = result.concat(mimeTypes);
    }
  });
  // 去重
  return Array.from(new Set(result));
};

/** 根据 limitType 获取 ext */
export const getExtArray = (limitType: string) => {
  let limitArray = limitType.toLowerCase().replace(/\s/g, '').split(/,|;/);
  // jpg 与 jpeg 为同一种格式的不同后缀
  if (limitArray.includes('jpg') || limitArray.includes('jpeg')) {
    limitArray = limitArray.concat(['jpg', 'jpeg']);
  }
  return Array.from(new Set(limitArray));
}

/** 根据黑白名单获取 accept */
export const getAccept = (props: {
  limitType?: string,
  prohibitType?: string,
}) => {
  const { limitType, prohibitType } = props;
  const { sysFormatWhites, sysFormatBlacks, sysFormatWhitesEnable } = getSystemUploadCheckConfig();
  let newLimitType = '*', newProhibitType = '';

  // 白名单
  if (sysFormatWhitesEnable) {
    if (limitType && sysFormatWhites) {
      if (limitType === '*' || limitType === '*.*') {
        newLimitType = sysFormatWhites || '*';
      } else if (sysFormatWhites === '*' || sysFormatWhites === '*.*') {
        newLimitType = limitType || '*';
      } else {
        // 取交集
        let limitArr = limitType.toLowerCase().replace(/\s*/g, "").split(/,|;/);
        let whitesArr = sysFormatWhites.toLowerCase().replace(/\s*/g, "").split(/,|;/);
        newLimitType = Array.from(new Set(intersection(limitArr, whitesArr))).join(',');
      }
    } else {
      newLimitType = limitType || sysFormatWhites || '*';
    }
  } else {
    newLimitType = limitType || '*';
  }

  // 黑名单
  if (!sysFormatWhitesEnable) {
    if (newLimitType && newLimitType !== '*') {
      if (prohibitType && sysFormatBlacks) {
        if (prohibitType === '*' || prohibitType === '*.*') {
          newProhibitType = '*';
        } else if (sysFormatBlacks === '*' || sysFormatBlacks === '*.*') {
          newProhibitType = '*'
        } else {
          // 取并集
          let prohibitArr = prohibitType.toLowerCase().split(/,|;/);
          let blacksArr = sysFormatBlacks.toLowerCase().split(/,|;/);
          newProhibitType = Array.from(new Set(union(prohibitArr, blacksArr))).join(',');
        }
      } else {
        newProhibitType = prohibitType || sysFormatBlacks || '';
      }
    }
  } else {
    newProhibitType = prohibitType || '';
  }

  // 黑白名单取差集
  if (newProhibitType) {
    if (newProhibitType === '*') {
      newLimitType = '';
    } else {
      // 取差集
      newLimitType = diff(newLimitType.split(/,|;/), newProhibitType.split(/,|;/)).join(',') || '';
    }
  }

  let accept = '';

  if (!newLimitType) {
    accept = '';
  } else if (newLimitType === '*' || newLimitType === '*.*') {
    accept = '*'
  } else {
    accept = ext2mimes(newLimitType).join(',');
  }
  return {
    accept,
    limitType: newLimitType
  }
}
