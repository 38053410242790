import { getLabel, getSyncHook, ua, weappSDK, corsImport } from '@weapp/utils';
import { AnyObj, PhotoViewData } from '../../../lib';
import { IFile, ProcessErrorType, UploadData, FilePreviewOptions, UpdateFileStatusRequestOption } from '../types';
import { openLink, getRealUrl, getExtendName } from './util';
import { DOWNLOADURL, WEA_LINK_KEEP_TEMPOTHERTOKEN } from '../constants/index';
import { getSecretLevelOption } from '../../secret-select';
import { starSymbol } from '../../secret-select/constants';
import { getHash, isMobile, isValueEmpty } from '../../../utils';
import { getSystemUploadCheckConfig } from './systemUploadCheck';
import { getBatchDownloadDataKey, getBatchDownloadPercent } from "./request";
import { message } from "../../dialog/dialog/message";

const filePreview = (option: FilePreviewOptions) => import(
  /* webpackChunkName: "upload" */
  '../FilePreview'
  ).then((defaultModule) => defaultModule?.default?.(option));

const updateFileStatusRequest = (option: UpdateFileStatusRequestOption) => import(
  /* webpackChunkName: "upload" */
  './request'
  ).then((defaultModule) => defaultModule?.updateFileStatusRequest?.(option))

/**
 * 获得批量上传完成提示信息
 * @param successFiles IFile[]
 * @param failedFiles IFile[]
 * @returns string
 */
export const getBatchUploadCompletedInfo = (successFiles: IFile[], failedFiles: IFile[]) => {
  let successInfo =
    getLabel('70971', '批量上传完成') + '，' +
    getLabel('70969', '共上传{total}个，成功{successnum}个，失败{failednum}个')
      .replace('{total}', (successFiles.length + failedFiles.length).toString())
      .replace('{successnum}', successFiles.length.toString())
      .replace('{successNum}', successFiles.length.toString())
      .replace('{failednum}', failedFiles.length.toString())
      .replace('{failedNum}', failedFiles.length.toString())
  return successInfo;
}

export const unifiedPreview = (data: UploadData[], curitem: UploadData, commonParams: AnyObj, otherParams?: AnyObj) => {
  let fileId: string = curitem.fileid!;
  let fileIds: string[] = [], imgDatas: UploadData[] = [];
  let isImage: boolean = false;
  // 预览分为 1.图片预览（所有能够预览的图片都要走预览接口拿到图片地址）
  // 2.文件预览（文件预览只需要走一遍拿到文件的预览地址即可）
  if (curitem.img || curitem.image) {// 1. 图片预览
    isImage = true;
    // 拿到所有可预览的图片 fileId
    imgDatas = data.filter(item => (item.img || item.image) && item.fileStatus !== 1 && item.fileStatus !== 2);
    fileIds = imgDatas.map(item => item.fileid || '');
  }
  // 配置预览属性
  let options: FilePreviewOptions = {
    fileId,
    isImage,
    commonParams: commonParams,
    data: curitem,
    datas: imgDatas,
    ...otherParams,
  }
  if (fileIds.length > 0) {
    options.fileIds = fileIds;
  }
  // 调用通用预览方法
  filePreview?.(options)
}

/**
 * 图片预览功能
 * @param params AnyObj
 */
export const imagePreview = async (params: {
  fileIds: string[],
  fileId: string,
  previewURL?: string,
  datas?: UploadData[],
  onSuccess?: (images: PhotoViewData[], fIndex: number, isMobile: boolean, otherParams: {
    loginFree?: boolean,
    module?: string,
    commonParams?: AnyObj
  }) => void,
  onError?: (res: any) => void,
  isMobile?: boolean,
  loginFree?: boolean,
  module?: string,
  commonParams?: AnyObj
}) => {
  return import(
    /* webpackChunkName: "upload" */
    './bathAsync').then((defaultModule) => defaultModule?.imagePreview(
    params
  ));
}

const queryURLParams = () => {
  if (!window.location.search) {
    return {};
  }
  let url = window.location.search.substring(1);
  let obj: AnyObj = {}; // 声明参数对象
  let arr = url.split("&"); // 以&符号分割为数组
  for (let i = 0; i < arr.length; i++) {
    let arrNew = arr[i].split("="); // 以"="分割为数组
    obj[arrNew[0]] = arrNew[1];
  }
  return obj;
}

/** 打开金格插件 */
export const openKinggrid2015 = (url: string) => {
  corsImport(`@weapp/file`)
    .then((app) => {
      const { IWebOffice2015Util } = app;
      if (IWebOffice2015Util.isNewWindowIweOffice2015()) {
        let instance = new IWebOffice2015Util({
          createWebSocketCallBack: () => {//创建完成socket回调
            instance.open({
              url,
              urlParams: {
                useIWebOffice2015: 1
              }
            })
          }
        })
      } else {
        // 其他（excel、word 等）
        // 这里调用打开链接方法
        openLink(url);
      }
    })
    .catch(err => {
      console.error(err);
      // 其他（excel、word 等）
      // 这里调用打开链接方法
      openLink(url);
    });
}

/**
 * 其他文件统一预览方法（包括 excel、word 等非 upload 内部处理预览的文件）
 * @param params anyObj
 */
export const otherFilePreiver = (params: {
  previewLink: string,
  loadUrl: string,
  previewType: string,
  hasPlugin?: boolean;
  onError: (res?: any) => void;
}) => {
  const { previewLink, loadUrl, previewType, hasPlugin, onError } = params;
  if (previewLink || loadUrl) {
    /**
     * 预览类型。
     * 当值为 load 时，取 loadUrl 下载地址的值，下载文件进行预览处理，一般为图片、视频、音频文件，通过下载地址调用播放组件进行处理。
     * 当值为 link 时，取 previewLink 地址进行请求跳转。
     * 当值为 origin 时，previewLink 返回的时请求 url 需要拼接当前环境的域名进行请求，进行预览。
     */
    let url = '';
    switch (previewType) {
      case 'load':
        url = loadUrl
        break;
      case 'link':
        url = previewLink
        break;
      case 'origin':
        url = window.origin + previewLink
        break;
      default:
        break;
    }

    const params = queryURLParams();
    if (WEA_LINK_KEEP_TEMPOTHERTOKEN in params) {
      if (url.indexOf('?') === -1) {
        url += `?${WEA_LINK_KEEP_TEMPOTHERTOKEN}=${params[WEA_LINK_KEEP_TEMPOTHERTOKEN]}`;
      } else {
        url += `&${WEA_LINK_KEEP_TEMPOTHERTOKEN}=${params[WEA_LINK_KEEP_TEMPOTHERTOKEN]}`;
      }
    }if (hasPlugin) {
      openKinggrid2015(url);
    } else {
      // 其他（excel、word 等）
      // 这里调用打开链接方法
      openLink(url);
    }
  } else {
    onError?.();
  }
}

/**
 * 处理附件报错提示信息
 * @param option ProcessErrorType
 */
export const processErrorInfo = (option: ProcessErrorType) => {
  const {
    type,
    file,
    maxCount,
    maxSize,
    totalMaxSize,
    res,
    message,
    isUploadOnlyOne,
    customErrorInfo,
    addErrorInfo
  } = option;
  let errorInfo;
  let errMes;
  const { sysMaxSingleUploadSize } = getSystemUploadCheckConfig();
  const maxFileSize = sysMaxSingleUploadSize && maxSize ? Math.min(maxSize, sysMaxSingleUploadSize) : maxSize || sysMaxSingleUploadSize || 0;
  switch (type) {
    case 1:
      errMes = `${getLabel("59793", "文件数量不能超过{maxCount}个")}`
      errMes = errMes.replace("{maxCount}", maxCount?.toString() || '1')
      errorInfo = customErrorInfo?.(file, type) || errMes;
      addErrorInfo?.(!!isUploadOnlyOne, file, errorInfo, type, res);
      break;
    case 2:
      errMes = `${getLabel("59795", "文件大小不能超过{maxSize}MB")}`
      errMes = errMes.replace("{maxSize}", maxFileSize?.toString() || '')
      errorInfo = customErrorInfo?.(file, type) || errMes;
      addErrorInfo?.(!!isUploadOnlyOne, file, errorInfo, type, res);
      break;
    case 3:
      const fileExtendName = getExtendName(file.name);
      errorInfo = customErrorInfo?.(file, type) || (fileExtendName ? getLabel("241073", "$s 文件禁止上传").replace('$s', fileExtendName) : getLabel("59797", "该文件类型禁止上传"));
      addErrorInfo?.(!!isUploadOnlyOne, file, errorInfo, type, res);
      break;
    case 4:
      errMes = `${getLabel("59796", "总文件大小不能超过{totalMaxSize}MB")}`
      errMes = errMes.replace("{totalMaxSize}", totalMaxSize?.toString() || '')
      errorInfo = customErrorInfo?.(file, type) || errMes;
      addErrorInfo?.(!!isUploadOnlyOne, file, errorInfo, type, res);
      break;
    case 5:
      errorInfo = customErrorInfo?.(file, type) || file.errorInfo || `${getLabel("59798", "上传已取消")}`;
      addErrorInfo?.(!!isUploadOnlyOne, file, errorInfo, type, res);
      break;
    case 7:
      errorInfo = customErrorInfo?.(file, type) || `${getLabel('79555', '文件大小不能为 0')}`;
      addErrorInfo?.(!!isUploadOnlyOne, file, errorInfo, type, res);
      break;
    case 300:
      errorInfo = customErrorInfo?.(file, type) || `${getLabel('121107', '检测到敏感词')}`;
      addErrorInfo?.(!!isUploadOnlyOne, file, errorInfo, type, res);
      break;
    // 密级错误
    case 8:
      errorInfo = customErrorInfo?.(file, type) || message || getLabel('216511', '密级校验失败，请重新上传');
      addErrorInfo?.(!!isUploadOnlyOne, file, errorInfo, type, res);
      break;
    case -1:
      // 接口非 200，网络异常错误
      errorInfo = `${getLabel('238308', '上传中断，网络异常')}`;
      addErrorInfo?.(!!isUploadOnlyOne, file, errorInfo, type, res);
      break;
    case -2:
      // 取消上传
      break;
    case -3:
      // 接口错误
      errorInfo = res?.msg || res?.message || customErrorInfo?.(file, type) || `${getLabel('238309', '接口异常，请重试')}`;
      addErrorInfo?.(!!isUploadOnlyOne, file, errorInfo, type, res);
      break;
    case -4:
      // 文件解析错误
      errorInfo = res?.msg || res?.message || customErrorInfo?.(file, type) || `${getLabel('228367', '$s md5 解析失败，文件读取异常').replace('$s', file.name)}`;
      addErrorInfo?.(!!isUploadOnlyOne, file, errorInfo, type, res);
      break;
    default:
      // 这里为接口 200，但是上传失败
      errorInfo = res?.msg || res?.message || customErrorInfo?.(file, type || 0) || `${getLabel("32131", "上传失败")}`;
      addErrorInfo?.(!!isUploadOnlyOne, file, errorInfo, 0, res);
      break;
  }
}

/**
 * 下载方法
 * @param option EditRequestOption
 * @returns RequestPromise<any>
 */
export const download = (option: {
  fileId: string,
  loginFree?: boolean,
  module?: string,
  params?: AnyObj
}) => {
  const { fileId, loginFree, module, params } = option;
  let reqParams: AnyObj = module ?
    {
      ...params,
      module: module,
      v: getHash(),
    } :
    {
      ...params,
      v: getHash(),
    }
  //  临时屏蔽移动端免登陆下载接口，改为统一走 pc 免登陆下载接口
  let url = getRealUrl(DOWNLOADURL, { loginFree, fileId });
  if (!isValueEmpty(reqParams)) {
    let params = '';
    Object.keys(reqParams).forEach((item, index) => {
      let prefix = '&';
      if (index === 0) {
        prefix = '?';
      }
      if (typeof reqParams?.[item] === 'string' && reqParams?.[item]) {  // 字符串，对特殊字符进行处理
        params += prefix + `${item}=${encodeURIComponent(reqParams?.[item])}`;
      } else if (typeof reqParams?.[item] === 'object') {  // 对象就 JSON 序列化后，对特殊字符进行处理
        params += prefix + `${item}=${encodeURIComponent(JSON.stringify(reqParams?.[item]))}`;
      } else {  // 其他，直接拼接
        params += prefix + `${item}=${reqParams?.[item]}`
      }
    })
    url += params;
  }
  const hooks = getSyncHook('weappUi', 'Upload.download.overwrite');
  if (typeof hooks === 'function') {
    hooks({ url, ...option });
  } else {
    let type = 'open';
    if ((ua.os === 'Windows' && ua.browser === 'wxwork') || ua.browser === "Weapp") {
      // 1. 企业微信 win 客户端下载会打开空白页，改为使用 location.href 打开下载地址
      // 2. e10 app 中，window.open 被做了调整，下载有可能出现空白页，需要直接使用 window.location.href 打开下载地址
      type = 'href';
    }
    weappSDK.openLink({ url, type });
  }
}

/**
 * 更新文件签署状态
 * @param options any
 */
export const updateFileStatus = async (options: {
  oldFile?: UploadData,
  uploadParams: any,
  onError?: (error?: any) => void;
  onSuccess?: (newListT: UploadData[]) => void;
  isComment?: boolean;
  commonParams?: AnyObj,
  loginFree?: boolean,
}) => {
  const { isComment, oldFile, loginFree, onError, onSuccess, commonParams } = options;
  let params: any = { ...commonParams };
  if (isComment) params.commentFlag = true;
  try {
    let res = await updateFileStatusRequest({
      docId: oldFile?.docId,
      refId: oldFile?.refId,
      params: params,
      loginFree,
    });
    const newFiles = res?.data || null;
    if (newFiles) {
      onSuccess?.(newFiles);
    } else {
      onError?.();
    }
  } catch (error) {
    onError?.(error);
  }
}

/**
 * 获取文件密级信息
 */
export const getSecretContent = (opt: {
  secretLevel?: string,
  secretLevelValidity?: string | null,
  /** 主次账号 */
  empId?: string,
  /** 自定义密级接口 key */
  key?: string,
}) => {
  const { secretLevel, secretLevelValidity } = opt;
  if (!secretLevel) return '';
  const secretLevelOption = getSecretLevelOption(secretLevel, false, {
    empId: opt?.empId,
    key: opt?.key,
  });
  const needValidity = secretLevelOption.secretLevelValidityShowType !== 'hidden';
  let secretContent = needValidity && secretLevelValidity && secretLevelValidity !== 'null'
    ? `${secretLevelOption.content}${secretLevelOption.identifier || starSymbol}${secretLevelValidity}`
    : `${secretLevelOption.content}`;
  return secretContent;
}

/**************************************** 批量下载 ****************************************/

/** 批量下载异常 */
const onBatchDownloadError = (options: {
  err?: any
  setShowProgress: (isShow: boolean) => void;
  setPercent: (percent: number) => void;
  setTotal: (total: number) => void;
}) => {
  const { err, setPercent, setShowProgress, setTotal } = options;
  setPercent(0);
  setTotal(0);
  setShowProgress(false);
  err && console.error(err);
}

/** 批量下载 - 打包 ing... */
const onPackFile = (option: {
  uuid: string,
  module?: string,
  loginFree?: boolean,
  commonParams?: AnyObj,
  setShowProgress: (isShow: boolean) => void;
  setPercent: (percent: number) => void;
  setTotal: (total: number) => void;
}) => {
  const { uuid, module, loginFree, commonParams, setShowProgress, setTotal, setPercent } = option;
  /** 获取下载进度 */
  getBatchDownloadPercent({ params: { ...commonParams, uuid }, loginFree })
    .then((percentRes) => {
      const { status, data, msg } = percentRes;
      if (!status || !uuid) {
        message({ type: 'error', content: msg });
        onBatchDownloadError({
          setTotal,
          setPercent,
          setShowProgress,
        });
        return;
      }
      const { percent, fileId } = data;
      let _percent = Number(percent);
      if (_percent < 0) _percent = 0;
      setPercent(parseInt(`${50 + _percent / 2}`));
      if (_percent === 100 && fileId) {  // 打包完成，触发下载接口
        // 300 延迟后隐藏进度
        let st = setTimeout(() => {
          setShowProgress(false);
          clearTimeout(st);
        }, 300);
        // 触发下载接口
        const hooks = getSyncHook('weappUi', 'Upload.batchDownload.download.overwrite');
        if (typeof hooks === 'function') {
          hooks({ loginFree, module, fileId: fileId, params: commonParams })
        } else {
          // 特殊处理，批量操作下载不要 commonParams 中的 authParams
          const params = { ...commonParams };
          delete params['authModule'];
          download({ loginFree, module, fileId: fileId, params: params })
        }
      } else {
        // 轮询，查看进度
        let st = setTimeout(() => {
          onPackFile(option);
          clearTimeout(st);
        }, 300);
      }
    })
    .catch((err) => {
      onBatchDownloadError({
        err,
        setTotal,
        setPercent,
        setShowProgress,
      });
    })
}


/** 批量下载 - 数据处理 ing... */
const onDataHanding = (option: {
  uuid: string,
  module?: string,
  loginFree?: boolean,
  commonParams?: AnyObj,
  setShowProgress: (isShow: boolean) => void;
  setPercent: (percent: number) => void;
  setTotal: (total: number) => void;
}) => {
  const { uuid, commonParams, loginFree, setShowProgress, setTotal, setPercent } = option;
  /** 获取下载进度 */
  getBatchDownloadPercent({
    params: { ...commonParams, uuid, cacheModule: 'PackAsyncDownload' },
    loginFree,
  })
    .then((percentRes) => {
      const { status, data, msg } = percentRes;
      if (!status || !uuid) {
        message({ type: 'error', content: msg });
        onBatchDownloadError({
          setTotal,
          setPercent,
          setShowProgress,
        });
        return;
      }
      const { percent } = data;
      let _percent = Number(percent);
      if (_percent < 0) _percent = 0;
      setPercent(Number.parseInt(`${_percent / 2}`));
      if (_percent === 100) {  // 打包完成，触发下载接口
        onPackFile(option);
      } else {
        // 轮询，查看进度
        let st = setTimeout(() => {
          onDataHanding(option);
          clearTimeout(st);
        }, 300);
      }
    })
    .catch((err) => {
      onBatchDownloadError({
        err,
        setTotal,
        setPercent,
        setShowProgress,
      });
    })
}


/** 触发下载 */
export const onBatchDownload = async (options: {
  loginFree?: boolean,
  module?: string,
  ids: string[],
  commonParams?: AnyObj,
  setShowProgress: (isShow: boolean) => void;
  setPercent: (percent: number) => void;
  setTotal: (total: number) => void;
}) => {
  const {
    loginFree, module, ids, commonParams,
    setShowProgress, setPercent, setTotal,
  } = options;
  try {
    setShowProgress(true);
    setPercent(0);
    setTotal(ids.length);
    const params = {
      module,
      fileIds: ids.join(','),
      ...commonParams
    }
    /** 获取 key */
    const res = await getBatchDownloadDataKey({
      params,
      loginFree,
    });
    const taskId = res?.data?.taskId || '';
    if (!res.status || !taskId) {
      message({ type: 'error', content: res?.msg });
      onBatchDownloadError({
        setTotal,
        setPercent,
        setShowProgress,
      });
      return;
    }
    ;
    /** 开始打包 */
    let st = setTimeout(() => {
      onDataHanding({
        uuid: taskId,
        module,
        loginFree,
        commonParams,
        setPercent,
        setTotal,
        setShowProgress
      });
      clearTimeout(st);
    }, 500);
  } catch (err) {
    onBatchDownloadError({
      err,
      setTotal,
      setPercent,
      setShowProgress,
    });
  }
}
