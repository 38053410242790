

import { getLabel, request } from '@weapp/utils';
import { AnyObj, utils } from '../../../lib';
import { CHECKVALIDITY, GETSECLEVELURL } from '../constants/index';
import { SecretLevelValidityShowType, SecretSelectOptionType } from '../types';
import { res } from './mock';
import { Method } from 'axios';

const { doValidator, isValueEmpty, isNumber } = utils;

export const MAINKEY = '__main__empId';

const promiseMap: {
  [key: string]: Promise<any>
} = {};
const secretLevelInfoMap: {
  [key: string]: {
    resourceOptionInfo: SecretSelectOptionType[];
    secretLevelValidityInfo: string;
    isOpenClassification: boolean;
  }
} = {}


// let secretLevelInfoPromise: Promise<any> | null = null;

// let secretLevelInfo: {
//   resourceOptionInfo: SecretSelectOptionType[];
//   secretLevelValidityInfo: string;
//   isOpenClassification: boolean;
// } | null = null;

/**
 * 密级请求
 * @param option Object
 * @returns RequestPromise<any>
 */
const requestSecretLevelInfo = (secLevel?: number, requestConfig?: {
  url?: string,
  method?: Method,
  params?: AnyObj,
  headers?: AnyObj
}) => {
  return new Promise<any>((resolve, reject) => {
    let params = {
      ...requestConfig?.params,
      secLevel
    }
    request({
      url: requestConfig?.url || GETSECLEVELURL,
      method: requestConfig?.method || 'GET',
      params: params,
      headers: requestConfig?.headers || {}
    }).then(res => {
      if (res.status && res.data) {
        resolve(res.data);
      } else {
        resolve({});
      }
    }).catch(err => {
      resolve({});
    })
  })
}

/**
 * 
 * @param secLevel 密级等级
 * @returns Promise
 */
export const getSecretLevelInfo = async (secLevel?: number, config?: {
  url?: string,
  key?: string,
  method?: Method,
  params?: AnyObj,
  headers?: AnyObj,
}) => {
  try {
    let cacheKey = config?.headers?.secondEmpId || MAINKEY;
    if (config?.key) {
      cacheKey = config.key + '_' + cacheKey
    }
    if (isValueEmpty(secretLevelInfoMap[cacheKey])) {
      if (!promiseMap[cacheKey]) {
        promiseMap[cacheKey] = requestSecretLevelInfo(secLevel, config);
      }
      secretLevelInfoMap[cacheKey] = await promiseMap[cacheKey];
    }
    return secretLevelInfoMap[cacheKey]!;
  } catch (err) {
    return Promise.reject(err);
  }
}

/** 密级校验 */
export const doValidate = async (data?: {
  secretLevel?: string,
  secretLevelValidity?: string,
}, props?: {
  checkValidity?: string,
  options?: SecretSelectOptionType[],
  required?: boolean,
  filterLevel?: string,
  filterLevelType?: string,
  requestHeaderParams?: AnyObj,
  secretLevelKey?: string,
  secretLevelUrl?: string,
  secretLevelMethod?: Method,
  secretLevelParams?: AnyObj,
}) => {
  const { checkValidity, options, required, filterLevel, filterLevelType, requestHeaderParams, secretLevelKey,
     secretLevelMethod, secretLevelParams, secretLevelUrl} = props || {};
  // 获取密级配置
  let secretOptions = options;
  if (isValueEmpty(secretOptions)) {
    const res = await getSecretLevelInfo(undefined, {
      headers: requestHeaderParams,
      url: secretLevelUrl,
      method: secretLevelMethod,
      params: secretLevelParams,
      key: secretLevelKey,
    });
    secretOptions = res.resourceOptionInfo;
  }
  // 获取校验配置
  let secretValidity = checkValidity || CHECKVALIDITY;
  // 获取当前配置内容
  let empId = requestHeaderParams?.secondEmpId || MAINKEY;
  const currentOption = getSecretLevelOption(data?.secretLevel, true, {
    noDefault: false,
    filterLevel,
    filterLevelType,
    options: secretOptions,
    empId,
    key: secretLevelKey
  });
  // 配置校验规则
  const rules: AnyObj = {};
  const customRegister: {
    name: string;
    rules: (value: string, requirement: string, attribute: string) => boolean;
    errorMessage: string;
  }[] = []
  let secretLevelRules = '';
  let secretLevelValidityRules = '';
  // 保密等级必填
  if (required) {
    secretLevelRules += 'required';
  }
  // 保密期限必填
  if (currentOption?.secretLevelValidityShowType === SecretLevelValidityShowType.REQUIRED) {
    secretLevelValidityRules += 'required';
  }
  // 保密期限校验规则
  if (secretValidity) {
    if (secretLevelValidityRules) secretLevelValidityRules += '|';
    // 直接传递正则的话，会把 | 进行分割，尝试了几种方法都不行
    // 这里直接通过自定义校验事件去进行正则校验
    secretLevelValidityRules += 'customLevelValidityRule:regexLevelValidity';
    customRegister.push({
      name: 'customLevelValidityRule',
      rules: (value: string) => {
        const regex = new RegExp(secretValidity!);
        return regex?.test(value);
      },
      errorMessage: getLabel('205853', '保密期限不符合格式')
    })
  } else {
    if (secretLevelValidityRules) secretLevelValidityRules += '|';
    // 直接传递正则的话，会把 | 进行分割，尝试了几种方法都不行
    // 这里直接通过自定义校验事件去进行正则校验
    secretLevelValidityRules += 'numberValidityRule:regexLevelValidity';
    customRegister.push({
      name: 'numberValidityRule',
      rules: (value: string) => {
        return !isNumber(value);
      },
      errorMessage: getLabel('214833', '保密期限不能为纯数字')
    })

    // if (secretLevelValidityRules) secretLevelValidityRules += '|';
    // 直接传递正则的话，会把 | 进行分割，尝试了几种方法都不行
    // 这里直接通过自定义校验事件去进行正则校验
    // secretLevelValidityRules += 'spaceValidityRule:regexLevelValidity';
    // customRegister.push({
    //   name: 'spaceValidityRule',
    //   rules: (value: string) => {
    //     return !/\s/?.test(value);
    //   },
    //   errorMessage: getLabel('214838', '保密期限不能包含空格')
    // })

  }
  if (secretLevelValidityRules) secretLevelValidityRules += '|';
  secretLevelValidityRules += 'max:50';

  // 更新校验规则
  if (secretLevelRules) rules['secretLevel'] = secretLevelRules;
  if (secretLevelValidityRules) rules['secretLevelValidity'] = secretLevelValidityRules;
  // 触发校验
  return new Promise((resolve, reject) => {
    doValidator({
      datas: {
        secretLevel: isValueEmpty(currentOption) ? '' : data?.secretLevel,
        secretLevelValidity: data?.secretLevelValidity || ''
      },
      rules,
      customRegister,
      message: {
        max: {
          string: getLabel('230140', '长度不能超过 $s').replace('$s', '50')
        }
      }
    }).then((res: any) => {
      resolve(res);
    }).catch(err => {
      reject(err);
    });
  })
}

/** 获取对应密级（默认密级）配置 */
export const getSecretLevelOption = (secretLevel?: string, filterCanceled?: boolean, params?: {
  noDefault?: boolean,
  options?: SecretSelectOptionType[],
  filterLevel?: string,
  filterLevelType?: string,
  /** 主次账号参数 */
  empId?: string,
  /** 自定义密级接口 key */
  key?: string,
}) => {
  // 获取当前配置内容
  let cacheKey = params?.empId || MAINKEY;
  if (params?.key) cacheKey = params.key + '_' + cacheKey;
  // 只读、禁用可以展示封存的密级，但是编辑时不能选择封存的密级
  const options = params?.options || secretLevelInfoMap[cacheKey]?.resourceOptionInfo || [];
  let option: Partial<SecretSelectOptionType> = {};
  if (String(secretLevel)) {
    option = options?.find(option => option.id === String(secretLevel) && (!filterCanceled || !option.canceled)) || {};
  } else if (!params?.noDefault) {
    option = options?.find(option => option.isDefault && (!filterCanceled || !option.canceled)) || {};
  }
  if (params?.filterLevel) {
    if (params?.filterLevelType === 'down') {
      option = Number(params.filterLevel) >= Number(option.id) ? option : {};
    } else {
      option = Number(params.filterLevel) <= Number(option.id) ? option : {};
    }
  }
  return option;;
}
