import { AttachmentSecurityValidateType } from '../types';
import { AnyObj, utils } from '../../../lib';
import { Method } from 'axios';

const { isMobile } = utils;

/**
 * 处理 url
 * @param url string
 * @param loginFree boolean
 * @param ismobile boolean
 * @returns string
 */
export const getRealUrl = (url: string, params?: {
  loginFree?: boolean,
  isMobile?: boolean,
  fileId?: string,
}) => {
  let apiPrefix = params?.loginFree ? 'papi' : 'api';
  const _isMobile = typeof params?.isMobile !== 'undefined' ? params.isMobile : isMobile;
  if (_isMobile) apiPrefix += '/app';
  let newUrl = url?.replace('{api}', apiPrefix);
  if (params?.fileId) {
    newUrl = newUrl.replace('{fileId}', params?.fileId);
  }
  return newUrl;
}

/**
 * 附件密级校验方法
 * @param files 附件
//  * @param secretLevel 保密等级
 */
export const doValidate = async (files: any[], secretLevel: string, defaultSecretValidity?: string, requestHeaderParams?: AnyObj, others?: {
  localSecurityKey?: string,
  localSecurityUrl?: string,
  localSecurityParams?: AnyObj,
  localSecurityMethod?: Method,
  localSecurityHeaders?: AnyObj,
  secretLevelKey?: string,
  secretLevelUrl?: string,
  secretLevelMethod?: Method,
  secretLevelParams?: AnyObj,
  secretLevelHeaders?: AnyObj,
}) => {
  return import(
    /* webpackChunkName: "upload" */
    './toolsAsync'
  ).then((defaultModule) => defaultModule.doValidate(files, secretLevel, defaultSecretValidity, requestHeaderParams, others));
}

/** 对本地密级校验完成的文件进行分类 */
export const classifyFiles = (datas: AttachmentSecurityValidateType[]) => {
  /** type 0: 校验通过的附件集合 */
  const verifiedSet: AttachmentSecurityValidateType[] = [];
  /** type 1: 附件保密等级不符合规范的集合 */
  const nonCompliantLevelSet: AttachmentSecurityValidateType[] = [];
  /** type 2: 保密等级过高的集合 */
  const highLevelSet: AttachmentSecurityValidateType[] = [];
  /** type 3: 附件密级保密期限不符合规范的集合 */
  const nonCompliantValiditySet: AttachmentSecurityValidateType[] = [];
  /** type 4: 需要保密期限但是没有设置的集合 */
  const needValiditySet: AttachmentSecurityValidateType[] = [];
  for (const data of datas) {

    switch (data.type) {
      case 0:
        verifiedSet.push(data)
        break;
      case 1:
        nonCompliantLevelSet.push(data)
        break;
      case 2:
        highLevelSet.push(data)
        break;
      case 3:
        nonCompliantValiditySet.push(data)
        break;
      case 4:
        needValiditySet.push(data)
        break;
    }
  }
  return {
    verifiedSet,
    nonCompliantLevelSet,
    nonCompliantValiditySet,
    highLevelSet,
    needValiditySet
  }
}

/** 获取附件密级名称 */
export const getSecValidity = async (params: {
  secretLevel?: string;
  validity?: string;
  secretProtect?: boolean;
  secLevelDisplayFormat?: number;
  requestHeaderParams?: AnyObj;
  secretLevelKey?: string,
  secretLevelUrl?: string;
  secretLevelMethod?: Method;
  secretLevelParams?: AnyObj;
  secretLevelHeaders?: AnyObj;
}) => {
  return import(
    /* webpackChunkName: "upload" */
    './toolsAsync'
  ).then((defaultModule) => defaultModule.getSecValidity?.(params));
}
