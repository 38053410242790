import { AnyObj } from '../../../types/common';


/** 但文件大小限制 */
let sysMaxSingleUploadSize: number | null = null;
/** 文件类型黑名单 */
let sysFormatBlacks: string | null = null;
/** 文件上传白名单 */
let sysFormatWhites: string | null = null;
/** 判断当前需要校验系统白名单还是系统黑名单 */
let sysFormatWhitesEnable: boolean | null = null;
/** 接口是否加载完成 */
let isLoadSetting: boolean | 'loading' = false;
/** 移动端是否显示下载按钮(false 是允许) */
let sysAppDownload: boolean = false;
/** 是否允许批量下载(true 是允许) */
let sysBatchDownload: boolean = true;

/** 请求接口获取系统文件上传校验规则 */
export const reqSystemUploadCheckConfig = (loginFree?: boolean) => {
  return import(
    /* webpackChunkName: "upload" */
    './systemUploadCheckAsync').then((defaultModule) => defaultModule?.reqSystemUploadCheckConfig(
      loginFree,
      isLoadSetting
    ));
}

export const setLoadingSetting = (loaded: boolean | 'loading') => {
  isLoadSetting = loaded;
}

export const setSystemUploadCheckConfig = (params: AnyObj) => {
  sysMaxSingleUploadSize = params?.sysMaxSingleUploadSize;
  sysFormatBlacks = params?.sysFormatBlacks;
  sysFormatWhites = params?.sysFormatWhites;
  sysFormatWhitesEnable = params?.sysFormatWhitesEnable;
  sysAppDownload = params?.sysAppDownload;
  sysBatchDownload = params?.sysBatchDownload;
}
/** 获取系统附件大小限制、附件类型白名单/黑名单配置 */
export const getSystemUploadCheckConfig = () => {
  return { sysMaxSingleUploadSize, sysFormatBlacks, sysFormatWhites, sysFormatWhitesEnable, sysAppDownload, sysBatchDownload };
}

/** 按照系统设置检测文件类型 */
export const checkFileTypeBySystem = (ext: string) => {
  return new Promise<boolean>((resolve, reject) => {
    reqSystemUploadCheckConfig()
      .then(() => {
        if (sysFormatWhitesEnable) {
          if (sysFormatWhites && sysFormatWhites !== '*' && sysFormatWhites !== '*.*') {
            const sysFormatWhitesArr = sysFormatWhites?.split(/,|;/);
            resolve(sysFormatWhitesArr.includes(ext));
          } else {
            resolve(true);
          }
        } else {
          if (sysFormatBlacks === '*' || sysFormatBlacks === '*.*') {
            resolve(false);
          } else if (sysFormatBlacks) {
            const sysFormatBlacksArr = sysFormatBlacks?.split(/,|;/);
            resolve(!sysFormatBlacksArr.includes(ext));
          } else {
            resolve(true);
          }
        }
      })
      .catch((error) => {
        reject(false);
      })
  })
}
