import { getLabel } from "@weapp/utils";
import { UploadProps } from '../types';

export function uploadMiddleware(props: UploadProps): UploadProps {
  const newProps: UploadProps = { ...props };

  // 文档做编辑的插件优化，要求编辑接口默认添加 editLinkType=editPage 参数
  if (!newProps.editParams) {
    newProps.editParams = {
      editLinkType: 'editPage'
    };
  } else {
    if (!('editLinkType' in newProps.editParams)) {
      newProps.editParams.editLinkType = 'editPage';
    }
  }

  if (newProps.listType === 'img' && !newProps.limitType) {
    newProps.limitType = 'jpg,jpeg,png,gif,giff,tif,tiff,bmp,webp'
  }

  if(typeof newProps.uploadingOptions === 'undefined') {
    newProps.uploadingOptions = [
      // {
      //   id: 'rename',
      //   icon: "Icon-Unfinished-business",
      //   content: getLabel('30088', '重命名')
      // },
      {
        id: 'editSecLevel',
        icon: 'Icon-daily-o',
        content: getLabel('90913', '修改密级'),
        moreType: true,
      },
      {
        id: 'delete',
        icon: 'Icon-cancel',
        content: getLabel('21778', '删除')
      },
    ];
  }

  return newProps;
}

export function muploadMiddleware(props: UploadProps): UploadProps {
  const newProps: UploadProps = { ...props };

  if (!newProps.uploadTitle) {
    newProps.uploadTitle = getLabel("59802", "请点击此处上传");
  }

  // 文档做编辑的插件优化，要求编辑接口默认添加 editLinkType=editPage 参数
  if (!newProps.editParams) {
    newProps.editParams = {
      editLinkType: 'editPage'
    };
  } else {
    if (!('editLinkType' in newProps.editParams)) {
      newProps.editParams.editLinkType = 'editPage';
    }
  }

  if (newProps.listType === 'img' && !newProps.limitType) {
    newProps.limitType = 'jpg,jpeg,png,gif,giff,tif,tiff,bmp,webp'
  }

  if(typeof newProps.uploadingOptions === 'undefined') {
    newProps.uploadingOptions = [
      // {
      //   id: 'rename',
      //   icon: "Icon-Unfinished-business",
      //   content: getLabel('30088', '重命名')
      // },
      {
        id: 'editSecLevel',
        icon: 'Icon-daily-o',
        content: getLabel('90913', '修改密级'),
        moreType: true,
      },
      {
        id: 'delete',
        icon: 'Icon-cancel',
        content: getLabel('21778', '删除')
      },
    ];
  }

  return newProps;
}
