import { DateType, DateQuickFilterProps } from '../../types'
import { dayjs } from '@weapp/utils'
import { isEqual } from '../../../../utils/index'
import { getWeekBoundary } from '../index'

/**
 * @description: 根据时间导出相应的标准时间格式
 * @param {string} time
 * @return {*}
 */
export function getFormatDateTime(time: string) {
  // 这里需要兼容一下HH的格式，new Date(2012-12-12 12)无法创建正常日期数据
  if (time.length <= 2) time += ':00'
  return new Date(new Date().toDateString() + ' ' + time)
}

/**
 * @description: 根据type格式化日期
 * @param {DateType} value
 * @param {string} type
 * @return {*}
 */
export function formatValueByType(value: DateType, type: string) {
  if (value === '') {
    return value
  } else {
    const date = dayjs(value)
    if (
      type === 'day' ||
      type === 'days' ||
      type === 'week' ||
      type === 'doubleWeek'
    ) {
      return date.format('YYYY-MM-DD')
    } else if (type === 'month' || type === 'months') {
      return date.format('YYYY-MM')
    } else if (type === 'year' || type === 'years') {
      return date.format('YYYY')
    } else if (type === 'season' || type === 'seasons') {
      if (typeof value === 'string') {
        return value as string
      } else {
        const year = dayjs(value).year()
        const quarter = dayjs(value).quarter()
        return `${year}-Q${quarter}`
      }
    } else if (type === 'halfyear' || type === 'halfyears') {
      if (typeof value === 'string') {
        return value as string
      } else {
        const year = dayjs(value).year()
        const half = dayjs(value).month() < 6 ? 1 : 2
        return `${year}-H${half}`
      }
    } else {
      return date.format('YYYY-MM-DD')
    }
  }
}

/**
 * @description: 格式化内部日期
 * @param {DateType | DateType[]} value
 * @param {string} type
 * @return {*}
 */
export function formatDate(value: DateType | DateType[], type: string = 'day') {
  if (Array.isArray(value)) {
    if (isEqual(value, [])) {
      return []
    } else {
      const ary: string[] = []
      value.forEach((item) => ary.push(formatValueByType(item, type)))
      return ary
    }
  } else {
    return formatValueByType(value, type)
  }
}

/**
 * @description: 格式化单个日期
 * @param {DateType | DateType[]} value
 * @param {*} timeFormat
 * @return {*}
 */
export function formatDateTime(value: DateType | DateType[], timeFormat: 'HH:mm:ss' | 'HH:mm' | 'HH', isDateMode: boolean = false) {
  if (Array.isArray(value)) {
    if (isEqual(value, [])) {
      return []
    } else {
      const ary: string[] = []
      value.forEach((item) => {
        ary.push(
          item === '' ? '' : dayjs(item).format(`YYYY-MM-DD ${timeFormat}`)
        )
      })
      return ary
    }
  } else {
    if (isDateMode) {
      return value === '' ? '' : dayjs(value).format(`YYYY-MM-DD`)
    } else {
      return value === '' ? '' : dayjs(value).format(`YYYY-MM-DD ${timeFormat}`)
    }
  }
}

/**
 * @description: 根据format格式化传入时间
 * @param {string} time
 * @param {*} format
 * @return {*}
 */
export function formatTime(time: string, format: 'HH:mm:ss' | 'HH:mm' | 'HH' = 'HH:mm:ss') {
  return time === '' ? '' : dayjs(getFormatDateTime(time)).format(format)
}

/**
 * @description: 格式化出口日期
 * @param {DateType | DateType[]} value
 * @param {string | 'timestamp' | 'Date'} format
 * @return {*}
 */
export function formatExportValue(value: DateType | DateType[], format: string | 'timestamp' | 'Date') {
  if (Array.isArray(value)) {
    let v: any[] = []
    if (format === 'Date') {
      value.forEach((item) => {
        v.push(item === '' ? '' : new Date(dayjs(item).format()))
      })
    } else if (format === 'timestamp') {
      value.forEach((item) => {
        v.push(item === '' ? '' : +dayjs(item))
      })
    } else {
      value.forEach((item) => {
        v.push(item === '' ? '' : dayjs(item).format(format))
      })
    }
    return v
  } else {
    let v: any
    if (value === '') {
      v = ''
    } else {
      if (format === 'Date') {
        v = new Date(dayjs(value).format())
      } else if (format === 'timestamp') {
        v = +dayjs(value)
      } else {
        v = dayjs(value).format(format)
      }
    }
    return v
  }
}

/**
 * @description: 通过传入的inputFormat来格式input框的值
 * @param {string} value
 * @param {string} showFormat
 * @return {*}
 */
export function formatInputValueByShowFormat(value: string, showFormat: string | undefined) {
  if (value && showFormat && dayjs(value).isValid()) {
    return dayjs(value).format(showFormat)
  } else {
    return value
  }
}
/**
 * @description: 根据showFormat来转化为标准日期格式，方便内部数据处理
 * @param {*}
 * @return {*}
 */
export function showFormatToStandardFormat(inputValue: string, showFormat: string | undefined) {
  let newInputValue = inputValue
  if (showFormat) {
    if (dayjs(newInputValue, showFormat).isValid()) {
      newInputValue = dayjs(newInputValue, showFormat).format()
    } else if (dayjs(newInputValue).isValid()) {
      newInputValue = dayjs(newInputValue).format()
    }
  }
  return newInputValue
}

/**
 * @description: 根据传入的value值格式化周/双周模式下的出口值
 * @param {string} value
 * @param {DateQuickFilterProps} props
 * @return {*}
 */
export function formatWeekValue(value: string, props: DateQuickFilterProps) {
  const { type = 'day', weekStart = 1 } = props
  if (dayjs(value).isValid()) {
    let newValue: DateType | DateType[] = formatDate(value, type) as string
    //周起始日期
    const newStartDay = formatDate(
      getWeekBoundary(dayjs(newValue), weekStart).thisWeekStartDay.toString()
    ) as string
    //周的结束日期要根据单/双周来判断
    let newEndDay
    if (type === 'week') {
      newEndDay = formatDate(
        getWeekBoundary(dayjs(newValue), weekStart).thisWeekEndDay.toString()
      ) as string
    } else {
      newEndDay = formatDate(
        new Date(
          +getWeekBoundary(dayjs(newValue), weekStart).thisWeekEndDay +
          7 * 24 * 3600 * 1000
        ).toString()
      ) as string
    }
    newValue = [newStartDay, newEndDay]
    return newValue
  } else {
    return []
  }
}
