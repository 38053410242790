import { classUseMemo, getLabel } from "@weapp/utils";
import { AnyObj } from "../../../lib";
import { BrowserAssociativeProps } from "../auto-complete/types";
import { associativeTypes, enableSelectedSortable, useMaxDisplaySelectedTypes } from "../defaultProps";
import { MTypesBrowserProps, TypesBrowserProps } from '../types-browser/types';
import { MShareBrowserProps, ShareBrowserProps } from '../share-browser/types';
import { BrowserProps, MBrowserProps } from "../types";
import { projectTemplate, taskTemplate } from './templates';
import { getUseBoxSelectionFromSystemAndProps } from './index';
import { printUtils } from "../shareUtils";

function overwriteProps(originProps: AnyObj, targetPrpos: AnyObj | undefined, cover?: boolean) {
  return cover
    ? {
      ...targetPrpos,
      ...originProps,
    }
    : {
      ...originProps,
      ...targetPrpos,
    };
}

// 只处理footer默认值缓存，一定要避免动态的情况
const footerCache = {};

export function middlewareHook<P extends object>(props: P): P {
  let newProps = (props as AnyObj);
  if (typeof newProps?.afterMiddleware === 'function') {
    newProps = newProps.afterMiddleware(newProps) || newProps;
  }
  return newProps as P;
}


export function browserMiddleware(props: BrowserProps): BrowserProps {
  const newProps: BrowserProps = { ...props };

  const { module, type } = newProps;

  // 人员组织多选
  if (type === 'combinationResource') {
    // 默认开启多类型
    newProps.isMultType = true;
  }

  // 关联项目 关联任务
  if ((module === 'project' && type === 'mainlineBrowser') ||
    (module === 'task' && type === 'taskBrowser')) {
    newProps.browserAssociativeProps = overwriteProps({
      associativeTemplate: projectTemplate,
    }, newProps.browserAssociativeProps);
  }

  // 所属项目
  if (module === 'task' && type === 'belongMainlineBrowser') {
    newProps.browserAssociativeProps = overwriteProps({
      associativeTemplate: taskTemplate,
    }, newProps.browserAssociativeProps);
  }

  // 我的标签，公共标签
  if (type === 'my' || type === 'common') {
    newProps.browserAssociativeProps = overwriteProps({
      isTag: true,
    }, newProps.browserAssociativeProps);
  }

  if (type && type in associativeTypes) {
    newProps.isAssociative = true;
  }

  if (type === 'resource') {
    // 处理企业微信通讯录套件是否授权参数
    const { authWecahtBook, wxDepNeedConvertCount, wxEmpNeedConvertCount, wxTotalNeedConvertCount } = window?.TEAMS || {};

    const teamsParams = {
      //是否授权企业微信通讯录套件
      authWecahtBook,
      //部门需要转义的数量
      wxDepNeedConvertCount,
      //人员需要转义的数量
      wxEmpNeedConvertCount,
      //合计需要转义的数量
      wxTotalNeedConvertCount,
    };

    newProps.commonParams = overwriteProps(teamsParams, newProps.commonParams || {});
  }

  // 人员
  if (type === 'resource') {
    /** 测试用，人员浏览按钮开启聚会拆分功能 */
    // newProps.isShowGroup = true;

    newProps.browserDialogProps = overwriteProps({ width: newProps.multiple ? 750 : 680 }, newProps.browserDialogProps);

    if (!('enableQuickImport' in newProps)) newProps.enableQuickImport = true;
    // const hrmBrowserAssociativeProps: BrowserAssociativeProps = {
    //   addInfo: {
    //     content: getLabel('10905', '邀请'),
    //     dialog: {
    //       app: '@weapp/profile',
    //       compName: 'SingleInviteDialogCom',
    //     }
    //   },
    //   extendButton: {
    //     icon: 'Icon-add-to01',
    //     content: getLabel('27444', '邀请同事'),
    //     dialog: {
    //       app: '@weapp/layout',
    //       compName: 'InviteDialogCom',
    //     },
    //   }
    // };
    // if (
    //   !(window.TEAMS?.currentUser?.admin ||
    //     (!window.TEAMS?.currentUser?.admin && !window.TEAMS?.currentTenant?.inviteForbid) ||
    //     (!window.TEAMS?.currentUser?.admin && window.TEAMS?.currentTenant?.inviteForbid && window.TEAMS?.invitePermission)
    //   )) {
    //   hrmBrowserAssociativeProps.enableAddData = false;
    //   hrmBrowserAssociativeProps.enableExtendButton = false;
    // }
    // newProps.browserAssociativeProps = overwriteProps(hrmBrowserAssociativeProps, newProps.browserAssociativeProps);
  }

  // // 群组
  // if (type === 'channel') {
  //   newProps.module = "hrm";
  // }

  if (!newProps.multiple) {
    // newProps.browserDialogProps = overwriteProps({ width: 600 }, newProps.browserDialogProps);
    newProps.browserAssociativeProps = overwriteProps({
      displayInInput: true, // 单选已选数据默认展示在输入框中
      adaptiveInputWidth: true, // 单选默认开启自适应宽度
    }, newProps.browserAssociativeProps);
  }

  // 底部按钮多语言
  newProps.footers = newProps.footers || classUseMemo('browserFooter', footerCache, () => [
    {
      key: 'sure',
      content: getLabel('31291', '确定'),
    }, {
      key: 'clear',
      content: getLabel('30580', '清除'),
    },
    {
      key: 'multSure',
      content: getLabel('59751', '确定（%s）').replace('（%s）', '(%s)'),
    },
    {
      key: 'multClear',
      content: getLabel('59725', '清空已选'),
    }
  ], []);

  newProps.key = newProps.key || newProps.type;

  const useBoxSelection = getUseBoxSelectionFromSystemAndProps(newProps);
  if (useBoxSelection) {
    if (newProps.browserAssociativeProps) {
      newProps.browserAssociativeProps.displayInInput = true;
    } else {
      newProps.browserAssociativeProps = { displayInInput: true };
    }
  }

  const newOperations = [
    {
      id: 'clear',
      icon: 'Icon-error02',
      title: getLabel('28067', '清空'),
    },
    ...newProps.browserAssociativeProps?.operations || [],
  ];
  newProps.browserAssociativeProps = overwriteProps({
    maxDisplaySelected: newProps.browserAssociativeProps?.wrapDisplay ? 10 : 50,
    disabledClearBtn: !(type === 'combinationResource'),
    operations: newOperations,
    associativeSearchToPanel: true,
    isAssociativeAdaptiveWidth: true,
  }, newProps.browserAssociativeProps);

  if (type && !useMaxDisplaySelectedTypes.includes(type)) {
    // 1. 非人员相关浏览按钮，展示所有数据
    newProps.browserAssociativeProps = overwriteProps({
      displayAllSelectedData: true,
    }, newProps.browserAssociativeProps);
  }
  if (printUtils.isPrintMode()) {
    // 1. 打印模式，展示所有数据
    // 2. 打印模式，隐藏头像
    // 3. 打印模式，禁用展开、收起功能
    newProps.browserAssociativeProps = overwriteProps({
      displayAllSelectedData: true,
      hideAvatar: true,
      disabledHideMore: true,
    }, newProps.browserAssociativeProps);
  }

  // if (type && !newProps.isTypesBrowser && enableSelectedSortable.includes(type)) {
  //   newProps.disabledSelectedSort = false;
  // } else if (newProps.disabledSelectedSort === undefined) {
  //   newProps.disabledSelectedSort = true;
  // }

  return middlewareHook(newProps);
}

export function autoCompleteMiddleware(props: BrowserAssociativeProps): BrowserAssociativeProps {
  const newProps: BrowserAssociativeProps = { ...props };
  const { module, type } = newProps;

  // 关联项目 关联任务
  if ((module === 'project' && type === 'mainlineBrowser') ||
    (module === 'task' && type === 'taskBrowser')) {
    newProps.associativeTemplate = projectTemplate;
  }

  // 所属项目
  if (module === 'task' && type === 'belongMainlineBrowser') {
    newProps.associativeTemplate = taskTemplate;
  }

  // 关联浏览按钮
  if (type && type in associativeTypes) {
    newProps.isAssociative = true;
  }

  // 我的标签，公共标签
  if (type && (type === 'my' || type === 'common')) {
    newProps.browserAssociativeProps = overwriteProps({
      isTag: true,
    }, newProps.browserAssociativeProps);
  }

  // 人员组织多选
  if (type === 'combinationResource') {
    // 默认开启多类型
    newProps.isMultType = true;
  }

  newProps.key = newProps.key || newProps.type;

  return middlewareHook(newProps);
}

export function associativeBrowserMiddleware(props: TypesBrowserProps): TypesBrowserProps {
  const newProps: TypesBrowserProps = { ...props };

  const { type } = newProps;

  // 底部按钮多语言
  newProps.footers = newProps.footers || classUseMemo('associativeBrowserFooter', footerCache, () => [
    {
      key: 'sure',
      content: getLabel('31291', '确定'),
    }, {
      key: 'clear',
      content: getLabel('30580', '清除'),
    },
    {
      key: 'multSure',
      content: getLabel('59751', '确定（%s）').replace('（%s）', '(%s)'),
    },
    {
      key: 'multClear',
      content: getLabel('59725', '清空已选'),
    }
  ], []);

  // if (type && !newProps.isTypesBrowser && enableSelectedSortable.includes(type)) {
  //   newProps.disabledSelectedSort = false;
  // } else if (newProps.disabledSelectedSort === undefined) {
  //   newProps.disabledSelectedSort = true;
  // }

  return middlewareHook(newProps);
}

export function mBrowserMiddleware(props: any): MBrowserProps {
  const newProps: MBrowserProps = { ...props };

  const { type } = newProps;

  if (type && associativeTypes.findIndex(t => t === type) !== -1) {
    newProps.isAssociative = true;
  }

  // // 人员
  // if (type === 'resource') {
  //   newProps.isMenuTab = true;
  // }

  // // 群组
  // if (type === 'channel') {
  //   newProps.module = "hrm";
  // }

  // 人员组织多选
  if (type === 'combinationResource') {
    // 默认开启多类型
    newProps.isMultType = true;
    newProps.isMenuTab = true;
  }

  if (type === 'resource') {
    // 处理企业微信通讯录套件是否授权参数
    const { authWecahtBook, wxDepNeedConvertCount, wxEmpNeedConvertCount, wxTotalNeedConvertCount } = window?.TEAMS || {};

    const teamsParams = {
      //是否授权企业微信通讯录套件
      authWecahtBook,
      //部门需要转义的数量
      wxDepNeedConvertCount,
      //人员需要转义的数量
      wxEmpNeedConvertCount,
      //合计需要转义的数量
      wxTotalNeedConvertCount,
    };

    newProps.commonParams = overwriteProps(teamsParams, newProps.commonParams || {});
  }

  // if ((module === 'project' && type === 'mainlineBrowser') ||
  //   (module === 'task' && type === 'taskBrowser')) {
  //   newProps.browserAssociativeProps = overwriteProps({
  //     customRenderSelectedItem: customRenderProjectItem,
  //   }, newProps.browserAssociativeProps, false);
  // }

  // if (module === 'task' && type === 'belongMainlineBrowser') {
  //   newProps.browserAssociativeProps = overwriteProps({
  //     customRenderSelectedItem: customRenderTaskItem,
  //   }, newProps.browserAssociativeProps, false);
  // }

  // 我的标签，公共标签
  if (type && (type === 'my' || type === 'common')) {
    newProps.browserAssociativeProps = overwriteProps({
      isTag: true,
    }, newProps.browserAssociativeProps);
  }

  // 底部按钮多语言
  newProps.footers = newProps.footers || classUseMemo('mBrowserFooter', footerCache, () => ([
    {
      key: 'cancel',
      content: getLabel('52130', '取消'),
    }, {
      key: 'clear',
      content: getLabel('30580', '清除'),
    },
    {
      key: 'sure',
      content: getLabel('31291', '确定'),
    },
    {
      key: 'multClear',
      content: getLabel('28067', '清空'),
    },
    {
      key: 'multSure',
      content: getLabel('31291', '确定'),
    }
  ]), []);

  newProps.key = newProps.key || newProps.type;

  // 1. isAssociative，最大展示数量 5
  // 2. multiline，最大展示数量 20
  // 3. maxDisplaySelected，支持复写
  // 4. displayAllSelectedData，展示全部
  const isAssociative = newProps?.browserAssociativeProps?.isAssociative || newProps?.isAssociative;
  newProps.browserAssociativeProps = overwriteProps({
    maxDisplaySelected: isAssociative ? 5 : 20,
  }, newProps.browserAssociativeProps);

  if (type && !useMaxDisplaySelectedTypes.includes(type)) {
    // 1. 打印模式，展示所有数据
    // 2. 非人员相关浏览按钮，展示所有数据
    newProps.browserAssociativeProps = overwriteProps({
      displayAllSelectedData: true,
    }, newProps.browserAssociativeProps);
  }

  // if (type && !newProps.isTypesBrowser && enableSelectedSortable.includes(type)) {
  //   newProps.disabledSelectedSort = false;
  // } else if (newProps.disabledSelectedSort === undefined) {
  //   newProps.disabledSelectedSort = true;
  // }

  return middlewareHook(newProps);
}

export function mTypesBrowserMiddleware(props: any): MTypesBrowserProps {
  const newProps: MTypesBrowserProps = { ...props };

  // 1. isAssociative，最大展示数量 5
  // 2. multiline，最大展示数量 20
  // 3. maxDisplaySelected，支持复写
  // 4. displayAllSelectedData，展示全部
  const isAssociative = newProps?.browserAssociativeProps?.isAssociative || newProps?.isAssociative;
  newProps.browserAssociativeProps = overwriteProps({
    maxDisplaySelected: isAssociative ? 5 : 20,
  }, newProps.browserAssociativeProps);

  // newProps.disabledSelectedSort = false;

  return middlewareHook(newProps);
}

export function mShareBrowserMiddleware(props: any): MShareBrowserProps {
  const newProps: MShareBrowserProps = { ...props };

  // 1. isAssociative，最大展示数量 5
  // 2. multiline，最大展示数量 20
  // 3. maxDisplaySelected，支持复写
  // 4. displayAllSelectedData，展示全部
  const isAssociative = newProps?.browserAssociativeProps?.isAssociative || newProps?.isAssociative;
  newProps.browserAssociativeProps = overwriteProps({
    maxDisplaySelected: isAssociative ? 5 : 20,
  }, newProps.browserAssociativeProps);


  if (newProps.browserShareProps) {
    newProps.browserShareProps.sortable = false;
  } else {
    newProps.browserShareProps = { sortable: false };
  }

  return middlewareHook(newProps);
}

export function mAutoCompleteMiddleware(props: BrowserAssociativeProps): BrowserAssociativeProps {
  const newProps: BrowserAssociativeProps = { ...props };
  const { type } = newProps;

  if (type && associativeTypes.findIndex(t => t === type) !== -1) {
    newProps.isAssociative = true;
  }

  // if ((module === 'project' && type === 'mainlineBrowser') ||
  //   (module === 'task' && type === 'taskBrowser')) {
  //   newProps.customRenderSelectedItem = customRenderProjectItem;
  // }

  // if (module === 'task' && type === 'belongMainlineBrowser') {
  //   newProps.customRenderSelectedItem = customRenderTaskItem;
  // }

  // 人员组织多选
  if (type === 'combinationResource') {
    // 默认开启多类型
    newProps.isMultType = true;
  }

  // 我的标签，公共标签
  if (type && (type === 'my' || type === 'common')) {
    newProps.isTag = true;
  }

  newProps.key = newProps.key || newProps.type;

  return middlewareHook(newProps);
}

export function typeBrowserMiddleware(props: TypesBrowserProps): TypesBrowserProps {
  const newProps: TypesBrowserProps = { ...props };

  newProps.browserAssociativeProps = overwriteProps({
    maxDisplaySelected: newProps.browserAssociativeProps?.wrapDisplay ? 10 : 50,
  }, newProps.browserAssociativeProps);

  if (printUtils.isPrintMode()) {
    // 1. 打印模式，展示所有数据
    // 2. 打印模式，隐藏头像
    // 3. 打印模式，禁用展开、收起功能
    newProps.browserAssociativeProps = overwriteProps({
      displayAllSelectedData: true,
      hideAvatar: true,
      disabledHideMore: true,
    }, newProps.browserAssociativeProps);
  }

  // if (newProps.detailListProps) {
  //   newProps.detailListProps.sortable = true;
  // } else {
  //   newProps.detailListProps = { sortable: true };
  // }

  return middlewareHook(newProps);
}

export function shareBrowserMiddleware(props: ShareBrowserProps): ShareBrowserProps {
  const newProps: ShareBrowserProps = { ...props };

  newProps.browserAssociativeProps = overwriteProps({
    maxDisplaySelected: newProps.browserAssociativeProps?.wrapDisplay ? 10 : 50,
  }, newProps.browserAssociativeProps);

  if (printUtils.isPrintMode()) {
    // 1. 打印模式，展示所有数据
    // 2. 打印模式，隐藏头像
    // 3. 打印模式，禁用展开、收起功能
    // 4. 打印模式，禁用查看详情
    newProps.browserAssociativeProps = overwriteProps({
      displayAllSelectedData: true,
      hideAvatar: true,
      disabledHideMore: true,
    }, newProps.browserAssociativeProps);
    newProps.showShareDetail = false;
  }

  if (newProps.browserShareProps) {
    newProps.browserShareProps.sortable = false;
  } else {
    newProps.browserShareProps = { sortable: false };
  }

  return middlewareHook(newProps);
}
