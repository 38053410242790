import { classnames } from '@weapp/utils';
import { iconClsPrefix, USESKELETON } from '../../constants/index';
import Loadable, { LoadingType } from '../../react-loadable';
import { IconType, IconProps } from './types';
import IconLoader from './icon-loader/IconLoader';

const IconSkeleton: LoadingType<IconProps> = (props) => {
  if (!USESKELETON) return <span />
  const { className, size, moduleColor } = props;
  const svgWrapper = classnames({
    [`${iconClsPrefix}`]: true,
    [`${iconClsPrefix}-wrapper`]: true,
    [`${iconClsPrefix}-${moduleColor}`]: typeof moduleColor !== 'undefined',
  }, className);
  const svgClassString = classnames({
    [`${iconClsPrefix}-${size}`]: true,
    [`${iconClsPrefix}-svg`]: true,
  });
  return <span className={svgWrapper}><span className={svgClassString} style={{ display: 'inline-block' }}></span></span>
}

const Icon = Loadable({
  name: 'Icon',
  loading: IconSkeleton,
  loader: () => import(
    /* webpackChunkName: 'ui_common' */
    './Icon'),
}) as IconType;

Icon.IconSkeleton = IconSkeleton;

Icon.defaultProps = {
  size: 'xs',
  prefixCls: iconClsPrefix
}


Icon.getSvgIconDom = IconLoader.getSvgIconDom;
Icon.getSvgIconStr = IconLoader.getSvgIconStr;
Icon.getIconMap = IconLoader.loadIconMap;
Icon.IconLoader = IconLoader;

export type { IconProps, IconType, IconNames, moduleType as IconModuleColorType } from './types';

const ICON_MAP = {};
export { ICON_MAP };

export default Icon;
